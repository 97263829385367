export const MESSAGE_BACK_INITIAL = 'Deseja realmente retornar à página inicial?';

export const MESSAGE_ABOUT_CHARACTERS = 'Nome informado deve conter mais de 3 caracteres.';

const GENERIC_INITIAL_MESSAGE = 'Oops!';

export const MESSAGE_WARNING_APARTMENT_WOOD = 'Esse tipo de construção não é permitido para esse tipo de imóvel.';

const GENERIC_ERROR_MESSAGE =
  'Aguarde alguns minutos e tente novamente. Se o erro persistir entre em contato conosco através dos nossos Canais de Atendimento.';

export const MESSAGE_GET_PERSON_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao buscar seus dados. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_GET_PERSON_ERROR_RESTRICT = `${GENERIC_INITIAL_MESSAGE} Prezado, não é possível prosseguir com essa cotação. Para mais esclarecimentos, favor nos contactar pelo e-mail: atendimento.corretora@pottencial.com.br.`;

export const MESSAGE_PERSON_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao criar a sua conta na Pottencial. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_HEFESTO_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao criar a sua conta na Pottencial. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_CEP_ERROR = `${GENERIC_INITIAL_MESSAGE} Não conseguimos localizar o seu endereço com o CEP informado.`;

export const MESSAGE_EXPOSURES = `${GENERIC_INITIAL_MESSAGE} Já existe apólice vinculada a esse endereço.`;

export const MESSAGE_EXPOSURES_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao prosseguir com a validação do seu endereço. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_COVERAGEBASIC_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao buscar os itens de cobertura básica. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_COVERAGEADDITIONAL_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao buscar os itens de coberturas adicionais. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_CREATEQUOTE_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao prosseguir com a cotação. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_EDITQUOTE_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao atualizar a cotação. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_PEOPLE_RISK_ASSESSEMENT_ERROR = `Risco fora da política de aceitação. Em caso de dúvidas, gentileza acionar o nosso time de atendimento.`;

export const MESSAGE_ADDQUOTE_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao adicionar a cobertura à cotação. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_REMOVEQUOTE_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao remover a cobertura adicional. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_UPDATEQUOTE_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao atualizar o valor a cobertura adicional. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_QUOTATION_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao baixar sua cotação. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_CREATEPROPOSAL_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao criar sua proposta. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_TERMS_ERROR = `${GENERIC_INITIAL_MESSAGE} Tivemos um problema ao salvar os critérios de aceite. ${GENERIC_ERROR_MESSAGE}`;

export const MESSAGE_PAYMENT_NOT_READY = 'Estamos gerando o seu pagamento. Aguarde um instante e tente novamente.';

export const MESSAGE_DOWNLOAD_SUCCESS = 'Download realizado com sucesso.';

export const MESSAGE_PAYMENT_LINK_UNAVAILABLE = `${GENERIC_INITIAL_MESSAGE} Houve um erro ao gerar o link de pagamento. ${GENERIC_ERROR_MESSAGE} `;

export const MESSAGE_RESTRICTEDLOCALITY_ERROR =
  'O CEP informado encontra-se com restrições técnicas para cotação.';

export const MESSAGE_LOCALITYEXPOSURE_ERROR = 'Verificamos que já existe uma apólice vinculada a este local de risco';

export const MESSAGE_INVALID_PARTNER = `Não foi possível validar seu vínculo comercial com a corretora. ${GENERIC_ERROR_MESSAGE}`;
