import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

const StepText = ({ textArray }) => (
  <>
    {textArray.map((text, index) => (
      <Box key={`text-${index}`} mb={1.5} className="steps__text-wrap">
        <Typography align="left" variant="body2" className="steps__secundary-color-text steps__font-text">
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Typography>
      </Box>
    ))}
  </>
);

StepText.propTypes = {
  textArray: PropTypes.array.isRequired,
};

export default StepText;
