import useUtils from 'hooks/useUtils';

import { LOCALIZATION } from 'constants/textsSteps';
import useRisk from 'hooks/useRisk';
import useParticipant from 'hooks/useParticipant';
import StepText from '../shared/StepText';

const LocalizationStepText = () => {
  const { getParticipantState } = useParticipant();
  const { getRiskState } = useRisk();
  const { getNameProperty, getGrammar } = useUtils();

  const isMobile = window.innerWidth <= 1280; 

  const texts = [ isMobile 
                  ? LOCALIZATION.textHeaderMobile 
                  : LOCALIZATION.textHeader(getParticipantState?.name, getGrammar(getRiskState?.propertyType), getNameProperty(getRiskState).toLowerCase())];

  return (
    <>
      <StepText textArray={texts} />
    </>
  );
};

export default LocalizationStepText;
