import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { TYPE_HOUSES, TYPE_MAKE_HOUSES, TYPE_IS_HOUSES } from 'constants/collections';
import {
  TYPE_IS_HOUSES_KEYS,
  TYPE_MAKE_HOUSES_KEYS,
  TYPE_HOUSES_KEYS,
  HEIGHT_STEP_CONTAINER,
} from 'constants/defaults';
import { PROPERTY } from 'constants/textsSteps';
import useRisk from 'hooks/useRisk';
import useValidator from 'hooks/useValidator';
import useCoverages from 'hooks/useCoverages';
import ButtonsControl from '../shared/ButtonsControl';
import TitleDivider from '../shared/TitleDivider';
import TypesSelection from '../shared/TypesSelection';

const DEFAULT_PROPERTY_STEP = {
  propertyType: '',
  constructionType: '',
  propertyUseType: '',
};

const AboutPropertyStep = ({ onBack, onNext }) => {
  const { getRiskState, setRisk } = useRisk();
  const { AboutPropertyStepValidationSchema } = useValidator();
  const { removeCoveragesForVacateProperty } = useCoverages();

  const vacateKey = TYPE_IS_HOUSES_KEYS.vacate.key;
  const woodKey = TYPE_MAKE_HOUSES_KEYS.wood.key;
  const apartmentKey = TYPE_HOUSES_KEYS.apartment.key;

  const formik = useFormik({
    initialValues: { ...DEFAULT_PROPERTY_STEP },
    validationSchema: AboutPropertyStepValidationSchema,
    validateOnBlur: true,
    onSubmit: () => {
      removeCoveragesForVacateProperty();
      onNext();
    },
  });

  const { setValues } = formik;

  useEffect(() => {
    const { propertyType, constructionType, propertyUseType } = getRiskState;

    setValues({
      propertyType: propertyType || '',
      constructionType: constructionType || '',
      propertyUseType: propertyUseType || '',
    });
  }, [getRiskState, setValues]);

  const setTypes = (prop, value) => {
    const data = {
      [prop]: value,
    };

    setRisk(data);
  };

  const getDisabled = useCallback(
    (type) => {
      if (type.key === vacateKey && formik.values.constructionType === woodKey) return true;

      if (type.key === woodKey) {
        if (formik.values.propertyType === apartmentKey) return true;

        if (formik.values.propertyUseType === vacateKey) return true;
      }

      if (type.key === apartmentKey && formik.values.constructionType === woodKey) return true;

      return false;
    },
    [formik]
  );

  const onPropertyType = (value, callBack) => {
    callBack();
    setTypes('propertyType', value);
  };

  const onConstructionType = (value, callBack) => {
    callBack();
    setTypes('constructionType', value);
  };

  const onPropertyUseTypeType = (value, callBack) => {
    callBack();
    setTypes('propertyUseType', value);
  };

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <Box mt={2} minHeight={HEIGHT_STEP_CONTAINER}>
        <TitleDivider text={PROPERTY.title} minWidth={135} />

        <TypesSelection
          title="Seu imóvel é"
          param="propertyType"
          parentFormik={formik}
          collection={TYPE_HOUSES}
          onChange={onPropertyType}
          getDisabled={getDisabled}
        />

        <TypesSelection
          title="Sua utilização é"
          param="propertyUseType"
          parentFormik={formik}
          collection={TYPE_IS_HOUSES}
          onChange={onPropertyUseTypeType}
          getDisabled={getDisabled}
        />

        <TypesSelection
          title="Ele é feito de"
          param="constructionType"
          parentFormik={formik}
          collection={TYPE_MAKE_HOUSES}
          onChange={onConstructionType}
          getDisabled={getDisabled}
        />
      </Box>

      <ButtonsControl
        dataTestid="button-forward-aboutPropertyStep"
        onBack={onBack}
        onNext={formik.submitForm}
        disabled={!isEmpty(formik.errors)}
        gridBack={isXs ? 4 : 3}
        gridForward={isXs ? 4 : 3}
      />
    </>
  );
};

AboutPropertyStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default AboutPropertyStep;
