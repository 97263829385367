import React, { useState } from 'react';
import { Tab, Box, Tabs, Grid } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useInView } from 'react-hook-inview';
import { ESSENCIALS_COVERAGES } from 'constants/collections';
import useUtils from 'hooks/useUtils';
import TabPanel from 'components/TabPanel/TabPanel';
import { SECTION_ASSISTANCE } from 'constants/texts';
import ContractLink from '../../globals/ContractLink';

const SectionAssistance = () => {
  const { setMarkMenu } = useUtils();
  const [value, setValue] = useState(0);
  const [ref] = useInView({
    threshold: 1,
    onEnter: () => {
      setMarkMenu('section-assistencias');
    },
  });

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7500,
  };

  return (
    <section className="section-assistance" id="section-assistencias">
      <Box className="container-assistance" id="section-assistencias-title" ref={ref}>
        <Box className="title-div">
          <h2 className="title-assistance title-section-global">
            <Box>{SECTION_ASSISTANCE.TITLE}</Box>
            <hr />
          </h2>

          <span className="subtitle-assistance">{SECTION_ASSISTANCE.SUBTITLE}</span>
        </Box>
      </Box>

      <Box className="container-icon">
        <Box className="tabs">
          <Tabs
            value={value}
            onChange={(_, newValue) => setValue(newValue)}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Assistências"
            className="tab-item"
          >
            <Tab
              label={
                <Box className={`${value === 0 && 'selected'}`}>
                  <Box className="subtitle">{SECTION_ASSISTANCE.ASSISTANCE}</Box>
                  <Box display="flex" alignItems="center" className="title">
                    {SECTION_ASSISTANCE.TYPE_ASSISTANCE.ESSENTIAL}
                  </Box>
                </Box>
              }
              id='simple-tab-0'
              aria-controls='simple-tabpanel-0'
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0} mobile={false}>
          <Box className="title-div-icon">
            <Slider id="slider-slick" {...settingsSlider}>
              <Box id="slider-slick">
                <Grid container>
                  {ESSENCIALS_COVERAGES.map((item, index) => (
                    <Grid item xs={6} md={3} className="box" key={`slider-item-${index}`}>
                      <Box className="box-imagem">
                        <img src={item.ico} alt={`${item.title} - Pottencial`} />
                      </Box>
                      <Box className="box-text">{item.title}</Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Slider>
          </Box>
        </TabPanel>
      </Box>

      <Box className="container-assistance">
        <Box className="title-div-icon">
          <Box display="flex" justifyContent="center" alignItems="baseline" flexWrap="wrap">
            <Box className="box-assistance">{SECTION_ASSISTANCE.FREE_PLAN}</Box>

            <Box className="box-button" >
              <Box display="flex" flexWrap="wrap" className="wrapper-cta">
                <ContractLink />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
  );
};

export default SectionAssistance;
