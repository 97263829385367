import React from 'react';
import { Redirect } from 'react-router-dom';
import CustomAppBar from 'components/CustomAppBar/CustomAppBar';
import useProposal from 'hooks/useProposal';
import { Box } from '@material-ui/core';
import { FINISH } from 'constants/textsSteps';
import Finish from '../Finish';
import CreditCard from '../paymentComponents/CreditCard';

const CreditCardConclusionStep = () => {
  const { getProposalState } = useProposal();

  if (!getProposalState.url) return <Redirect to="/error/404" />;

  return (
    <Box height={1} position="absolute" width={1} id="contract">
      <CustomAppBar />
      <Finish FINISH_TEXT={FINISH}>
        <CreditCard />
      </Finish>
    </Box>
  );
};

export default CreditCardConclusionStep;
