import Init from "pages/contract/Init";
import LandingPage from "pages/landingPage/LandingPage";
import CreditCardTransitionStep from 'pages/contract/CreditCardConclusionStep/CreditCardTransitionStep';
import CreditCardConclusionStep from 'pages/contract/CreditCardConclusionStep/CreditCardConclusionStep';
import CreditCardFallBackStep from 'pages/contract/CreditCardConclusionStep/CreditCardFallBackStep';
import PrivateRoute from "security/PrivateRoute/PrivateRoute";
import { ROUTES } from "./defaults";

const DefaultRoutes = () => [
  <PrivateRoute exact component={Init} path={`/${ROUTES.Contratacao}`} key={`Finlandia_${Init.name}`}/>,
    <PrivateRoute exact component={LandingPage} path='/' key={`Finlandia_${LandingPage.name}`}/>,
    <PrivateRoute exact component={CreditCardTransitionStep} path={`/${ROUTES.CreditCardTransition}`} key={`Finlandia_${CreditCardTransitionStep.name}`}/>,
    <PrivateRoute exact component={CreditCardFallBackStep} path={`/${ROUTES.CreditCardFallback}`} key={`Finlandia_${CreditCardFallBackStep.name}`}/>,
    <PrivateRoute exact component={CreditCardConclusionStep} path={`/${ROUTES.CreditCardConclusion}`} key={`Finlandia_${CreditCardConclusionStep.name}`}/>
];

export default DefaultRoutes;