import { merge } from 'lodash';
import { createTheme, responsiveFontSizes } from '@material-ui/core';

import { DEFAULT_THEME } from 'constants/defaults';
import MuiBaseConfig from './MuiBaseConfig';

export const createThemeMaterial = (color = DEFAULT_THEME) => {
  const configs = {
    name: color.id,
    palette: {
      primary: {
        main: color.primary,
      },
      secondary: {
        main: '#EFEFEF',
        contrastText: '#8B7676',
      },
      text: {
        primary: '#3C3C3C',
      },
      error: {
        main: '#C3284C',
      },
    },
  };

  const theme = createTheme(merge({}, MuiBaseConfig, configs));

  return responsiveFontSizes(theme);
};

export default createThemeMaterial;
