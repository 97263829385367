import React from 'react';
import PropTypes from 'prop-types';

const Main = ({ children }) => <>{children}</>;

Main.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Main;
