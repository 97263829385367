import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import App from './App';
import useUtils from './hooks/useUtils';
import { store, persist } from './store';

const Main = () => {
  const { isBlank } = useUtils();

  useEffect(() => {
    if (isBlank(window.ENV.GOOGLE_KEY)) {
      return;
    }

    ReactGA.initialize(window.ENV.GOOGLE_KEY);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [isBlank]);

  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persist}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ErrorBoundary>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </ErrorBoundary>
          </MuiPickersUtilsProvider>
        </PersistGate>
      </Provider>
    </>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));
