import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Tab, Box, Tabs, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { chunk } from 'lodash';
import { ESSENCIALS_COVERAGES } from 'constants/collections';
import {
  FETCH_STATUS,
  HEIGHT_STEP_CONTAINER,
  MAX_ASSISTANCE_PER_PAGE,
  STEPS_NUMBER,
  TYPE_IS_HOUSES_KEYS,
} from 'constants/defaults';
import { ASSISTANCE } from 'constants/textsSteps';
import useGlobal from 'hooks/useGlobal';
import useRisk from 'hooks/useRisk';
import useQuote from 'hooks/useQuote';
import usePayloads from 'hooks/usePayloads';
import useParticipant from 'hooks/useParticipant';
import { useAlert } from 'components/Alert/Alert';
import { MESSAGE_HEFESTO_ERROR } from 'constants/messages';
import TabPanel from 'components/TabPanel/TabPanel';
import ButtonsControl from '../shared/ButtonsControl';
import TitleDivider from '../shared/TitleDivider';

const AssistanceStep = ({ onBack, onNext }) => {
  const [value, setValue] = useState(0);
  const [items, setItems] = useState([]);

  const { setActiveStep } = useGlobal();
  const { getRiskState } = useRisk();
  const { getQuoteState, updateQuote, errorResponseMessage } = useQuote();
  const { updateQuotePayloadRef, createPersonHefestoPayloadRef } = usePayloads();
  const { createPersonHefesto } = useParticipant();
  const { addMsgDanger } = useAlert();

  const handleOnBack = () => {
    if (getRiskState?.propertyUseType === TYPE_IS_HOUSES_KEYS.vacate.key) {
      setActiveStep(STEPS_NUMBER.BASIC_COVERAGE);
      return;
    }

    onBack();
  };

  const onFinish = async () => {
    const updateQuoteResponse = await updateQuote(getQuoteState?.id, updateQuotePayloadRef.current);

    if (updateQuoteResponse.meta.requestStatus === FETCH_STATUS.rejected) {
      const message = errorResponseMessage(updateQuoteResponse.payload.errors.code);

      addMsgDanger(message);
      return;
    }

    const createHefestoResponse = await createPersonHefesto(createPersonHefestoPayloadRef.current);

    if (createHefestoResponse.meta.requestStatus === FETCH_STATUS.rejected) {
      addMsgDanger(MESSAGE_HEFESTO_ERROR);
      return;
    }

    onNext();
  };

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 7500,
  };

  useEffect(() => {
    setItems(chunk(ESSENCIALS_COVERAGES, MAX_ASSISTANCE_PER_PAGE));
  }, []);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <Box mt={3} minHeight={HEIGHT_STEP_CONTAINER}>
        <TitleDivider text={ASSISTANCE.title} minWidth={105} />

        <Box className="container-icon">
          <Box className="tabs">
            <Tabs
              value={value}
              onChange={(_, newValue) => setValue(newValue)}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Assistências"
              className="tenth__tab-item"
            >
              <Tab
                label={
                  <Box className={`${value === 0 && 'selected'}`}>
                    <Box display="flex" alignItems="center">
                      ESSENCIAL
                    </Box>
                  </Box>
                }
                id="simple-tab-0"
                aria-controls="simple-tabpanel-0"
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0} mobile={isXs}>
            <Box>
              <Slider id="slider-slick" {...settingsSlider}>
                {items.map((item, index) => (
                  <Box id="slider-slick" key={`slider-slick-${index}`} data-testid={`box-item-slider${index}`}>
                    <Grid container>
                      {item.map((itemSub, indexSub) => (
                        <Grid item xs={6} md={4} className="box" key={`slider-item-${indexSub}`}>
                          <Box display="flex" alignItems="center" pb={3.5}>
                            <Box minWidth={ isXs ? 45 : 80} className="box-imagem">
                              <img src={itemSub.ico} alt={`${itemSub.title} - Pottencial`} className="box-imagem-essencial"/>
                            </Box>
                            <Box className="box-text">
                              <Typography variant="body2">{itemSub.title}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </Slider>
            </Box>
          </TabPanel>
        </Box>
      </Box>

      <ButtonsControl
        dataTestid="button-forward-assistanceStep"
        onBack={handleOnBack}
        onNext={onFinish}
        gridBack={isXs ? 4 : 3}
        gridForward={isXs ? 4 : 3}
        textForward="Contratar"
      />
    </>
  );
};

AssistanceStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default AssistanceStep;
