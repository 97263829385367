import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@material-ui/core';

const TitleDivider = ({ text, minWidth }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
    <Box minWidth={minWidth}>
      <Typography variant="subtitle2" color="primary" style={{ fontWeight: '500' }}>
        {text}
      </Typography>
    </Box>

    <Box width={1}>
      <Divider />
    </Box>
  </Box>
);

TitleDivider.propTypes = {
  text: PropTypes.string.isRequired,
  minWidth: PropTypes.number.isRequired,
};

export default TitleDivider;
