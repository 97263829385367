import React from 'react';
import videoBanner from 'assets/videos/banner-video.mp4';
import { useInView } from 'react-hook-inview';
import useUtils from 'hooks/useUtils';
import { Box } from '@material-ui/core';
import { SECTION_BANNER } from '../../../../../constants/texts';
import ContractLink from '../../globals/ContractLink';

const SectionHero = () => {
  const { setMarkMenu } = useUtils();
  const [refBanner] = useInView({
    threshold: 1,
    onEnter: () => {
      setMarkMenu('section-home');
    },
  });

  return (
    <section className="section-banner" id="section-home">
      <div className="background" alt="Seguro Residencial - Pottencial">
        <video autoPlay loop muted>
          <track default kind="captions" srcLang="pt-br" />
          <source src={videoBanner} type="video/mp4" />
        </video>
      </div>

      <div className="content-banner">
        <div className="banner-container">
          <h1 className="title-banner-container whiteSpace" id="section-home-title" ref={refBanner}>
            {SECTION_BANNER.TITLE}
          </h1>

          <h4 className="sub-title-banner-container">{SECTION_BANNER.SUBTITLE}</h4>

          <Box display="flex" flexWrap="wrap" className="wrapper-cta">
            <ContractLink />
          </Box>
        </div>
      </div>
    </section>
  );
};

export default SectionHero;
