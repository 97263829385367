export const ABOUT_YOU = {
  title: 'SOBRE VOCÊ',
  textHeader:
    'Olá! Sou a Patti, Potter e assistente virtual da Pottencial e estou aqui para te ajudar a personalizar o seu Pottencial Residencial. Para começar, preciso que me informe os dados abaixo. Ah! aqui na Pottencial você escolhe como deseja ser chamado, basta informar que é um nome social e inserir o nome com o qual se identifica.',
  textHeaderMobile:
  'Olá! Sou a Patti, vou te ajudar a personalizar o seu Pottencial Residencial. Para começar, preciso que me informe os dados abaixo.',  
  termPolicy: {
    title: 'Aceite do uso do nome social',
    body: [
      'Nome social NÃO é apelido!',
      'O nome social é o nome pelo qual os transgêneros, transexuais e travestis se identificam. A Pottencial entende que o nome diz respeito à pessoa que o porta e preza pelo respeito e bem-estar de todos os seus clientes e parceiros.',
      'Agora, basta clicar em "Li e Aceito" e emitiremos sua apólice contendo o nome com o qual se identifica. Ele permanecerá em nossos registros pelo tempo em que perdurar o contrato de seguro.',
    ],
  },
  termService: {
    title: 'Termos de Serviço',
    body: [
      'Afirmo que as informações foram prestadas com exatidão, boa fé e veracidade, assumo integral responsabilidade por elas, inclusive as não descritas de próprio punho. Atesto ainda que tomei conhecimento das Condições Gerais correspondentes a este seguro e declaro que o enquadramento foi realizado em conformidade a realidade, e que caso esteja em desacordo poderá acarretar em perda de indenização. Autorizo expressamente a inclusão de todos os dados e informações relacionadas ao presente documento em banco de dados aos quais a seguradora poderá recorrer para análise de riscos atuais, futuros e para liquidação de processo de sinistro.',
      'Somos fiéis aos princípios éticos que estão arraigados em nossos valores e em nossa cultura. Com transparência e integridade, consolidamos relacionamentos duradouros, que são frutos da nossa conduta ética, do nosso comprometimento com as normas e da nossa latente vocação para o desenvolvimento. Em conformidade com a Lei Geral de proteção de dados (Lei n° 13.709/2018), a',
      'respeita e garante a privacidade e proteção dos dados pessoais de todos os envolvidos nesta proposta de seguro, por isso, declara que o tratamento de dados pessoais se dá para o desempenho de suas atividades legais, observando a legislação aplicável sobre segurança da informação, privacidade e proteção de dados e demais normas setoriais ou gerais sobre o tema. Sempre respeitaremos os princípios da finalidade, adequação, necessidade, transparência, livre acesso, segurança, prevenção, não discriminação e obrigações legais. A POTTENCIAL já implementou as medidas técnicas e organizacionais apropriadas para proteger os dados pessoais, levando em conta técnicas avançadas disponíveis, o contexto e as finalidades do tratamento. As medidas de segurança atenderão as exigências da LEI DE PROTEÇÃO DE DADOS.',
      'possui uma politica de privacidade, a qual encontra-se disponível no seguinte endereço:',
      'Dispõe também de um comitê exclusivo para sanar dúvidas referentes ao tratamento de dados pessoais, podendo ser acionado pelo e-mail:',
    ],
  },
};

export const PROPERTY = {
  title: 'SOBRE SEU IMÓVEL',
  textHeader: (name) =>
    `Vamos lá, ${name}! Agora me conte um pouco sobre sua residência. Ah, é importante você saber que, não aceitamos imóveis tombados pelo patrimônio histórico, república ou pensão.`,
  textHeaderMobile: (name) =>
  `Vamos lá, ${name}! Agora me conte um pouco sobre seu imovél.`,  
};

export const LOCALIZATION = {
  title: 'ENDEREÇO',
  textHeader: (name, grammar, propertyName) =>
    `${name}, agora que conheci ${grammar.pronounPossessive} ${propertyName}, me fale o endereço onde ${grammar.pronoun} está localizad${grammar.gender}.`,
  textHeaderMobile: `Agora me fale o endereço onde está localizado.`, 
};
export const BASIC_COVERAGE = {
  title: 'COBERTURA BÁSICA',
  textHeader:
    'Agora precisamos definir o valor da sua cobertura, caso ocorra algum imprevisto. Vamos começar pela cobertura básica, ela é a base do seu seguro.',
  textHeaderMobile: 'Agora precisamos definir o valor da sua cobertura, caso ocorra algum imprevisto.',
  textDescription: (min, max) => `O valor mínimo é de ${min} e o máximo é de ${max}.`,
  textBody: 'Você pode alterar o valor das coberturas de maneira que melhor lhe atenda.'
};

export const ADDITIONAL_COVERAGES = {
  title: 'COBERTURAS ADICIONAIS',
  removeCoverage: 'Deseja remover essa cobertura adicional?',
  textHeader: (name) =>
    `${name}, além da cobertura básica, você pode contar também com coberturas adicionais que são ideias para quem quer ainda mais tranquilidade no dia a dia. Inclua as coberturas que deseja para deixar seu seguro mais completo.`,
    textHeaderMobile: (name) =>
    `${name}, você pode contar também com coberturas adicionais que são ideias para quem quer ainda mais tranquilidade no dia a dia.`,
  };

export const ASSISTANCE = {
  title: 'ASSISTÊNCIAS',
  textHeader:
    'Aqui na Pottencial você conta também com assistências 24 horas para te ajudar em momentos de imprevistos. No pacote essencial você conta com 17 acionamentos gratuitos durante a vigência do seu seguro.',
  textHeaderMobile: 'Aqui na Pottencial você conta também com assistências 24 horas gratuita para te ajudar em momentos de imprevistos. '
};

export const PAYMENT = {
  title: 'PAGAMENTO',
  textHeader: 'Prontinho! Agora só falta o pagamento para seu imóvel ficar protegido.',
  textHeaderMobile: 'Prontinho! Agora só falta o pagamento para seu imóvel ficar protegido.',
  minDate: 'O primeiro vencimento deve ser no mínimo 3 dias após a data de hoje.',
  maxDate: (date) => `O primeiro vencimento deve ser até ${date}.`,
};

export const FINISH = {
  title: 'Agora o seu lar está protegido pelo Pottencial Residencial!',
  subtitle: (name) =>
    `Que maravilha, ${name}! Estamos muito felizes por você ter escolhido a Pottencial para proteger o seu lar.`,
  firstDescription:
    'Lembrando que esta confirmação poderá levar até 48 horas, mas, assim que o pagamento for realizado, seu lar já estará coberto pelo Pottencial Residencial.',
  secondDescription:
    'Ah, você também pode acompanhar tudo sobre o seu seguro por meio do portal Pottencial Para Você, basta clicar no botão abaixo.',
  thirdDescription: 'Conte sempre conosco!',
  errorRecoveryProposal:
    'Tivemos um problema ao recuperar seu documento de proposta, mas está tudo certo com seu pagamento e sua apólice será enviada por e-mail.',
};

export const CREDITCARD_FINISH = {
  title: 'Falta pouco!',
  subtitle: (name) =>
    `Que maravilha, ${name}! Estamos muito felizes por você ter escolhido a Pottencial para proteger o seu lar.`,
  firstDescription:
    'Assim que recebermos a confirmação de pagamento, emitiremos a sua apólice e enviaremos via e-mail.',
  secondDescription:
    'Ah, você também pode acompanhar tudo sobre o seu seguro por meio do portal Pottencial para Você, basta clicar no botão abaixo.',
  thirdDescription: 'Conte sempre conosco!',
};

export default { ABOUT_YOU };
