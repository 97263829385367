import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { BROKER_URLS } from 'constants/constants';
import DefaultRoutes from 'constants/defaultRoutes';
import { ROUTES } from 'constants/defaults';
import PrivateRoute from './security/PrivateRoute/PrivateRoute';
import Init from './pages/contract/Init';
import CreditCardTransitionStep from './pages/contract/CreditCardConclusionStep/CreditCardTransitionStep';
import CreditCardConclusionStep from './pages/contract/CreditCardConclusionStep/CreditCardConclusionStep';
import CreditCardFallBackStep from './pages/contract/CreditCardConclusionStep/CreditCardFallBackStep';
import LandingPage from './pages/landingPage/LandingPage';

const renderRoutes = () => {
  const routesArray = BROKER_URLS.flatMap((urlPath, index) => 
  [<PrivateRoute exact component={Init} path={`${urlPath}/${ROUTES.Contratacao}`} key={`${urlPath}_${index}_${Init.name}`}/>,

  <PrivateRoute exact component={LandingPage} path={`${urlPath}`} key={`${urlPath}_${index}_${LandingPage.name}`}/>,

  <PrivateRoute exact component={CreditCardTransitionStep} path={`${urlPath}/${ROUTES.CreditCardTransition}`} key={`${urlPath}_${index}_${CreditCardTransitionStep.name}`}/>,
  
  <PrivateRoute exact component={CreditCardFallBackStep} path={`${urlPath}/${ROUTES.CreditCardFallback}`} key={`${urlPath}_${index}_${CreditCardFallBackStep.name}`}/>,
  
  <PrivateRoute exact component={CreditCardConclusionStep} path={`${urlPath}/${ROUTES.CreditCardConclusion}`} key={`${urlPath}_${index}_${CreditCardConclusionStep.name}`}/>]
  );
  routesArray.push(...DefaultRoutes());
  routesArray.push(<Redirect to="/error/404" key='redirect'/>);

  return routesArray;
};

const Routes = () => (
  <Switch>
      {renderRoutes()}
  </Switch>
);

export default Routes;
