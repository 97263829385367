import React from 'react';
import { EDIT_SOCIALNAME_MODAL } from 'constants/socialNameMessages';
import { EventDrivenModalProps, modalInfoProps } from './types';
import SocialNameModalInfo from './SocialNameModalInfo';

const SocialNameEditionModal: React.FC<EventDrivenModalProps> = ({ open, callReturnEvent }) => {
  const modalProps: modalInfoProps = {
    open,
    title: EDIT_SOCIALNAME_MODAL.TITLE,
    confirmedButton: EDIT_SOCIALNAME_MODAL.CONFIRMED_BUTTON,
    unconfimedButton: EDIT_SOCIALNAME_MODAL.UNCONFIRMED_BUTTON,
    callReturnEvent,
  };

  return (
    <>
      <SocialNameModalInfo {...modalProps} />
    </>
  );
};

export default SocialNameEditionModal;
