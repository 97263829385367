import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { TextField } from '@material-ui/core';

const CEPInput = (props) => {
  const field = useMemo(() => {
    const inputProps = {
      ...props,
      onBlur: undefined,
      onChange: undefined,
    };

    return <TextField {...inputProps} />;
  }, [props]);

  return (
    <InputMask {...props} maskChar={null} mask="99999-999">
      {() => field}
    </InputMask>
  );
};

CEPInput.propTypes = {
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

CEPInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
};

export default CEPInput;
