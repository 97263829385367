import { useDispatch } from 'react-redux';
import { requestOperations } from '../redux';

export default () => {
  const dispatch = useDispatch();

  const getAddressByZipCode = async (zipCode) => dispatch(requestOperations.getAddressByZipCode(zipCode));

  return {
    getAddressByZipCode,
  };
};
