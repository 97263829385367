import React, { useState, useRef } from 'react';
import { Box, Button, Popover, Paper, Divider } from '@material-ui/core';
import iconMenuOrange from 'assets/img/general/Header/icon-menu-orange.svg';
import iconClose from 'assets/img/general/icon-close.svg';
import iconPeople from 'assets/img/home/icon-people.svg';
import iconEnterprise from 'assets/img/home/icon-enterprise.svg';
import iconBroker from 'assets/img/home/icon-broker.svg';
import useWindowSize from 'hooks/useWindowSize';
import useUtils from 'hooks/useUtils';
import useBroker from 'hooks/useBroker';
import usePartner from 'hooks/usePartner';
import useGlobal from 'hooks/useGlobal';
import { ROUTES } from 'constants/defaults';
import { GLOBALS } from '../../../../constants/texts';

const NewHeader = () => {
  const windowSize = useWindowSize();
  const { setMarkMenu } = useUtils();
  const buttonRef = useRef();
  const [activeMenu, setActiveMenu] = useState();
  const [open, setOpen] = useState(false);
  const { searchParams } = useGlobal();
  const { getBroker, getBrokerLogo } = useBroker();
  const { disableContractLink } = usePartner();

  return (
    <header id="new-main-header">
      <Box id="mobile-menu" className={activeMenu ? 'active' : ''}>
        <Box className="content-menu">
          <Box className="logo">
            <a
              to="/"
              tag="anchor"
              anchor="#section-home"
              title="Ir para a página principal"
              aria-label="Ir para a página principal"
              href="#section-home"
            >
              <img src={getBrokerLogo()} alt="Logo - Pottencial Residencial" />
            </a>

            <button
              type="button"
              className="close"
              title="Fechar menu"
              aria-label="Fechar menu"
              onClick={() => setActiveMenu(false)}
            >
              <img src={iconClose} alt="Ícone - Pottencial" />
            </button>
          </Box>
          <Box className="navigation">
            <ul>
              <li>
                <a tag="anchor" anchor="#section-home" href="/">
                  {GLOBALS.HEADER.START}
                </a>
              </li>
              <li>
                <a tag="anchor" anchor="#section-assistencias" href="#section-assistencias">
                  {GLOBALS.HEADER.ASSISTANCE}
                </a>
              </li>
              <li>
                <a tag="anchor" anchor="#section-coberturas" href="#section-coberturas">
                  {GLOBALS.HEADER.COVERAGES}
                </a>
              </li>
              <li>
                <a tag="anchor" anchor="#section-client" href="#section-client">
                  {GLOBALS.HEADER.CUSTOMER_AREA}
                </a>
              </li>

              {!disableContractLink() && 
              <li>
                <a
                  tag="link"
                  className="link-right"
                  title="Personalize seu seguro"
                  label="Ver produtos"
                  href={`${getBroker().URL_PATH}/${ROUTES.Contratacao + searchParams}`}
                  id="residencial-site-personalize-seguro-botao"
                >
                  {GLOBALS.CUSTOMIZE_YOUR_INSURANCE}
                </a>
              </li>
              }
            </ul>
          </Box>
        </Box>
      </Box>

      <Box className="new-header menu-white">
        <Box className="menu-container">
          <Box className="menu-left">
            <Box className="logo">
              <a
                to="/"
                tag="anchor"
                anchor="#section-home"
                title="Ir para a página principal"
                aria-label="Ir para a página principal"
                href="#section-home"
              >
                <img src={getBrokerLogo()} alt="Logo - Pottencial Residencial" />
              </a>
            </Box>
          </Box>
          <Box className="menu-right">
            <ul>
              <li onClick={() => setMarkMenu('section-home')} role="presentation">
                <button
                  type="button"
                  tag="anchor"
                  className="link-right active"
                  anchor="#section-home"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  {GLOBALS.HEADER.START}
                </button>
              </li>

              <li onClick={() => setMarkMenu('section-assistencias')} role="presentation">
                <a tag="anchor" anchor="#section-assistencias" className="link-right" href="#section-assistencias">
                  {GLOBALS.HEADER.ASSISTANCE}
                </a>
              </li>

              <li onClick={() => setMarkMenu('section-coberturas')} role="presentation">
                <a tag="anchor" className="link-right" anchor="#section-coberturas" href="#section-coberturas">
                  {GLOBALS.HEADER.COVERAGES}
                </a>
              </li>

              <li onClick={() => setMarkMenu('section-client')} role="presentation">
                <a tag="anchor" className="link-right" anchor="#section-client" href="#section-client">
                  {GLOBALS.HEADER.CUSTOMER_AREA}
                </a>
              </li>

              <li>
                <a href={`${window.ENV.BROKER_URL}/register`} target="_blank" rel="noopener noreferrer">
                  <span className="go-to-broker">{GLOBALS.HEADER.BE_OUR_BROKER}</span>
                </a>
              </li>

              <li>
                <Box role="button" className="button-login" ref={buttonRef} onClick={() => setOpen(true)} tabIndex={0}>
                  <span>{GLOBALS.HEADER.LOGIN}</span>
                </Box>

                <Popover
                  disableScrollLock
                  open={open}
                  anchorEl={buttonRef.current}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  onClose={() => setOpen(!open)}
                  id="pop-up-login"
                >
                  <Paper className="paper">
                    <Box>
                      <Box textAlign="center" mb={4}>
                        <span className="title">{GLOBALS.HEADER.REGISTER.TITLE}</span>
                      </Box>

                      <Box display="flex" alignItems="center" mb={3}>
                        <Button className="button" onClick={() => window.open(window.ENV.CUSTOMER_URL, '_blank')}>
                          <Box mr={2}>
                            <img src={iconPeople} alt="Ícone - Pessoa" />
                          </Box>

                          <span className="body-text">{GLOBALS.HEADER.REGISTER.PERSON}</span>
                        </Button>
                      </Box>

                      <Box display="flex" alignItems="center" mb={3}>
                        <Button className="button" onClick={() => window.open(window.ENV.CORPORATE_URL, '_blank')}>
                          <Box mr={2}>
                            <img src={iconEnterprise} alt="Ícone - Empresa" />
                          </Box>

                          <span className="body-text">{GLOBALS.HEADER.REGISTER.COMPANY}</span>
                        </Button>
                      </Box>

                      <Box display="flex" alignItems="center" mb={3}>
                        <Button className="button" onClick={() => window.open(window.ENV.BROKER_URL, '_blank')}>
                          <Box mr={2}>
                            <img src={iconBroker} alt="Ícone - Corretor" />
                          </Box>

                          <span className="body-text">{GLOBALS.HEADER.REGISTER.BROKER}</span>
                        </Button>
                      </Box>

                      <Box display="flex" alignItems="center" justifyContent="center" mb={1}>
                        <span className="title">
                          {GLOBALS.HEADER.REGISTER.NOT_POTTENCIAL_YET}
                          <span className="mark">
                            {' '}
                            <a
                              href={`${window.ENV.SITE_URL}/register/`}
                              className="link-right"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {GLOBALS.HEADER.REGISTER.REGISTER}
                            </a>
                          </span>
                        </span>
                      </Box>

                      <Divider className="divider" />
                    </Box>
                  </Paper>
                </Popover>
              </li>
            </ul>
          </Box>

          {windowSize.width <= 1250 && (
            <button
              type="button"
              className="menu-toggler"
              title="Abrir menu"
              aria-label="Abrir menu"
              onClick={() => setActiveMenu(true)}
            >
              <span>{GLOBALS.HEADER.MENU}</span>
              <img src={iconMenuOrange} alt="Ícone - Pottencial" />
            </button>
          )}
        </Box>
      </Box>
    </header>
  );
};

export default NewHeader;
