import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Box, Grid, Paper, Hidden, Button, Divider, Typography, CircularProgress, useMediaQuery} from '@material-ui/core';
import { useFormik } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Error';
import CurrencyInput from 'components/CurrencyInputCustom/CurrencyInputCustom';
import { useLoader } from 'components/Loader/Loader';
import { useAlert } from 'components/Alert/Alert';
import useCoverages from 'hooks/useCoverages';
import useUtils from 'hooks/useUtils';
import usePayloads from 'hooks/usePayloads';
import useHelpers from 'hooks/useHelpers';
import useQuote from 'hooks/useQuote';
import useValidator from 'hooks/useValidator';
import useRisk from 'hooks/useRisk';
import useGlobal from 'hooks/useGlobal';
import useRestrictions from 'hooks/useRestrictions';
import { coveragesBasic } from 'constants/collections';
import {
  STEPS_NUMBER,
  TYPE_IS_HOUSES_KEYS,
  HEIGHT_STEP_CONTAINER,
  FETCH_STATUS,
  DEFAULT_BASIC_COVERAGE_VALUE,
} from 'constants/defaults';
import { BASIC_COVERAGE } from 'constants/textsSteps';
import PrivacyModal from 'pages/landingPage/presentation/home/SectionCoverages/PrivacyModal';
import ButtonsControl from '../shared/ButtonsControl';
import TitleDivider from '../shared/TitleDivider';

const DEFAULT_BASIC_STEP = {
  basicCoverageValue: '',
};

const BasicCoverageStep = ({ onBack, onNext }) => {
  const [loading, setLoading] = useState(false);
  const [showCoverageModal, setShowCoverageModal] = useState(false);
  const [cardActive, setCardActive] = useState({});
  const [validation, setValidation] = useState('');

  const [valueMin, setValueMin] = useState(1);
  const [valueMax, setValueMax] = useState(100);

  const { addMsgDanger } = useAlert();
  const { formatCurrency } = useUtils();
  const { validateValue } = useHelpers();
  const { BasicCoverageValidationSchema } = useValidator();
  const { getRiskState } = useRisk();
  const { getQuoteState, createQuote, updateQuote, errorResponseMessage } = useQuote();
  const { getBasicCoverage, setCoverageValue } = useCoverages();
  const { setActiveStep } = useGlobal();
  const { getRestrictionsState } = useRestrictions();

  const { createQuotePayloadRef, updateQuotePayloadRef } = usePayloads();

  const { disableLoader, enableLoader } = useLoader();

  const vacateKey = TYPE_IS_HOUSES_KEYS.vacate.key;

  const onError = (msg) => {
    setLoading(false);
    enableLoader();
    addMsgDanger(msg);
    setActiveStep(STEPS_NUMBER.LOCALIZATION);
  };

  const handleCreateQuote = async () => {
    const createQuoteResponse = await createQuote(createQuotePayloadRef.current);
    if (createQuoteResponse.meta.requestStatus === FETCH_STATUS.rejected) {
      const message = errorResponseMessage(createQuoteResponse.payload.errors.code);

      setCoverageValue(getBasicCoverage?.coverage?.id, DEFAULT_BASIC_COVERAGE_VALUE);
      onError(message);
    }
  };

  const handleUpdateQuote = async () => {
    const updateQuoteResponse = await updateQuote(getQuoteState?.id, updateQuotePayloadRef.current);

    if (updateQuoteResponse.meta.requestStatus === FETCH_STATUS.rejected) {
      const message = errorResponseMessage(updateQuoteResponse.payload.errors.code);

      onError(message);
    }
  };

  const createOrUpdateQuote = async () => {
    const isCreated = !!getQuoteState?.id;

    if (isCreated) await handleUpdateQuote();
    else await handleCreateQuote();
  };

  const getBasicCoverageMaxInsuredAmountValue = (insuredAmount) => {
    if (getRestrictionsState.hasRestriction)
      return getRestrictionsState.maxInsuredAmountBasicCoverage || 0;
    return insuredAmount?.max?.value || 0;
  };

  const formik = useFormik({
    initialValues: { ...DEFAULT_BASIC_STEP },
    validationSchema: BasicCoverageValidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {
      if (getRiskState?.propertyUseType === vacateKey) {
        setActiveStep(STEPS_NUMBER.ASSISTANCE);
        return;
      }

      onNext();
    },
  });

  const handleCardClick = (card) => {
    setCardActive(card);
    setShowCoverageModal(true);
  };

  const handleTooltipRebuild = () => ReactTooltip.rebuild();

  const handleEditQuote = (_, value) => {
    formik.setFieldValue('basicCoverageValue', value);

    if (validateValue(value, valueMin, valueMax, setValidation))
      setCoverageValue(getBasicCoverage?.coverage?.id, value);
  };

  const { setValues } = formik;

  useEffect(() => {
    disableLoader();
    setLoading(true);

    createOrUpdateQuote();

    const insuredAmount = getBasicCoverage?.coverage?.insuredAmount || {};
    const basicCoverageMaxAmount = getBasicCoverageMaxInsuredAmountValue(insuredAmount);
    setValueMin(insuredAmount?.min?.value || 0);
    setValueMax(basicCoverageMaxAmount);

    setValues({
      basicCoverageValue: insuredAmount?.value || insuredAmount?.min?.value || 0,
    });

    setLoading(false);
    enableLoader();
  }, [getBasicCoverage?.coverage?.insuredAmount]);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <ReactTooltip id="infoTip" place="top" />

      <Box mt={2} minHeight={HEIGHT_STEP_CONTAINER}>
        <TitleDivider text={BASIC_COVERAGE.title} minWidth={150} />

        <Box>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box px={1}>
                <Box mb={4} data-testid="box-text-basicCoverageStep">
                  <Typography variant="body2" color="textSecondary">
                    <b>A cobertura básica te protege dos seguintes imprevistos:</b>
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  {coveragesBasic.map((item, index) => (
                    <Grid item xs={12} sm={6} key={`basic-coverage-${index}`}>
                      <Paper className="seventh__coverage-paper">
                        <AddIcon color="primary" fontSize="small" className="seventh__coverage-paper__plus-icon" />

                        <Box>
                          <Button
                            fullWidth
                            data-testid={`button-${item.key}-basicCoverageStep`}
                            className="seventh__coverage-paper__button"
                            onClick={() => handleCardClick(item)}
                          >
                            <Box display="flex" alignItems="center">
                              <Box mr={2}>
                                <img src={item.imgUrl} alt={item.alt} loading="eager" />
                              </Box>

                              <Box mr={1} textAlign="left">
                                <Box>
                                  <Typography variant="caption" color="primary">
                                    Em {item.caseOf ? 'caso' : 'queda'} de
                                  </Typography>
                                </Box>

                                <Typography variant="subtitle1">{item.secundaryTitle}</Typography>
                              </Box>
                            </Box>
                          </Button>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            <Hidden smDown>
              <Grid item xs={12} md={1}>
                <Box display="flex" justifyContent="center" height={1}>
                  <Divider orientation="vertical" />
                </Box>
              </Grid>
            </Hidden>

            <Grid item xs={12} md={5}>
              <Box mb={4} pt={isXs ? 1.5 : 0}>
                <Typography variant="body2" color="textSecondary">
                  <b>Valor a receber em caso destes imprevistos:</b>
                </Typography>
              </Box>

              <Box display="flex" mb={2} flexDirection={isXs ? 'column': 'row'} gridRowGap={isXs ? '10px' : '0'}>
                <Box>
                  <Box
                    id="residencial-site-valor-receber-campo"
                    display="flex"
                    alignItems="center"
                    mr={3}
                    width={isXs ? '100%': 200}
                    className="seventh__input-box"
                    data-for="inputTip"
                    data-place="top"
                    data-html="true"
                    data-tip={`Limite Mínimo: ${formatCurrency(valueMin)} <br><br> Limite Máximo: ${formatCurrency(
                      valueMax
                    )}`}
                    sx={isXs ? {
                      '& .MuiFormControl-root': {
                        width: '100%',
                      },
                    }:{
                      '& .MuiFormControl-root': {
                        width: 200,
                      },
                    }}
                  >
                    <CurrencyInput
                      id="basicCoverageValue"
                      variant="outlined"
                      title="Valor da cobertura"
                      {...formik.getFieldProps('basicCoverageValue')}
                      error={formik.touched.basicCoverageValue && !!formik.errors.basicCoverageValue}
                      helperText={formik.touched.basicCoverageValue && formik.errors.basicCoverageValue}
                      onChange={handleEditQuote}
                      max={valueMax}
                      inputProps={{ 'data-testid': 'input-coveragevalue-basicCoverageStep' }}
                      onClick={handleTooltipRebuild}
                      onBlur={handleTooltipRebuild}
                      disabled={loading}
                    />

                    <ReactTooltip id="inputTip" event="focusin" eventOff="focusout" />

                    {loading && (
                      <Box ml={-4.64} width={30}>
                        <CircularProgress className="seventh__circular-progress" />
                      </Box>
                    )}
                  </Box>

                  {validation !== '' && (
                    <Box>
                      <Typography variant="caption" color="error">
                        {validation}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box display="flex" alignItems="center">
                  <Box mr={1}>
                    <WarningIcon fontSize="small" htmlColor="#ffb500" />
                  </Box>

                  <Typography variant="caption">
                    <i>Não há franquia.</i>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ButtonsControl
        dataTestid="button-forward-basicCoverageStep"
        onBack={onBack}
        onNext={formik.submitForm}
        disabled={loading || validation !== ''}
        gridBack={isXs ? 4 : 3}
        gridForward={isXs ? 4 : 3}
      />

      <PrivacyModal
        show={showCoverageModal}
        setShowPrivacyModal={setShowCoverageModal}
        setCardActive={setCardActive}
        cardActive={cardActive}
      />
    </>
  );
};

BasicCoverageStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default BasicCoverageStep;
