import { makeStyles } from "@material-ui/core";

export const modalStyles = makeStyles((theme) => ({
  btn: {
    width: '100%',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  modal: {
    paddingBottom: theme.spacing(4),
    overflow: 'auto',
  },
  border: {
    '& .MuiPaper-root': {
      borderRadius: 14,
    },
  },
  thumb: {
    maxHeight: 200,
    width: '100%'
  }
}));

export const dateInputStyles = makeStyles(() => ({
  dateInput: {
    '& Button': {
      padding: '0px !important',
    },
  },
}));