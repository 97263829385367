import logoXP from 'assets/img/general/Logos/xp.png';
import logoDefault from 'assets/img/general/Logos/pottencial_residencial.png';
import logoGallagher from 'assets/img/general/Logos/gallagher.png';
import logoCoopercitrus from 'assets/img/general/Logos/coopercitrus.png';
import promotionalXP from 'assets/img/general/PromotionalLogos/Promotional_XP.png';
import useGlobal from "./useGlobal";

export default () => {
  const { location } = useGlobal();

  const brokerUrlPathName = location.pathname.toUpperCase();

  const getBroker = () => {
    if (brokerUrlPathName.includes(window.ENV.BROKER.XP.URL_PATH.toUpperCase())) return window.ENV.BROKER.XP;
    if (brokerUrlPathName.includes(window.ENV.BROKER.GALLAGHER.URL_PATH.toUpperCase())) return window.ENV.BROKER.GALLAGHER;
    if (brokerUrlPathName.includes(window.ENV.BROKER.COOPERCITRUS.URL_PATH.toUpperCase())) return window.ENV.BROKER.COOPERCITRUS;

    return window.ENV.BROKER.DEFAULT;
  };

  const getBrokerLogo = () => {
    if (brokerUrlPathName.includes(window.ENV.BROKER.XP.URL_PATH.toUpperCase())) return logoXP;
    if (brokerUrlPathName.includes(window.ENV.BROKER.GALLAGHER.URL_PATH.toUpperCase())) return logoGallagher;
    if (brokerUrlPathName.includes(window.ENV.BROKER.COOPERCITRUS.URL_PATH.toUpperCase())) return logoCoopercitrus;

    return logoDefault;
  };

  const getBrokerRedirectUrl = () => {
    if (brokerUrlPathName.includes(window.ENV.BROKER.XP.URL_PATH.toUpperCase())) return window.ENV.BROKER.XP.URL_PATH;
    if (brokerUrlPathName.includes(window.ENV.BROKER.GALLAGHER.URL_PATH.toUpperCase())) return window.ENV.BROKER.GALLAGHER.URL_PATH;
    if (brokerUrlPathName.includes(window.ENV.BROKER.COOPERCITRUS.URL_PATH.toUpperCase())) return window.ENV.BROKER.COOPERCITRUS.URL_PATH;

    return '/';
  };

  const getBrokerPromotionalLogo = () => {
    if (brokerUrlPathName.includes(window.ENV.BROKER.XP.URL_PATH.toUpperCase())) return promotionalXP;

    return null;
  };

  return {
    getBroker,
    getBrokerLogo,
    getBrokerRedirectUrl,
    getBrokerPromotionalLogo,
    getCurrentBroker: brokerUrlPathName
  };
};