import { useCallback } from 'react';

import { DEFAULT_THEME } from '../constants/defaults';

const useSettings = () => {
  const getColor = useCallback(() => DEFAULT_THEME, []);

  return {
    getColor,
  };
};

export default useSettings;
