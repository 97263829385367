import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Grid, Button, Dialog, Backdrop, Typography, makeStyles, DialogContent } from '@material-ui/core';

import icoError from 'assets/img/icon-alert-danger.svg';
import icoAlert from 'assets/img/icon-alert-warning.svg';
import icoSuccess from 'assets/img/icon-alert-success.svg';

const useStyles = makeStyles((theme) => ({
  btn: {
    width: '100%',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  modal: {
    paddingBottom: theme.spacing(4),
    overflow: 'auto',
  },
  border: {
    '& .MuiPaper-root': {
      borderRadius: 14,
    },
  },
}));

const MessageBox = (props) => {
  const classes = useStyles();
  const {
    opened,
    type,
    title,
    text,
    thumb,
    labelPrimary,
    labelSecondary,
    handleClose,
    handlePrimary,
    handleSecondary,
    children,
    buttonPosition,
    maxWidth,
    dataTestId,
  } = props;

  const getImageByType = (typeImg) => {
    switch (typeImg) {
      case 'error':
        return icoError;
      case 'warning':
        return icoAlert;
      case 'success':
        return icoSuccess;
      default:
        return thumb;
    }
  };

  return (
    <Dialog
      open={opened}
      maxWidth={maxWidth}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      disableEscapeKeyDown
      fullWidth
      className={classes.border}
    >
      <DialogContent className={classes.modal} data-testid={dataTestId}>
        <CloseIcon title="Fechar" onClick={handleClose} className={classes.close} />

        {(thumb || type) && (
          <Box mt={2} mb={3} align="center">
            <img src={getImageByType(type)} alt="thumb" />
          </Box>
        )}

        {title && (
          <Box mt={2} mb={3}>
            <Typography align="center" variant="h5">
              {title}
            </Typography>
          </Box>
        )}

        {text && (
          <Box mt={2} mb={3} align="center">
            <Typography align="center">{text}</Typography>
          </Box>
        )}

        {children}

        {buttonPosition === 'nextTo' && (
          <Grid pb={2} container justifyContent="center" spacing={2}>
            {handleSecondary && (
              <Grid item lg={4} sm={6} xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.btn}
                  onClick={handleSecondary}
                  title={labelSecondary}
                >
                  {labelSecondary}
                </Button>
              </Grid>
            )}

            {handlePrimary && (
              <Grid item lg={4} sm={6} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                  onClick={handlePrimary}
                  title={labelPrimary}
                >
                  {labelPrimary}
                </Button>
              </Grid>
            )}
          </Grid>
        )}

        {buttonPosition === 'below' && (
          <Grid pb={3} container justifyContent="center" spacing={2}>
            {handlePrimary && (
              <Grid item sm={8} xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.btn}
                  onClick={handlePrimary}
                  title={labelPrimary}
                >
                  {labelPrimary}
                </Button>
              </Grid>
            )}

            {handleSecondary && (
              <Grid item sm={8} xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.btn}
                  onClick={handleSecondary}
                  title={labelSecondary}
                >
                  {labelSecondary}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

MessageBox.propTypes = {
  opened: PropTypes.bool,
  children: PropTypes.element,
  buttonPosition: PropTypes.oneOf(['nextTo', 'below']),
  maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
  type: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
  handlePrimary: PropTypes.func,
  handleSecondary: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  thumb: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  labelPrimary: PropTypes.string,
  labelSecondary: PropTypes.string,
  dataTestId: PropTypes.string,
};

MessageBox.defaultProps = {
  type: 'info',
  maxWidth: 'sm',
  buttonPosition: 'nextTo',
  opened: false,
  text: undefined,
  thumb: undefined,
  title: undefined,
  labelPrimary: 'Confirmar',
  labelSecondary: 'Cancelar',
  handlePrimary: undefined,
  handleSecondary: undefined,
  children: undefined,
  dataTestId: '',
};

export default MessageBox;
