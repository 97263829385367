import requestService from './requestService';

class RequestManager {
  searchPersonByDocumentNumber = requestService.searchPersonByDocumentNumber;

  getAdditionalCoverages = requestService.getAdditionalCoverages;

  getBasicCoverage = requestService.getBasicCoverage;

  createQuote = requestService.createQuote;

  updateQuote = requestService.updateQuote;

  createPerson = requestService.createPerson;

  createHefesto = requestService.createHefesto;

  createProposal = requestService.createProposal;

  acceptTerms = requestService.acceptTerms;

  getQuotePDF = requestService.getQuotePDF;

  getPaymentsUrl = requestService.getPaymentsUrl;

  getProposalDocumentByOrderId = requestService.getProposalDocumentByOrderId;

  getAddressByZipCode = requestService.getAddressByZipCode;

  getPersonById = requestService.getPersonById;

  getRestrictionInsuredAmountByZipCode = requestService.getInsuredAmountRestrictionByZipCode;
}

export default new RequestManager();
