import React, { useState, useMemo } from 'react';
import { Router, useHistory } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import Loader from 'components/Loader';
import AlertEmiter from 'components/Alert/Alert';
import useBroker from 'hooks/useBroker';
import useGlobal from 'hooks/useGlobal';
import { ROUTES } from 'constants/defaults';
import SocialNameModal from 'components/SocialName/SocialNameModal';
import { createThemeMaterial } from './theme';
import Routes from './Routes';
import Main from './layouts/Main';
import AxiosSetting from './helpers/axios';
import useSettings from './hooks/useSettings';
import './helpers/yup';
import 'assets/style/global.scss';
import 'assets/style/main.scss';

const App = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { searchParams } = useGlobal();

  const { getColor } = useSettings();
  const { getBroker } = useBroker();
  const color = getColor();
  const theme = createThemeMaterial(color);

  const handleError = useMemo(
    () => ({
      403: () => {
        history.push(`${getBroker().URL_PATH}/${ROUTES.Contratacao + searchParams}`);
      },
    }),
    [history]
  );

  return (
    <>
      <AxiosSetting
        handleError={handleError}
        onStartRequest={() => setLoading(true)}
        onStopRequest={() => setLoading(false)}
      />
      <ThemeProvider theme={theme}>
        <AlertEmiter />
        <Loader show={loading} />
        <SocialNameModal />

        <Main>
          <Router history={history}>
            <Routes />
          </Router>
        </Main>
      </ThemeProvider>
    </>
  );
};

export default App;
