import { useCallback } from 'react';
import { isEmpty, find, forEach } from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import { TYPE_HOUSES, TYPE_MAKE_HOUSES, TYPE_IS_HOUSES } from 'constants/collections';
import { TYPE_HOUSES_KEYS } from 'constants/defaults';

moment.locale('pt-br');

const useUtils = () => {
  const getOnlyNumber = useCallback((value = '') => value.replace(/\D/g, ''), []);

  const isBlank = useCallback((value) => isEmpty(value) || value.trim().length === 0, []);

  const formatCEP = useCallback(
    (value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3'),
    [isBlank]
  );

  const formatCPF = useCallback(
    (value) => !isBlank(value) && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
    [isBlank]
  );

  const formatCNPJ = useCallback(
    (value) => !isBlank(value) && value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'),
    [isBlank]
  );

  const formatHours = useCallback((value) => value && moment(value).format('LT'), []);

  const formatDate = useCallback((value) => value && moment(value).format('DD/MM/YYYY'), []);

  const formatDateApi = useCallback((value) => value && moment(value).format('YYYY/MM/DD'), []);

  const formatDateNews = useCallback(
    (value) => value && `${moment(value).format('DD')} de ${moment(value).format('MMMM YYYY')}`,
    []
  );

  const formatPhoneOrCellphone = useCallback(
    (value) => {
      if (!isBlank(value)) {
        let number = getOnlyNumber(value);
        number = number.replace(/^(\d{2})(\d)/g, '($1) $2');
        number = number.replace(/(\d)(\d{4})$/, '$1-$2');
        return number;
      }

      return '';
    },
    [isBlank, getOnlyNumber]
  );

  const formatCurrency = useCallback(
    (value) => {
      let formatValue;

      const currency = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      if (!isBlank(value.toString())) {
        formatValue = currency.format(value.toString());
      } else {
        formatValue = 'R$ 0,00';
      }

      return formatValue;
    },
    [isBlank]
  );

  const date = useCallback((value) => (value ? moment(value) : moment()), []);

  const getGrammar = useCallback((value) => {
    const possibilities = [TYPE_HOUSES_KEYS.apartment.key];

    const matcher = possibilities.includes(value);

    return {
      pronoun: matcher ? 'ele' : 'ela',
      pronounPossessive: matcher ? 'seu' : 'sua',
      gender: matcher ? 'o' : 'a',
    };
  }, []);

  const getNameProperty = useCallback(
    (contract) => find(TYPE_HOUSES, (type) => type.key === contract?.propertyType)?.title || '',
    []
  );

  const getNameConstruction = useCallback((contract) => {
    const name = find(TYPE_MAKE_HOUSES, (type) => type.key === contract?.constructionType)?.title || '';
    return name.toLowerCase();
  }, []);

  const getNameType = useCallback((contract) => {
    const name = find(TYPE_IS_HOUSES, (type) => type.key === contract?.propertyUseType)?.title || '';
    return name.toLowerCase();
  }, []);

  const setMarkMenu = useCallback((anchor) => {
    const elementsToRemove = document.querySelectorAll('.link-right');
    forEach(elementsToRemove, (item) => {
      item.classList.remove('active');
    });

    const elementsToAdd = document.querySelectorAll(`[anchor="#${anchor}"]`);
    forEach(elementsToAdd, (item) => {
      item.classList.add('active');
    });
  }, []);

  const downloadPDF = useCallback((pdf, name) => {
    const link = document.createElement('a');
    const linkSource = `data:application/pdf;base64,${pdf}`;
    link.download = name;
    link.href = linkSource;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const sortCoverages = (a, b) => {
    if (a.coverage?.name < b.coverage?.name) {
      return -1;
    }
    if (a.coverage?.name > b.coverage?.name) {
      return 1;
    }
    return 0;
  };

  const removeEmptyObjects = (array) =>  {
    const newArray = [];
  
    array.forEach(element => {
      if (Object.keys(element).length !== 0) {
        newArray.push(element);
      }
    });
  
    return newArray;
  };

  return {
    date,
    isBlank,
    formatCPF,
    formatCNPJ,
    formatCEP,
    formatDate,
    formatHours,
    formatDateApi,
    getOnlyNumber,
    formatDateNews,
    formatPhoneOrCellphone,
    formatCurrency,
    getNameProperty,
    getNameConstruction,
    getNameType,
    getGrammar,
    setMarkMenu,
    downloadPDF,
    sortCoverages,
    removeEmptyObjects,
  };
};

export default useUtils;
