import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { requestActions, requestOperations } from "../redux";
import { getTermService } from "../redux/requestSelectors";

export default () => {
  const dispatch = useAppDispatch();

  const getTermState = useSelector(getTermService);

  const setTermServiceOpen = (openTerm) => dispatch(requestActions.setTermServiceOpen({ openTerm }));

  const acceptTermService = async (termPayload) => dispatch(requestOperations.acceptTerms(termPayload));

  return {
    getTermState,
    setTermServiceOpen,
    acceptTermService
  };
};
