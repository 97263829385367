import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, useMediaQuery } from '@material-ui/core';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import { findLast } from 'lodash';
import MessageBox from 'components/MessageBox/MessageBox';
import { useAlert } from 'components/Alert/Alert';
import useHelpers from 'hooks/useHelpers';
import useGlobal from 'hooks/useGlobal';
import useCoverages from 'hooks/useCoverages';
import useQuote from 'hooks/useQuote';
import usePayloads from 'hooks/usePayloads';
import { useLoader } from 'components/Loader';
import { MESSAGE_COVERAGEADDITIONAL_ERROR } from 'constants/messages';
import { STEPS_NUMBER, HEIGHT_STEP_CONTAINER, FETCH_STATUS } from 'constants/defaults';
import { ADDITIONAL_COVERAGES } from 'constants/textsSteps';
import ButtonsControl from '../shared/ButtonsControl';
import TitleDivider from '../shared/TitleDivider';
import DetailModal from './DetailModal';
import Arrow from './Arrow';
import CardCoverage from './CardCoverage';

const AdditionalCoverageStep = ({ onBack, onNext }) => {
  const [openRemoveCoverage, setOpenRemoveCoverage] = useState(false);
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [currentCoverageModal, setCurrentCoverageModal] = useState({});
  const [validation, setValidation] = useState('');
  const [validationId, setValidationId] = useState('');
  const [idToRemove, setIdToRemove] = useState('');

  const { getAdditionalCoverages, setCoverageValue, getSelectedCoverages, getCoveragesState } = useCoverages();
  const { setActiveStep } = useGlobal();
  const { getQuoteState, updateQuote, errorResponseMessage } = useQuote();
  const { updateQuotePayloadRef } = usePayloads();

  const { addMsgDanger } = useAlert();
  const { getValueMax, validateValueCoverage, getIcon } = useHelpers();
  const { disableLoader, enableLoader } = useLoader();

  const downSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const downMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const upXL = useMediaQuery((theme) => theme.breakpoints.up('xl'));

  const getScale = useCallback(() => {
    if (downSM) return 1;
    if (downMD) return 2;
    if (downLG) return 3;
    if (upXL) return 4;

    return 3;
  }, [downSM, downMD, downLG, upXL]);

  const settingsSlider = useMemo(
    () => ({
      dots: false,
      infinite: true,
      slidesToShow: getScale(),
      slidesToScroll: getScale(),
      autoplay: false,
      nextArrow: <Arrow Component={ArrowForwardIos} />,
      prevArrow: <Arrow Component={ArrowBackIos} />,
    }),
    [getScale]
  );

  const getValidation = useCallback(
    (coverageId) => validation !== '' && validationId === coverageId,
    [validation, validationId]
  );

  const isSelectedCoverage = (id) => {
    const current = findLast(
      getSelectedCoverages,
      (coverageItem) => coverageItem?.coverage?.id === id && coverageItem?.coverage?.insuredAmount?.value
    );
    return !!current;
  };

  const addOrUpdateCoverage = (id, value) => setCoverageValue(id, value);

  const removeCoverage = (id) => setCoverageValue(id, 0);

  const handleChange = (coverage, value) => {
    const isValid = validateValueCoverage(coverage, value, setValidation, setValidationId);
    if (isValid) addOrUpdateCoverage(coverage?.id, value);
    else if (value === 0) removeCoverage(coverage?.id);
  };

  const getValueCoverage = (id) => {
    if (isSelectedCoverage(id)) {
      const current = findLast(getSelectedCoverages, (coverageItem) => coverageItem?.coverage?.id === id);
      const value = current?.coverage?.insuredAmount?.value || '';

      if (current) {
        return value;
      }
    }

    return 0;
  };

  const confirmRemoveCoverage = () => {
    removeCoverage(idToRemove);
    setOpenRemoveCoverage(false);
  };

  const backRemoveCoverage = () => {
    setOpenRemoveCoverage(false);
  };

  useEffect(() => {
    if (!getAdditionalCoverages) {
      addMsgDanger(MESSAGE_COVERAGEADDITIONAL_ERROR);
      setActiveStep(STEPS_NUMBER.BASIC_COVERAGE);
    }
  }, []);

  const handleUpdateQuote = async () => {
    disableLoader();
    const updateQuoteResponse = await updateQuote(getQuoteState?.id, updateQuotePayloadRef.current);
    enableLoader();
    if (updateQuoteResponse.meta.requestStatus === FETCH_STATUS.rejected) {
      const message = errorResponseMessage(updateQuoteResponse.payload.errors?.code);

      addMsgDanger(message);
    }
  };

  useEffect(() => {
    handleUpdateQuote();
  }, [getCoveragesState]);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <MessageBox
        opened={openRemoveCoverage}
        handleClose={backRemoveCoverage}
        title="Remover cobertura"
        text={ADDITIONAL_COVERAGES.removeCoverage}
        handleSecondary={backRemoveCoverage}
        handlePrimary={confirmRemoveCoverage}
        maxWidth="xs"
        type="warning"
        dataTestId="dialog-confirm-remove"
      />
      <>
        <ReactTooltip id="tipValueMinMax" type="dark" place="top" />

        <ReactTooltip id="tipTitle" type="dark" place="top" />

        <Box mt={2} minHeight={HEIGHT_STEP_CONTAINER}>
          <TitleDivider text={ADDITIONAL_COVERAGES.title} minWidth={190} />

          <Box mb={2} className="eighth__slider-box" id="KEKW">
            <Slider {...settingsSlider}>
              {getAdditionalCoverages?.map((item, index) => (
                <CardCoverage
                  key={item?.coverage?.id}
                  index={index}
                  object={item}
                  isValid={getValidation(item?.coverage?.id)}
                  valueMax={getValueMax(item?.coverage)}
                  validation={validation}
                  handleChange={handleChange}
                  setIdToRemove={setIdToRemove}
                  valueCoverage={getValueCoverage(item?.coverage?.id)}
                  isSelectedCoverage={isSelectedCoverage(item?.coverage?.id)}
                  setShowModalDetails={setShowModalDetails}
                  setOpenRemoveCoverage={setOpenRemoveCoverage}
                  setCurrentCoverageModal={setCurrentCoverageModal}
                />
              ))}
            </Slider>
          </Box>
        </Box>

        {showModalDetails && (
          <DetailModal
            open={showModalDetails}
            setOpen={setShowModalDetails}
            coverage={currentCoverageModal}
            icon={getIcon(currentCoverageModal?.icon)}
          />
        )}

        <ButtonsControl
          dataTestid="button-forward-additionalCoverageStep"
          onBack={onBack}
          onNext={onNext}
          gridBack={isXs ? 4 : 3}
          gridForward={isXs ? 4 : 3}
        />
      </>
    </>
  );
};

AdditionalCoverageStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default AdditionalCoverageStep;
