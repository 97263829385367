import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useLoader } from 'components/Loader/Loader';
import MessageBox from 'components/MessageBox/MessageBox';
import { FINISH } from 'constants/textsSteps';
import useProposal from 'hooks/useProposal';
import { FETCH_STATUS, ROUTES } from 'constants/defaults';
import useBroker from 'hooks/useBroker';

const CreditCardCompletionStep = () => {
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const { getProposalDocument } = useProposal();
  const { getBroker } = useBroker();
  const { orderId } = useParams();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const handleGetProposalUrl = async () => {
    showLoader();

    const getProposalDocumentResponse = await getProposalDocument(orderId);

    if (getProposalDocumentResponse.meta.requestStatus === FETCH_STATUS.rejected)
    {
      hideLoader();
      setOpenErrorModal(true);
      return;
    }

    hideLoader();
    history.push(`${getBroker().URL_PATH}/${ROUTES.CreditCardConclusion}`);

  };

  useEffect(() => {
    handleGetProposalUrl();
  }, []);

  return (
    <div style={{ backgroundColor: '#f26522', width: '100vw', height: '100vh' }}>
      {openErrorModal && (
        <MessageBox
          type="warning"
          text={FINISH.errorRecoveryProposal}
          opened={openErrorModal}
          labelPrimary="Ir para o portal"
          handleClose={() => window.open(window.ENV.CUSTOMER_URL, '_self')}
          handlePrimary={() => window.open(window.ENV.CUSTOMER_URL, '_self')}
          maxWidth="sm"
        />
      )}
    </div>
  );
};

export default CreditCardCompletionStep;
