import React from 'react';
import { ASSISTANCE } from 'constants/textsSteps';
import StepText from '../shared/StepText';

const AboutPropertyStepText = () => {

  const isMobile = window.innerWidth <= 1280; 

  const texts = [isMobile ? ASSISTANCE.textHeaderMobile : ASSISTANCE.textHeader];

  return (
    <>
      <StepText textArray={texts} />
    </>
  );
  
};

export default AboutPropertyStepText;
