import { COVERAGE_TYPE, TYPE_IS_HOUSES_KEYS } from "constants/defaults";
import { useDispatch, useSelector } from "react-redux";
import { requestActions, requestOperations } from "../redux";
import { getCoverages } from "../redux/requestSelectors";
import useRisk from "./useRisk";
import useUtils from "./useUtils";

export default () => {
  const { getRiskState } = useRisk();
  const { sortCoverages } = useUtils();
  
  const dispatch = useDispatch();

  const getCoveragesState = useSelector(getCoverages);

  const loadCoverages = async () => dispatch(requestOperations.getAllCoverages());

  const getBasicCoverage = [...getCoveragesState].find(f => f.type === COVERAGE_TYPE.basic);

  const getAdditionalCoverages = [...getCoveragesState].sort(sortCoverages).filter(f => f.type === COVERAGE_TYPE.additional);

  const getSelectedCoverages = [...getCoveragesState].sort(sortCoverages).filter(f => f.coverage?.insuredAmount?.value);

  const setCoverageValue = (id, value) => dispatch(requestActions.updateCoverageValue({id, value}));

  const removeCoveragesForVacateProperty = () => {
    if (getRiskState?.propertyUseType === TYPE_IS_HOUSES_KEYS.vacate.key)
    {
      getAdditionalCoverages?.forEach(coverageItem => setCoverageValue(coverageItem?.coverage.id, 0));
    }
  };

  return {
    getCoveragesState,
    loadCoverages,
    getBasicCoverage,
    getAdditionalCoverages,
    setCoverageValue,
    getSelectedCoverages,
    removeCoveragesForVacateProperty
  };

};
