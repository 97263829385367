import React from "react";
import { Box } from "@material-ui/core";
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, mobile }) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <Box py={3} px={ mobile ? 0 : 3}>{children}</Box>}
  </Box>
);

TabPanel.propTypes = {
  children: PropTypes.element.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  mobile: PropTypes.any.isRequired
};

export default TabPanel;