import { useDispatch, useSelector } from "react-redux";
import { getPartner } from "redux/requestSelectors";
import { requestActions, requestOperations } from "../redux";
import useBroker from "./useBroker";
import useGlobal from "./useGlobal";

export default () => {
  const dispatch = useDispatch();
  const { searchParams } = useGlobal();
  const { getCurrentBroker } = useBroker();
  const partnerId = new URLSearchParams(searchParams).get('p');

  const getPartnerState = useSelector(getPartner);

  const validatePartner = async () => {
    dispatch(requestActions.setPartner({ isValid: true }));
    if (getCurrentBroker.includes(window.ENV.BROKER.XP.URL_PATH.toUpperCase()) && partnerId)
      return dispatch(requestOperations.getPersonById(partnerId));

    return Promise.resolve(true);
  };

  const disableContractLink = () => !getPartnerState?.isValid;


  return {
    getPartnerState,
    validatePartner,
    disableContractLink,
  };
};