import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import icoAlert from 'assets/img/general/ico-alert-info.svg';
import Svg from 'react-inlinesvg';
import ModalInfo from 'components/ModalInfo/ModalInfo';
import PropTypes from 'prop-types';

import { EventDrivenModalProps } from './types';

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': { maxWidth: '550px !important' },
  },
  buttons: {
    float: 'right',
  },
  buttom: {
    margin: '30px 0px 30px 30px',
  },
  boxAlert: {
    display: 'flex',
    marginTop: 20,
    padding: 16,
    borderRadius: 10,
    border: '1px solid #e3c869',
    background: '#fff8e5',
  },
  boxAlertText: {
    marginLeft: 10,
    fontSize: 15,
    fontWeight: 700,
    color: '#996D00',
  },
}));

const UseSocialNameModal: React.FC<EventDrivenModalProps> = ({ open, callReturnEvent }) => {
  const classes = useStyles();

  return (
    <>
      <ModalInfo
        open={open}
        className={classes.modal}
        onClose={() => {
          callReturnEvent(false);
        }}
      >
        <Box textAlign="left" p={4}>
          <Typography variant="h5">Uso do nome social</Typography>

          <Typography variant="body1">
            <br />
            Nome social <b>NÃO</b> é apelido!
            <br />
            <br />
            É a escolha de um nome diferente do registrado oficialmente, feita pela
            <br />
            própria pessoa para refletir sua identidade de gênero. Veja o exemplo abaixo:
            <br />
            <br />
            Nome de registro: João Pereira da Silva
            <br />
            <br />
            Nome social: Joana Pereira da Silva
            <br />
          </Typography>
          <Box className={classes.boxAlert}>
            <Svg src={icoAlert} width={60} height={30} />
            <Typography className={classes.boxAlertText}>
              Ao optar pela utilização do nome social, nossas comunicações e novas emissões de documentos passarão a
              contemplar essa informação.
            </Typography>
          </Box>
          <Box className={classes.buttons}>
            <Button
              className={classes.buttom}
              color="primary"
              title="Não usar nome social"
              onClick={() => {
                callReturnEvent(false);
              }}
            >
              Não usar nome social
            </Button>
            <Button
              className={classes.buttom}
              color="primary"
              title="Usar nome social"
              variant="contained"
              onClick={() => {
                callReturnEvent(true);
              }}
            >
              Usar nome social
            </Button>
          </Box>
        </Box>
      </ModalInfo>
    </>
  );
};

UseSocialNameModal.propTypes = {
  open: PropTypes.bool.isRequired,
  callReturnEvent: PropTypes.func.isRequired,
};

export default UseSocialNameModal;
