import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import { TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

const CPFInput = (props: any) => {
  const { id, onChange, onBlur } = props;

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const onlyNumbers = event.target.value.replace(/\D/g, '');

    onBlur({
      target: { id, value: onlyNumbers },
    });
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const onlyNumbers = event.target.value.replace(/\D/g, '');

    onChange({
      target: { id, value: onlyNumbers },
    });
  };

  return (
    <InputMask {...props} maskChar={null} mask="999.999.999-99" onChange={handleChange} onBlur={handleBlur}>
      {(inputProps: any) => <TextField {...inputProps} />}
    </InputMask>
  );
};

export default CPFInput;
