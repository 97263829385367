import PropTypes from 'prop-types';
import { OFFENSIVE_SOCIALNAME_MODAL } from 'constants/socialNameMessages';
import { EventDrivenModalProps, modalInfoProps } from './types';
import SocialNameModalInfo from './SocialNameModalInfo';

const SocialNameOffensiveWordsModal: React.FC<EventDrivenModalProps> = ({ open, callReturnEvent }) => {
  const modalProps: modalInfoProps = {
    open,
    title: OFFENSIVE_SOCIALNAME_MODAL.TITLE,
    confirmedButton: OFFENSIVE_SOCIALNAME_MODAL.CONFIRMED_BUTTON,
    unconfimedButton: OFFENSIVE_SOCIALNAME_MODAL.UNCONFIRMED_BUTTON,
    callReturnEvent,
    openSupportChannel: true,
  };

  return (
    <>
      <SocialNameModalInfo {...modalProps} />
    </>
  );
};

SocialNameOffensiveWordsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  callReturnEvent: PropTypes.func.isRequired,
};

export default SocialNameOffensiveWordsModal;
