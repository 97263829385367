import { Typography } from '@material-ui/core';

const SocialNameDisclaimer: React.FC = () => (
  <>
    <Typography variant="body2">Nome social NÃO é apelido!</Typography>
    <br />
    <Typography variant="body2">
      O nome social é uma opção de adoção de um nome diferente do nome oficialmente registrado no cartório de
      nascimento, que é escolhido pela própria interessada. Essa escolha é importante para que a pessoa seja
      identificada adequadamente e tratada de acordo com a sua identidade de gênero na sociedade.
    </Typography>
  </>
);

export default SocialNameDisclaimer;
