/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { RESTRICTDOCUMENTERRORCODE } from 'constants/defaults';
import { requestActions, requestOperations } from '.';

export const INITIAL_STATE = {
  coverages: [],
  participant: {
    personId: '',
    document: '',
    email: '',
    phone: '',
    name: '',
    personName: undefined,
    personSocialName: undefined,
  },
  payments: {
    type: '',
    url: '',
    isBillet: false,
    firstDueDate: '',
    installmentNumber: 0,
  },
  proposal: {
    sequencial: '',
    url: '',
    id: '',
  },
  quote: {
    id: '',
    createdAt: '',
    updatedAt: '',
    quoteSequential: 0,
    status: '',
    commercialPremium: 0,
    purePremium: 0,
    grossPremium: 0,
    iof: 0,
    discount: 0,
    commercialLoadingFee: 0,
    installments: [
      {
        installmentNumber: 0,
        amount: 0,
      },
    ],
    quoteCoverages: [],
    productCoverages: [],
  },
  riskData: {
    historicalProtectedProperty: false,
    sharedProperty: false,
    insuredOwner: true,
    documentPeriodStart: '',
    documentPeriodEnd: '',
    insuredAmount: 0,
    propertyType: '',
    constructionType: '',
    propertyUseType: '',
    address: {
      type: 'Residential',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      uf: '',
      zipCode: '',
      country: '',
    },
    addressDisabled: false,
  },
  termService: {
    acceptedTerm: false,
    openTerm: false,
  },
  trustService: {
    trustedServiceAgreement: false,
    trustedCommunicationsPottencial: false,
  },
  slide: 'right',
  step: 0,
  finishing: false,
  partner: {
    id: '',
    isValid: true,
  },
  restrictions: {
    hasRestriction: false,
    maxInsuredAmountBasicCoverage: 0,
  },
};

const stateQuote = (action) => ({
  ...INITIAL_STATE.quote,
  error: {
    ...action.payload?.errors,
    status: action.payload?.status,
  },
});

const requestReducer = createReducer(INITIAL_STATE, (builder) => {
  // request Actions
  builder
    .addCase(requestActions.setSlide, (state, action) => {
      const { slide } = action.payload;
      state.slide = slide;
    })
    .addCase(requestActions.setTermServiceOpen, (state, action) => {
      const { openTerm } = action.payload;
      state.termService = {
        ...state.termService,
        openTerm,
      };
    })
    .addCase(requestActions.setStep, (state, action) => {
      const { step } = action.payload;
      state.step = step;
    })
    .addCase(requestActions.setAddressDisabled, (state, action) => {
      const { addressDisabled } = action.payload;
      state.riskData.addressDisabled = addressDisabled;
    })
    .addCase(requestActions.setFinishing, (state, action) => {
      const { isFinishing } = action.payload;
      state.finishing = isFinishing;
    })
    .addCase(requestActions.setReset, (state) => {
      state.coverages = INITIAL_STATE.coverages;
      state.participant = INITIAL_STATE.participant;
      state.payments = INITIAL_STATE.payments;
      state.proposal = INITIAL_STATE.proposal;
      state.quote = INITIAL_STATE.quote;
      state.riskData = INITIAL_STATE.riskData;
      state.termService = INITIAL_STATE.termService;
      state.trustService = INITIAL_STATE.trustService;
      state.restrictions = INITIAL_STATE.restrictions;
    })
    .addCase(requestActions.setResetFinish, (state) => {
      state.step = INITIAL_STATE.step;
      state.finishing = INITIAL_STATE.finishing;
      state.slide = INITIAL_STATE.slide;
    })
    .addCase(requestActions.setParticipant, (state, action) => {
      state.participant = {
        ...state.participant,
        ...action.payload,
      };
    })
    .addCase(requestActions.setRisk, (state, action) => {
      state.riskData = {
        ...state.riskData,
        ...action.payload,
      };
    })
    .addCase(requestActions.setAddress, (state, action) => {
      state.riskData.address = {
        ...state.riskData.address,
        ...action.payload,
      };
    })
    .addCase(requestActions.trustServiceAgreement, (state, action) => {
      const { isTrusted } = action.payload;
      state.trustService.trustedServiceAgreement = isTrusted;
    })
    .addCase(requestActions.trustServiceCommunications, (state, action) => {
      const { isTrusted } = action.payload;
      state.trustService.trustedCommunicationsPottencial = isTrusted;
    })
    .addCase(requestActions.updateCoverageValue, (state, action) => {
      const { id, value } = action.payload;
      const coverageIndex = state.coverages?.findIndex((f) => f.coverage.id === id);

      return {
        ...state,
        coverages: state.coverages.map((coverageItem, coverageItemIndex) =>
          coverageItemIndex === coverageIndex
            ? {
                ...coverageItem,
                coverage: {
                  ...coverageItem.coverage,
                  insuredAmount: {
                    ...coverageItem.coverage.insuredAmount,
                    value,
                  },
                },
              }
            : coverageItem
        ),
      };
    })
    .addCase(requestActions.setPayment, (state, action) => {
      state.payments = {
        ...state.payments,
        ...action.payload,
      };
    })
    .addCase(requestActions.setPartner, (state, action) => {
      state.partner = {
        ...action.payload,
      };
    });

  // request Operations
  builder
    .addCase(requestOperations.searchPersonByDocument.fulfilled, (state, action) => {
      const { name, socialName } = action.payload;

      state.participant = {
        ...state.participant,
        name: socialName ?? name,
        personName: name,
        personSocialName: socialName,
      };
    })
    .addCase(requestOperations.searchPersonByDocument.rejected, (state, action) => {
      state.participant = {
        ...state.participant,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
        isRestricted: action.payload?.data?.code === RESTRICTDOCUMENTERRORCODE,
      };
    })
    .addCase(requestOperations.getAllCoverages.fulfilled, (state, action) => {
      if (state.coverages.length === 0) {
        return {
          ...state,
          coverages: [...state.coverages, ...action.payload],
        };
      }
      return {
        ...state,
      };
    })
    .addCase(requestOperations.getAllCoverages.rejected, (state, action) => {
      state.coverages = {
        ...state.coverages,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
    })
    .addCase(requestOperations.createQuote.fulfilled, (state, action) => {
      state.quote = { ...action.payload };
    })
    .addCase(requestOperations.createQuote.rejected, (state, action) => {
      state.quote = stateQuote(action);
    })
    .addCase(requestOperations.updateQuote.fulfilled, (state, action) => {
      state.quote = { ...action.payload };
    })
    .addCase(requestOperations.updateQuote.rejected, (state, action) => {
      state.quote = {
        ...state.quote,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
    })
    .addCase(requestOperations.createPerson.fulfilled, (state, action) => {
      const { personId } = action.payload;
      state.participant = {
        ...state.participant,
        personId,
      };
    })
    .addCase(requestOperations.createPerson.rejected, (state, action) => {
      state.participant = {
        ...state.participant,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
    })
    .addCase(requestOperations.createProposal.fulfilled, (state, action) => {
      const { proposalId, proposalSequencial, url } = action.payload;
      state.proposal = {
        sequencial: proposalSequencial,
        url,
        id: proposalId,
      };
    })
    .addCase(requestOperations.createProposal.rejected, (state, action) => {
      state.proposal = {
        ...INITIAL_STATE.proposal,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
    })
    .addCase(requestOperations.acceptTerms.fulfilled, (state, action) => {
      const { success = false } = action.payload;
      state.termService.acceptedTerm = success;
    })
    .addCase(requestOperations.acceptTerms.rejected, (state, action) => {
      state.termService = {
        ...INITIAL_STATE.termService,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
    })
    .addCase(requestOperations.getPaymentsUrl.fulfilled, (state, action) => {
      const { paymentType, paymentUrl } = action.payload;
      state.payments = {
        ...state.payments,
        type: paymentType,
        url: paymentUrl,
      };
    })
    .addCase(requestOperations.getPaymentsUrl.rejected, (state, action) => {
      state.payments = {
        ...state.payments,
        url: INITIAL_STATE.payments.url,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
    })
    .addCase(requestOperations.getProposalDocument.fulfilled, (state, action) => {
      const { proposalDocumentUrl, insuredName } = action.payload;
      state.proposal = {
        ...state.proposal,
        url: proposalDocumentUrl,
        insuredName,
      };
    })
    .addCase(requestOperations.getProposalDocument.rejected, (state, action) => {
      state.proposal = {
        ...state.proposal,
        insuredName: INITIAL_STATE.proposal.insuredName,
        url: INITIAL_STATE.proposal.url,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
    })
    .addCase(requestOperations.getAddressByZipCode.fulfilled, (state, action) => {
      const { cep, logradouro, bairro, cidade, uf } = action.payload;
      state.riskData.address = {
        ...state.riskData.address,
        zipCode: cep,
        street: logradouro,
        district: bairro,
        city: cidade,
        uf,
      };
      state.riskData.addressDisabled = logradouro !== '' && bairro !== '';
    })
    .addCase(requestOperations.getAddressByZipCode.rejected, (state, action) => {
      state.riskData.address = {
        ...state.riskData.address,
        error: {
          ...action.payload?.errors,
          status: action.payload?.status,
        },
      };
      state.riskData.addressDisabled = false;
    })
    .addCase(requestOperations.getPersonById.fulfilled, (state, action) => {
      state.partner.id = action.payload.id;
      state.partner.isValid = true;
    })
    .addCase(requestOperations.getPersonById.rejected, (state) => {
      state.partner.id = '';
      state.partner.isValid = false;
    })
    .addCase(requestOperations.getInsuredAmountRestrictionByZipCode.fulfilled, (state, action) => {
      state.restrictions = action.payload;
    })
    .addCase(requestOperations.getInsuredAmountRestrictionByZipCode.rejected, (state, action) => {
      state.restrictions = {
        ...INITIAL_STATE.restrictions,
        error: {
          ...action.payload?.errors,
          statis: action.payload?.status,
        },
      };
    });
});

export default requestReducer;
