import {
  MESSAGE_EDITQUOTE_ERROR,
  MESSAGE_LOCALITYEXPOSURE_ERROR,
  MESSAGE_RESTRICTEDLOCALITY_ERROR,
  MESSAGE_PEOPLE_RISK_ASSESSEMENT_ERROR,
} from 'constants/messages';
import {
  CHECK_EXPOSURE_ERROR_CODE,
  RESCTRICTED_ZIP_CODE_ERROR_CODE,
  PEOPLE_RISK_ASSESSEMENT_ERROR_CODE,
  RESCTRICTED_CITY_CODE_ERROR_CODE,
  RESCTRICTED_STATE_ERROR_CODE,
} from 'constants/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import { requestOperations } from '../redux';
import { getQuote } from '../redux/requestSelectors';

export default () => {
  const dispatch = useDispatch();

  const debouncedUpdateQuote = useMemo(
    () =>
      debounce(async (id, quote) => dispatch(requestOperations.updateQuote({ id, quote })), 1000, { leading: true }),
    []
  );

  const getQuoteState = useSelector(getQuote);

  const createQuote = async (quote) => dispatch(requestOperations.createQuote(quote));

  const updateQuote = async (id, quote) => debouncedUpdateQuote(id, quote);

  const getQuotePDF = async (quoteId) => dispatch(requestOperations.getQuotePDF(quoteId));

  const errorResponseMessage = (code) => {
    switch (code)
    {
      case RESCTRICTED_ZIP_CODE_ERROR_CODE:
      case RESCTRICTED_CITY_CODE_ERROR_CODE:
      case RESCTRICTED_STATE_ERROR_CODE:
        return MESSAGE_RESTRICTEDLOCALITY_ERROR;
      case CHECK_EXPOSURE_ERROR_CODE:
        return MESSAGE_LOCALITYEXPOSURE_ERROR;
      case PEOPLE_RISK_ASSESSEMENT_ERROR_CODE:
        return MESSAGE_PEOPLE_RISK_ASSESSEMENT_ERROR;
      default:
        return MESSAGE_EDITQUOTE_ERROR;
    }
  };

  return {
    getQuoteState,
    createQuote,
    updateQuote,
    getQuotePDF,
    errorResponseMessage,
  };
};
