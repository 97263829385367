import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isCep } from 'validator-brazil';
import { Box, Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import useRisk from 'hooks/useRisk';
import useLocality from 'hooks/useLocality';
import useValidator from 'hooks/useValidator';
import useUtils from 'hooks/useUtils';
import useRestrictions from 'hooks/useRestrictions';
import { useAlert } from 'components/Alert/Alert';
import CEPInput from 'components/CEPInput/CEPInput';
import { MESSAGE_CEP_ERROR } from 'constants/messages';
import { HEIGHT_STEP_CONTAINER, CEP_SIZE_IN_MASK, FETCH_STATUS, MAX_SIZE_ADDRESS } from 'constants/defaults';
import { LOCALIZATION } from 'constants/textsSteps';
import ButtonsControl from '../shared/ButtonsControl';
import TitleDivider from '../shared/TitleDivider';

const DEFAULT_LOCALIZATION_STEP = {
  zipCode: '',
  street: '',
  number: '',
  complement: '',
  district: '',
  uf: '',
  city: '',
};

const LocalizationStep = ({ onBack, onNext }) => {
  const { getRiskState, setAddress } = useRisk();
  const { getAddressByZipCode } = useLocality();
  const { getInsuredAmountRestriction } = useRestrictions();
  const { LocatizationStepValidationSchema } = useValidator();
  const { addMsgDanger } = useAlert();

  const zipCodeInput = useRef();
  const streetInput = useRef();

  const { getOnlyNumber } = useUtils();

  const formik = useFormik({
    initialValues: { ...DEFAULT_LOCALIZATION_STEP },
    validationSchema: LocatizationStepValidationSchema,
    validateOnBlur: true,
    onSubmit: onNext,
  });

  const { setValues } = formik;

  useEffect(() => {
    const { zipCode, street, number, complement, district, city, uf } = getRiskState?.address || {};

    setValues({
      zipCode: zipCode || '',
      street: street || '',
      number: number || '',
      complement: complement || '',
      district: district || '',
      city: city || '',
      uf: uf || '',
    });
  }, [getRiskState?.address, setValues]);

  const handleFindAddressByZipCode = async (event) => {
    const { value: current } = event.target;

    formik.setFieldValue('zipCode', current);
    if (current.length === CEP_SIZE_IN_MASK && isCep(current)) {
      const zipCodeNumbersOnly = getOnlyNumber(current);

      const getAddressByZipCodeResponse = await getAddressByZipCode(zipCodeNumbersOnly);

      if (getAddressByZipCodeResponse.meta.requestStatus === FETCH_STATUS.rejected) {
        addMsgDanger(MESSAGE_CEP_ERROR);
        formik.setFieldValue('zipCode', '');
        setTimeout(() => zipCodeInput?.current?.focus());
      } else {
        const values = {
          zipCode: getOnlyNumber(getRiskState?.address.zipCode) || '',
          street: getRiskState?.address.street || '',
          number: formik.getFieldProps('number').value || '',
          complement: formik.getFieldProps('complement').value || '',
          district: getRiskState?.address.district || '',
          uf: getRiskState?.address.uf || '',
          city: getRiskState?.address.city || '',
        };
        
        formik.setValues(values);
        streetInput?.current?.focus();
        await getInsuredAmountRestriction(zipCodeNumbersOnly);
      }
    }
  };

  const setAddressState = (prop) => {
    setAddress({ [prop]: formik.values[prop] });
  };

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <Box mt={2} minHeight={HEIGHT_STEP_CONTAINER}>
        <TitleDivider text={LOCALIZATION.title} minWidth={80} />

        <Box mb={4}>
          <Grid container spacing={4} data-testid="grid">
            <Grid id="residencial-site-cep-campo" item sm={3} xs={12} className="space-field-adress">
              <CEPInput
                id="zipCode"
                color="secondary"
                label="Informe seu CEP"
                title="Informe seu CEP"
                {...formik.getFieldProps('zipCode')}
                error={formik.touched.zipCode && !!formik.errors.zipCode}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                fullWidth
                value={formik.values.zipCode}
                onChange={handleFindAddressByZipCode}
                onFocus={(event) => event.target.setAttribute('autocomplete', 'none')}
                InputProps={{
                  inputRef: zipCodeInput,
                  'data-testid': 'input-cep-localizationStep',
                }}
              />
            </Grid>

            <>
              <Grid item lg={6} sm={9} xs={12} className="space-field-adress">
                <TextField
                  id="street"
                  color="secondary"
                  label="Rua*:"
                  title="Rua"
                  {...formik.getFieldProps('street')}
                  error={formik.touched.street && !!formik.errors.street}
                  helperText={formik.touched.street && formik.errors.street}
                  value={formik.values.street}
                  fullWidth
                  InputProps={{
                    inputRef: streetInput,
                    disabled: getRiskState?.addressDisabled,
                    onBlur: () => setAddressState('street'),
                    maxLength: MAX_SIZE_ADDRESS,
                    'data-testid': 'input-street-localizationStep',
                  }}
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12} className="space-field-adress">
                <TextField
                  id="district"
                  color="secondary"
                  label="Bairro*:"
                  title="Bairro"
                  {...formik.getFieldProps('district')}
                  error={formik.touched.district && !!formik.errors.district}
                  helperText={formik.touched.district && formik.errors.district}
                  fullWidth
                  inputProps={{
                    disabled: getRiskState?.addressDisabled,
                    onBlur: () => setAddressState('district'),
                    maxLength: MAX_SIZE_ADDRESS,
                    'data-testid': 'input-district-localizationStep',
                  }}
                />
              </Grid>

              <Grid item lg={2} sm={6} xs={12} className="space-field-adress">
                <TextField
                  id="number"
                  color="secondary"
                  label="Número*:"
                  title="Número"
                  {...formik.getFieldProps('number')}
                  error={formik.touched.number && !!formik.errors.number}
                  helperText={formik.touched.number && formik.errors.number}
                  fullWidth
                  inputProps={{
                    onBlur: () => setAddressState('number'),
                    maxLength: 10,
                    'data-testid': 'input-number-localizationStep',
                  }}
                />
              </Grid>

              <Grid item lg={4} sm={6} xs={12} className="space-field-adress">
                <TextField
                  id="complement"
                  color="secondary"
                  label={`Complemento${getRiskState?.propertyType === 'apartment' ? '*' : ''}`}
                  title="Complemento"
                  {...formik.getFieldProps('complement')}
                  error={formik.touched.complement && !!formik.errors.complement}
                  helperText={formik.touched.complement && formik.errors.complement}
                  fullWidth
                  inputProps={{
                    onBlur: () => setAddressState('complement'),
                    maxLength: MAX_SIZE_ADDRESS,
                    'data-testid': 'input-complement-localizationStep',
                  }}
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12} className="space-field-adress">
                <TextField
                  id="city"
                  color="secondary"
                  label="Cidade*:"
                  title="Cidade"
                  {...formik.getFieldProps('city')}
                  error={formik.touched.city && !!formik.errors.city}
                  helperText={formik.touched.city && formik.errors.city}
                  fullWidth
                  inputProps={{
                    disabled: true,
                    'data-testid': 'input-city-localizationStep',
                  }}
                />
              </Grid>

              <Grid item lg={3} sm={6} xs={12} className="space-field-adress">
                <TextField
                  id="uf"
                  color="secondary"
                  label="Estado*:"
                  title="Estado"
                  {...formik.getFieldProps('uf')}
                  error={formik.touched.uf && !!formik.errors.uf}
                  helperText={formik.touched.uf && formik.errors.uf}
                  fullWidth
                  inputProps={{
                    disabled: true,
                    'data-testid': 'input-uf-localizationStep',
                  }}
                />
              </Grid>

              <Grid item sm={12} xs={12} className="flex-end space-field-adress">
                <Box>
                  <Typography
                    color="primary"
                    variant="caption"
                    component="a"
                    target="_blank"
                    href={window.ENV.URL_CEP}
                    style={{ textDecoration: 'underline', marginTop: 5, cursor: 'pointer' }}
                  >
                    Não sei meu CEP
                  </Typography>
                </Box>
              </Grid>
            </>
          </Grid>
        </Box>
      </Box>

      <ButtonsControl
        dataTestid="button-forward-localizationStep"
        onBack={onBack}
        onNext={formik.submitForm}
        disabled={!isEmpty(formik.errors)}
        gridBack={isXs ? 4 : 3}
        gridForward={isXs ? 4 : 3}
      />
    </>
  );
};

LocalizationStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default LocalizationStep;
