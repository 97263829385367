import React, { useMemo } from 'react';
import { Box, Button, Paper, Typography, useMediaQuery  } from '@material-ui/core';
import useUtils from 'hooks/useUtils';
import useQuote from 'hooks/useQuote';
import useParticipant from 'hooks/useParticipant';
import useCoverages from 'hooks/useCoverages';
import useRisk from 'hooks/useRisk';
import { DEFAULT_BASIC_COVERAGE_VALUE, FETCH_STATUS, HOURS } from 'constants/defaults';
import { iconDownload } from 'constants/images';
import { MESSAGE_QUOTATION_ERROR, MESSAGE_DOWNLOAD_SUCCESS } from 'constants/messages';
import { useAlert } from 'components/Alert/Alert';
import TitleDivider from './TitleDivider';

const ValuesInfo = () => {
  const { getRiskState } = useRisk();
  const { getParticipantState } = useParticipant();
  const { getQuoteState, getQuotePDF } = useQuote();
  const { getSelectedCoverages } = useCoverages();
  const { addMsgDanger, addMsgSuccess } = useAlert();
  const {
    formatCurrency,
    formatDate,
    formatCPF,
    date,
    getNameProperty,
    getNameConstruction,
    getNameType,
    downloadPDF,
  } = useUtils();

  const { installments = [], grossPremium, iof, id } = getQuoteState || {};

  const isQuoteCreated = !!id;

  const reducer = (accumulator, current) => accumulator + current;

  const values = getSelectedCoverages.map((item) => item.coverage?.insuredAmount?.value);

  const amount = useMemo(() => (getSelectedCoverages.length > 0 ? values.reduce(reducer, 0) : DEFAULT_BASIC_COVERAGE_VALUE), [getSelectedCoverages.length, values]);

  const lastInstallment = useMemo(() => installments[installments.length - 1] || {}, [installments]);

  const quantityCoverages = useMemo(() => (getSelectedCoverages.length || 0), [getSelectedCoverages]);

  const handleDownloadQuote = async () => {

    const getQuotePDFResponse = await getQuotePDF(getQuoteState?.id);

    if(getQuotePDFResponse.meta.requestStatus === FETCH_STATUS.fulfilled)
    {
      const base64 = getQuotePDFResponse.payload;
      downloadPDF(base64, 'SuaCotacao.pdf');
      addMsgSuccess(MESSAGE_DOWNLOAD_SUCCESS);
    }
    else
      addMsgDanger(MESSAGE_QUOTATION_ERROR);
  };

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
    {((isMdDown && isQuoteCreated) || (!isMdDown))   && (
    <Paper className="values">
      <Box mb={isMdDown ? 0.5 : 3} mx={ isXs ? 0 : 10} className="values__title">
        <Typography variant="subtitle1" color="primary">
          <b>Seu Pottencial Residencial</b>
        </Typography>
      </Box>

      <Box px={10} className="values__value-info" >
        <Box display="flex" flexDirection="column" mb={3}>
          <Box display="flex" mb={0.5}>
            <Box mr={1}>
              <Typography variant="subtitle2">Nome:</Typography>
            </Box>

            <Typography variant="body2">{getParticipantState.name}</Typography>
          </Box>

          <Box display="flex" mb={0.5}>
            <Box mr={1}>
              <Typography variant="subtitle2">CPF:</Typography>
            </Box>

            <Typography variant="body2">{formatCPF(getParticipantState.document)}</Typography>
          </Box>

          <Box display="flex" alignSelf="flex-start" mb={0.5}>
            <Box mr={1} width={52}>
              <Typography variant="subtitle2">E-mail:</Typography>
            </Box>

            <Typography variant="body2">{getParticipantState.email}</Typography>
          </Box>

          <Box display="flex" mb={0.5}>
            <Box mr={1}>
              <Typography variant="subtitle2">Telefone:</Typography>
            </Box>

            <Typography variant="body2">{getParticipantState.phone}</Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" mb={3}>
          <Box mb={0.5}>
            <Typography variant="subtitle2">
              <b>Vigência</b>
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2">
              {`${formatDate(date().set(HOURS))} à ${formatDate(date().add(1, 'y').set(HOURS))}`}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" mb={3}>
          <Box mb={0.5}>
            <Typography variant="subtitle2">
              <b>Imóvel</b>
            </Typography>
          </Box>

          <Box mb={1} data-testid="text-types-introductoryStep">
            <Typography variant="body2">
              {!getRiskState?.propertyType && <>Aguardando informações do imóvel.</>}

              {getRiskState?.propertyType && (
                <>
                  {[
                    getRiskState?.propertyType && getNameProperty(getRiskState),
                    getRiskState?.propertyUseType && getNameType(getRiskState),
                    getRiskState?.constructionType && getNameConstruction(getRiskState),
                  ]
                    .filter(Boolean)
                    .join(', ')}
                  .
                </>
              )}
            </Typography>
          </Box>

          {getRiskState?.address?.zipCode && (
            <Typography variant="body2">
              {[
                getRiskState?.address?.street !== '' && getRiskState?.address?.street,
                getRiskState?.address?.number !== '' && getRiskState?.address?.number,
                getRiskState?.address?.district !== '' && getRiskState?.address?.district,
                getRiskState?.address?.uf !== '' && `${getRiskState?.address?.city}/${getRiskState?.address?.uf}`,
              ]
                .filter(Boolean)
                .join(', ')}
            </Typography>
          )}
        </Box>
      </Box>
 
      <Box mx={10} className={isMdDown ? "values__withFooter" : ""}>
        <TitleDivider text="COBERTURAS E ASSISTÊNCIAS" minWidth={210} />
        {isQuoteCreated && (
        <>
          <Box display="flex" justifyContent="space-between" mb={getSelectedCoverages.length === 1 ? 1 : 0}>

            {quantityCoverages <= 1 && (
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2">
                  <b>Cobertura básica</b>
                </Typography>

                <Typography variant="body2" data-testid="basicCoverage-valuesInfo-min">
                  {formatCurrency(amount || '')}
                </Typography>
              </Box>
            )}
          </Box>

          {quantityCoverages > 1 && (
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Box display="flex" flexDirection="column">
                <Typography variant="subtitle2">
                  <b>Total de coberturas</b>
                </Typography>

                <Typography variant="body2">{formatCurrency(amount || '')}</Typography>
              </Box>

              <Box>
                <Typography variant="subtitle1" color="primary">
                  <b>{quantityCoverages}</b>
                </Typography>
              </Box>
            </Box>
          )}
        </>
        )}
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Box display="flex" flexDirection="column">
            <Typography variant="subtitle2">
              <b>Assistência 24h</b>
            </Typography>

            <Typography variant="body2">17 acionamentos por vigência</Typography>
          </Box>

          <Box>
            <Typography variant="subtitle1" color="primary">
              <b>Grátis</b>
            </Typography>
          </Box>
        </Box>
      </Box>

      {isQuoteCreated && (<Box px={isXs ? 5 : 10} pb={isMdDown ? 2.5 : 5} className="values__value-total">
        <TitleDivider text="TOTAL" minWidth={50}/>

        <Box display="flex" alignItems={isXs ? "end" : "baseline"} justifyContent="end" flexDirection={isXs ?'column' : 'row'}  mb={isXs ? 0.6 : 3}>
          <Typography variant="caption">em até {lastInstallment?.installmentNumber}x</Typography>

          <Box mx={1} data-testid="text-installment-valuesInfo" display={isXs ? "flex" : "block"}>
            <Typography variant="h5" color="primary">
              <b>{formatCurrency(lastInstallment?.amount || '')}</b>
            </Typography>

            {isXs && (<Typography variant="caption" className="values__labelAuto">/mês</Typography>)}
          </Box>

          {!isXs && (<Typography variant="caption">/mês</Typography>)}

        </Box>

        <Box display="flex" justifyContent="end">
          <Typography variant="caption">
            <i>IOF: {formatCurrency(iof || '')}</i>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="end">
          <Typography variant="caption" data-testid="basicCoverage-valuesInfo-gross" >
            <i>
              <b>Valor total: {formatCurrency(grossPremium || '')}</b>
            </i>
          </Typography>
        </Box>
      </Box>)}

      {isQuoteCreated && (
        <Box display="flex" justifyContent="center" alignItems="center" className="values__download">
          <Button variant="outlined" color="primary" onClick={handleDownloadQuote}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box mr={1}>
                <img src={iconDownload} alt="Download da Cotação" />
              </Box>

              <Box>
                <b>Baixar Cotação</b>
              </Box>
            </Box>
          </Button>
        </Box>
      )}
    </Paper>
    )}
    </>
  );
};

export default ValuesInfo;
