import React from 'react';
import { DONT_USE_SOCIALNAME_MODAL } from 'constants/socialNameMessages';
import { EventDrivenModalProps, modalInfoProps } from './types';
import SocialNameModalInfo from './SocialNameModalInfo';

const SocialNameExclusionModal: React.FC<EventDrivenModalProps> = ({ open, callReturnEvent }) => {
  const modalProps: modalInfoProps = {
    open,
    title: DONT_USE_SOCIALNAME_MODAL.TITLE,
    confirmedButton: DONT_USE_SOCIALNAME_MODAL.CONFIRMED_BUTTON,
    unconfimedButton: DONT_USE_SOCIALNAME_MODAL.UNCONFIRMED_BUTTON,
    callReturnEvent,
  };

  return (
    <>
      <SocialNameModalInfo {...modalProps} />
    </>
  );
};

export default SocialNameExclusionModal;
