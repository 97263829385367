import { useDispatch, useSelector } from "react-redux";
import { getRisk } from "redux/requestSelectors";
import { requestActions } from "../redux";

export default () => {
  const dispatch = useDispatch();

  const getRiskState = useSelector(getRisk);

  const setRisk = (risk) => dispatch(requestActions.setRisk(risk));

  const setAddressDisabled = (enabled) => dispatch(requestActions.setAddressDisabled(enabled));

  const setAddress = (address) => dispatch(requestActions.setAddress(address));

  return {
    getRiskState,
    setRisk,
    setAddressDisabled,
    setAddress
  };

};
