/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Radio,
  FormLabel,
  TextField,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
  useMediaQuery,
  Icon,
  Tooltip,
  Theme,
  IconButton,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import { FormikHelpers, useFormik } from 'formik';

import { PersonResponse } from 'borders/dtos/response';
import { useAlert } from 'components/Alert/Alert';
import CPFInput from 'components/CPFInput/CPFInput';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import TermButton from 'components/TermButton/TermButton';
import SocialNameDisclaimer from 'components/SocialName/SocialNameDisclaimer';

import questionMarkIcon from 'assets/img/contract/icon-questionmark.svg';

import useTerm from 'hooks/useTerm';
import useReset from 'hooks/useReset';
import useParticipant from 'hooks/useParticipant';
import useProposal from 'hooks/useProposal';
import useTrustService from 'hooks/useTrustService';
import useValidator from 'hooks/useValidator';
import useSocialNameModalConfirmation from 'hooks/useSocialNameModalConfirmation';
import useSocialNameValidations from 'hooks/useSocialNameValidations';

import {
  MESSAGE_PERSON_ERROR,
  MESSAGE_TERMS_ERROR,
  MESSAGE_GET_PERSON_ERROR,
  MESSAGE_GET_PERSON_ERROR_RESTRICT,
} from 'constants/messages';
import {
  HEIGHT_STEP_CONTAINER,
  CPF_SIZE,
  RESTRICTDOCUMENTERRORCODE,
  FETCH_STATUS,
  SOCIAL_NAME_PERSON_ERROR,
} from 'constants/defaults';
import { ABOUT_YOU } from 'constants/textsSteps';
import ButtonsControl from '../shared/ButtonsControl';
import TermService from './TermService';
import TitleDivider from '../shared/TitleDivider';
import CommunicationsCheck from './CommunicationsCheck';
import { AboutYouFormSchema, INITIAL_FORM_DATA } from './formSchema';

type AboutYouStepProps = {
  onBack: () => void;
  onNext: () => void;
};

const AboutYouStep: React.FC<AboutYouStepProps> = ({ onBack, onNext }) => {
  const [isEditingSocialName, setIsEditingSocialName] = useState(false);

  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('xs'));

  const { setReset } = useReset();
  const { addMsgDanger } = useAlert();
  const { getProposalState } = useProposal();
  const { AboutYouStepValidationSchema } = useValidator();
  const { executeAfterModal } = useSocialNameModalConfirmation();
  const { setTermServiceOpen, acceptTermService, getTermState } = useTerm();
  const { trustServiceAgreement, getTrustServiceState } = useTrustService();
  const { createPerson, getPersonByDocument, getParticipantState, setParticipant } = useParticipant();
  const { validate } = useSocialNameValidations();

  const hasExistingSocialName = useMemo(
    () => !!getParticipantState.personSocialName,
    [getParticipantState.personSocialName]
  );

  const submit = async (formData: AboutYouFormSchema, { setFieldValue }: FormikHelpers<AboutYouFormSchema>) => {
    const { personName } = getParticipantState;
    const { document, email, name, useSocialName, phone } = formData;

    const payloadAccept = {
      email,
      name: useSocialName ? name : personName,
      phone,
    };

    const acceptTermResponse = await acceptTermService(payloadAccept);

    if (acceptTermResponse.meta.requestStatus === FETCH_STATUS.fulfilled) {
      const { success } = acceptTermResponse.payload as { success: boolean };

      if (success) {
        const payloadPerson = {
          document,
          email,
          phone,
          socialName: useSocialName ? name : undefined,
        };

        const createPersonResponse = await createPerson(payloadPerson);

        if (createPersonResponse.meta.requestStatus === FETCH_STATUS.fulfilled) onNext();
        else if ((createPersonResponse.payload as any).errors.code === SOCIAL_NAME_PERSON_ERROR) {
          executeAfterModal('SocialNameOffensiveWordsModal', {
            onRefuse: () => {},
            onAccept: () => {},
          });
          setFieldValue('name', '');
        } else addMsgDanger(MESSAGE_PERSON_ERROR);
      }
    } else addMsgDanger(MESSAGE_TERMS_ERROR);
  };

  const { setValues, ...formik } = useFormik({
    initialValues: INITIAL_FORM_DATA,
    validationSchema: AboutYouStepValidationSchema,
    validateOnBlur: true,
    onSubmit: submit,
  });

  const socialNameErrorMessage = useMemo(
    () => formik.values.useSocialName && validate(formik.values.name).message,
    [formik.values.name]
  );

  const editSocialName = () => {
    executeAfterModal('SocialNameEditionModal', {
      onRefuse: () => {},
      onAccept: () => {
        setIsEditingSocialName(true);
      },
    });
  };

  const handleBlur = (formProperty: keyof typeof formik.values) => {
    setParticipant({ [formProperty]: formik.values[formProperty] });
  };

  const handleDocument = useCallback(
    async (document: string) => {
      const getPersonByDocumentResponse = await getPersonByDocument(document);

      if (getPersonByDocumentResponse.meta.requestStatus === FETCH_STATUS.rejected) {
        const restrictedDocument =
          (getPersonByDocumentResponse.payload as any).errors.code === RESTRICTDOCUMENTERRORCODE;

        addMsgDanger(restrictedDocument ? MESSAGE_GET_PERSON_ERROR_RESTRICT : MESSAGE_GET_PERSON_ERROR);
      } else {
        const result = getPersonByDocumentResponse.payload as PersonResponse;

        formik.setFieldValue('document', document);
        formik.setFieldValue('name', result.socialName ?? result.name);
        formik.setFieldValue('useSocialName', !!result.socialName);

        setIsEditingSocialName(false);
      }
    },
    [addMsgDanger, getPersonByDocument, setParticipant]
  );

  const deactivateSocialName = () => {
    setValues({
      ...formik.values,
      name: getParticipantState.personName,
      useSocialName: false,
    });

    setIsEditingSocialName(false);

    setParticipant({
      name: getParticipantState.personName,
      useSocialName: false,
      socialName: undefined,
    });
  };

  const activateSocialName = () => {
    setValues({
      ...formik.values,
      name: getParticipantState.personSocialName ?? '',
      useSocialName: true,
    });

    setParticipant({
      name: getParticipantState.personSocialName ?? '',
      socialName: getParticipantState.personSocialName ?? '',
      useSocialName: true,
    });

    setIsEditingSocialName(!getParticipantState.personSocialName);
  };

  const handleChangeRadio = (shouldUseSocialName: boolean) => {
    if (shouldUseSocialName) {
      executeAfterModal('SocialNameActivateModal', {
        onRefuse: () => {},
        onAccept: () => {
          activateSocialName();
        },
      });
    } else if (!shouldUseSocialName && hasExistingSocialName) {
      executeAfterModal('SocialNameExclusionModal', {
        onRefuse: () => {
          deactivateSocialName();
        },
        onAccept: () => {},
      });
    } else if (!shouldUseSocialName) {
      deactivateSocialName();
    }

    setParticipant({
      useSocialName: shouldUseSocialName,
    });
  };

  const onAcceptServiceAgreement = () => {
    formik.setFieldValue('trustedServiceAgreement', true);
    trustServiceAgreement(true);
    setTermServiceOpen(false);
  };

  useEffect(() => {
    if (formik.values.document?.length === CPF_SIZE) {
      handleDocument(formik.values.document);
    } else {
      formik.setFieldValue('name', '');
      formik.setFieldValue('useSocialName', false);
    }
  }, [formik.values.document, handleDocument]);

  useEffect(() => {
    if (getProposalState?.sequencial || getProposalState?.url || getProposalState?.id) setReset();
  }, [getProposalState?.id, getProposalState?.sequencial, getProposalState?.url, setReset]);

  useEffect(() => {
    setValues({
      ...getTrustServiceState,
      ...getTermState,
      ...getParticipantState,
    });
  }, []);

  return (
    <>
      <Box mt={2} minHeight={HEIGHT_STEP_CONTAINER}>
        <TitleDivider text={ABOUT_YOU.title} minWidth={100} />

        <Box display="flex" flexWrap="wrap">
          <Grid container spacing={3}>
            <Grid item xs={12} md={3} className="space-field-about-you">
              <Box mb={1}>
                <CPFInput
                  data-testid="input-email-introductoryStep"
                  id="document"
                  title="CPF"
                  label="CPF"
                  {...formik.getFieldProps('document')}
                  error={formik.touched.document && !!formik.errors.document}
                  helperText={formik.touched.document && formik.errors.document}
                  fullWidth
                  inputProps={{
                    onBlur: () => handleBlur('document'),
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6} className="space-field-about-you">
              <FormControl fullWidth>
                <FormLabel>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs="auto">
                      <Typography variant="caption" color="textPrimary">
                        Deseja utilizar nome social?
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Tooltip arrow title={<SocialNameDisclaimer />}>
                        <Icon>
                          <img src={questionMarkIcon} alt="question mark icon" />
                        </Icon>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </FormLabel>
                <RadioGroup
                  row
                  id="useSocialName"
                  data-testid="radio-socialname-introductoryStep"
                  value={formik.values.useSocialName ? 'yes' : 'no'}
                  onChange={(event) => {
                    const answers = {
                      yes: true,
                      no: false,
                    };
                    handleChangeRadio(answers[event.target.value as keyof typeof answers]);
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    label="Sim"
                    title="Sim"
                    control={
                      <Radio
                        id="residencial-site-nome-social-radio"
                        color="primary"
                        inputProps={{
                          // @ts-ignore TS reclama que a propriedade não existe, mas existe no contexto de testes
                          'data-testid': 'input-socialName-accept-introductoryStep',
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value="no"
                    label="Não"
                    title="Não"
                    control={
                      <Radio
                        id="residencial-site-nome-social-radio"
                        color="primary"
                        inputProps={{
                          // @ts-ignore TS reclama que a propriedade não existe, mas existe no contexto de testes
                          'data-testid': 'input-socialName-deny-introductoryStep',
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4} className="space-field-about-you">
              <TextField
                id="name"
                color="secondary"
                disabled={!isEditingSocialName}
                label="Nome"
                title="Nome"
                fullWidth
                {...formik.getFieldProps('name')}
                // @ts-ignore TS reclama que a propriedade não existe, mas existe no contexto de testes
                error={socialNameErrorMessage}
                helperText={socialNameErrorMessage}
                inputProps={{
                  maxLength: 200,
                  'data-testid': 'input-name-introductoryStep',
                  onBlur: () => handleBlur('name'),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment:
                    formik.values.useSocialName && hasExistingSocialName && !isEditingSocialName ? (
                      <IconButton size="small" color="primary" onClick={editSocialName}>
                        <Edit />
                      </IconButton>
                    ) : undefined,
                }}
              />
            </Grid>

            <Grid item xs={12} md={4} className="space-field-about-you">
              <TextField
                id="email"
                color="secondary"
                label="E-mail"
                title="E-mail"
                {...formik.getFieldProps('email')}
                fullWidth
                error={formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                inputProps={{
                  maxLength: 150,
                  'data-testid': 'input-email-introductoryStep',
                  onBlur: () => handleBlur('email'),
                }}
              />
            </Grid>

            <Grid item xs={12} md={3} className="space-field-about-you">
              <PhoneInput
                id="phone"
                color="secondary"
                title="Telefone"
                label="Telefone"
                {...formik.getFieldProps('phone')}
                error={formik.touched.phone && !!formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
                fullWidth
                inputProps={{
                  'data-testid': 'input-phone-introductoryStep',
                  onBlur: () => handleBlur('phone'),
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="terms-container">
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box id="residencial-site-termos-servico-checkbox">
                <TermButton
                  text="termos de serviço"
                  name="trustedServiceAgreement"
                  value={formik.values.trustedServiceAgreement}
                  error={formik.touched.trustedServiceAgreement && formik.errors.trustedServiceAgreement}
                  onChange={(checked) => {
                    formik.setFieldValue('trustedServiceAgreement', checked);
                    trustServiceAgreement(checked);
                  }}
                  onShowTerm={() => setTermServiceOpen(true)}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Box>
                <CommunicationsCheck parentFormik={formik} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ButtonsControl
        dataTestid="button-forward-introductoryStep"
        onBack={onBack}
        onNext={formik.submitForm}
        disabled={!isEmpty(formik.errors) || !isEmpty(socialNameErrorMessage)}
        isFirstStep
        gridBack={isXs ? 4 : 3}
        gridForward={isXs ? 4 : 3}
      />

      {getTermState.openTerm && (
        <TermService open={getTermState.openTerm} setOpen={setTermServiceOpen} onAccept={onAcceptServiceAgreement} />
      )}
    </>
  );
};

export default AboutYouStep;

AboutYouStep.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
