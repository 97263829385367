import React from 'react';
import { Typography } from '@material-ui/core';
import useProposal from 'hooks/useProposal';
import usePayment from 'hooks/usePayment';

const Boleto = () => {
  const { getProposalState } = useProposal();
  const { getPaymentState } = usePayment();

  const handleOpenProposal = () => window.open(getProposalState?.url, '_blank');
  const handleDownloadBoleto = () => window.open(getPaymentState?.url, '_blank');

  return (
    <>
      <Typography variant="body1">
        Clique aqui para{' '}
        <Typography className="steps__finish-main__link" variant="body1" component="span" onClick={handleOpenProposal}>
          baixar sua proposta
        </Typography>{' '}
        e{' '}
        <Typography
          className="steps__finish-main__link"
          variant="body1"
          component="span"
          onClick={handleDownloadBoleto}
        >
          boleto.
        </Typography>{' '}
        Vamos enviar a sua apólice por e-mail assim que recebermos a confirmação do pagamento.
      </Typography>
      <br />
    </>
  );
};

export default Boleto;
