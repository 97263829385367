import React, { useState } from 'react';

import useUtils from 'hooks/useUtils';
import { useInView } from 'react-hook-inview';
import { coveragesAdditional, coveragesBasic } from 'constants/collections';
import CoveragesButton from './CoveragesButton';
import PrivacyModal from './PrivacyModal';
import List from './List';
import { SECTION_COVERAGES } from '../../../../../constants/texts';

const SectionProtectedHome = () => {
  const { setMarkMenu } = useUtils();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showRoofBasic, setShowRoofBasic] = useState(false);
  const [cardActive, setCardActive] = useState({});
  const [refCoberturas] = useInView({
    threshold: 1,
    onEnter: () => {
      setMarkMenu('section-coberturas');
    },
  });

  const onCardClick = (card) => {
    setShowPrivacyModal(true);
    setCardActive(card);
  };

  return (
    <section className="section-coverages" id="section-coberturas">
      <div className="background">
        <div className="title-coverages" id="section-coberturas-title" ref={refCoberturas}>
          <h2 className="title-section-global">
            <div>{SECTION_COVERAGES.TITLE}</div>
            <hr />
          </h2>

          <span className="subtitle-coverages whiteSpace">{SECTION_COVERAGES.SUBTITLE}</span>
        </div>

        <div className="container-full">
          <div className="ContainerTexto">
            <CoveragesButton showRoofBasic={showRoofBasic} setShowRoofBasic={setShowRoofBasic} />
          </div>
          <div className="container-coverages">
            <div className="boxes-div">
              <List wynCardList={showRoofBasic ? coveragesAdditional : coveragesBasic} onItemClick={onCardClick} />
              <PrivacyModal
                show={showPrivacyModal}
                setShowPrivacyModal={setShowPrivacyModal}
                setCardActive={setCardActive}
                cardActive={cardActive}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionProtectedHome;
