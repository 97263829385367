import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getFinishing, getSlide, getStep } from "redux/requestSelectors";
import { requestActions } from "../redux";

export default () => {
  const dispatch = useDispatch();

  const getFinishingState = useSelector(getFinishing);

  const getActiveStep = useSelector(getStep);

  const getSlideState = useSelector(getSlide);

  const setFinishing = isFinishing => dispatch(requestActions.setFinishing({isFinishing}));

  const setActiveStep = (step) => dispatch(requestActions.setStep({step}));

  const setSlide = (slideDirection) => dispatch(requestActions.setSlide(slideDirection));

  const setReset = () => dispatch(requestActions.setReset());

  const setResetFinish = () => dispatch(requestActions.setResetFinish());

  const location = useLocation();

  const searchParams = location.search;

  return {
    getFinishingState,
    getActiveStep,
    getSlideState,
    setFinishing,
    setActiveStep,
    setSlide,
    setReset,
    setResetFinish,
    location,
    searchParams
  };
};
