import React from 'react';
import { ABOUT_YOU } from 'constants/textsSteps';
import StepText from '../shared/StepText';

const AboutYouStepText = () => {

  const isMobile = window.innerWidth <= 1280; 

  const texts = [isMobile ? ABOUT_YOU.textHeaderMobile : ABOUT_YOU.textHeader];

  return (
    <>
      <StepText textArray={texts} />
    </>
  );
};

export default AboutYouStepText;
