import { useDispatch, useSelector } from "react-redux";
import { getTrustService } from "redux/requestSelectors";
import { requestActions } from "../redux";

export default () => {
  const dispatch = useDispatch();

  const getTrustServiceState = useSelector(getTrustService);

  const trustServiceAgreement = (isTrusted) => dispatch(requestActions.trustServiceAgreement({ isTrusted }));

  const trustServiceCommunications = (isTrusted) => dispatch(requestActions.trustServiceCommunications({ isTrusted }));

  return {
    getTrustServiceState,
    trustServiceAgreement,
    trustServiceCommunications
  };
};
