import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PrivateRoute = (props) => {
  const { component: Component, functionality, ...rest } = props;

  return (
    <Route
      {...rest}
      render={() => (
        <>
          <Component />
        </>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  functionality: PropTypes.string || PropTypes.array,
  component: PropTypes.any,
  path: PropTypes.string.isRequired,
};

PrivateRoute.defaultProps = {
  component: undefined,
  functionality: undefined,
};

export default PrivateRoute;
