import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { Box, Fade, Modal, Paper, Backdrop } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import 'assets/style/elements/modal.scss';

interface ModalInfoProps {
  children: ReactNode;
  open: boolean;
  className: string;
  noPadding?: boolean;
  onClose?: () => void;
}

const ModalInfo: React.FC<ModalInfoProps> = ({ children, open, onClose, noPadding, className, ...props }) => (
  <Modal
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
    open={open}
    onClose={onClose}
    className={`${className} modal-info`}
    {...props}
  >
    <Fade in={open}>
      <Box className="modal-info__core">
        <Paper className={clsx({ 'modal-info__no-padding': noPadding }, 'modal-info__paper')}>
          {onClose && <Close className="modal-info__close" onClick={onClose} />}
          {children}
        </Paper>
      </Box>
    </Fade>
  </Modal>
);

ModalInfo.defaultProps = {
  onClose: undefined,
  noPadding: false,
};

export default ModalInfo;
