import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import requestReducer from 'redux/requestReducer';
import storageSession from 'redux-persist/lib/storage/session';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession
};

const configureAppPersistor = persistReducer(persistConfig, requestReducer);

const configureAppStore = (preloadedState) => configureStore({
  reducer: {
    request: configureAppPersistor
  },
  preloadedState
});

export default configureAppStore;