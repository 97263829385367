import { useEffect } from 'react';

const useSocialNameValidations = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.pottencial.com.br/scripts/socialNameValidation.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // @ts-ignore TS reclama que a propriedade não existe, mas existe no dom após a execução
  const validateStub = (value) => validateSocialName?.(value) ?? { valid: false, message: null };

  return { validate: validateStub };
};

export default useSocialNameValidations;
