import PropTypes from 'prop-types';
import { Box, Button, Checkbox, Typography, FormHelperText, FormControlLabel } from '@material-ui/core';

type TermButtonProps = {
  text: string;
  value: boolean;
  name: string;
  error?: string | false;
  onChange?: (checked: boolean) => void;
  onShowTerm?: () => void;
};

const TermButton: React.FC<TermButtonProps> = ({ text, value, name, error, onChange, onShowTerm }) => (
  <Box>
    <Box>
      <FormControlLabel
        style={{ marginRight: 0 }}
        control={<Checkbox color="primary" name={name} checked={value} onClick={() => onChange?.(!value)} />}
        label={
          <Box>
            <Typography variant="caption">Li e aceito os</Typography>
          </Box>
        }
      />
      <Button variant="text" color="primary" title={`Ver ${text}`} onClick={() => onShowTerm?.()}>
        <Typography variant="caption" style={{ textDecoration: 'underline' }}>
          {text}.
        </Typography>
      </Button>
    </Box>
    <Box>
      <FormHelperText hidden={!error} error={!!error}>
        {error}
      </FormHelperText>
    </Box>
  </Box>
);

TermButton.propTypes = {
  text: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onShowTerm: PropTypes.func,
};

TermButton.defaultProps = {
  error: undefined,
  onChange: () => {},
  onShowTerm: () => {},
};

export default TermButton;
