import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store';
import { getParticipant } from 'redux/requestSelectors';
import { CreateHefestoRequest, CreatePersonRequest } from 'borders/dtos/request';
import { requestActions, requestOperations } from '../redux';

export default () => {
  const dispatch = useAppDispatch();

  const getParticipantState = useSelector(getParticipant);

  const setParticipant = useCallback(
    (participant: any) => dispatch(requestActions.setParticipant(participant)),
    [dispatch]
  );

  const createPerson = useCallback(
    async (personPayload: CreatePersonRequest) => dispatch(requestOperations.createPerson(personPayload)),
    [dispatch]
  );

  const createPersonHefesto = useCallback(
    async (personHefestoPayload: CreateHefestoRequest) =>
      dispatch(requestOperations.createPersonHefesto(personHefestoPayload)),
    [dispatch]
  );

  const getPersonByDocument = useCallback(
    async (documentNumber: string) => dispatch(requestOperations.searchPersonByDocument(documentNumber)),
    [dispatch]
  );

  return {
    getParticipantState,
    setParticipant,
    createPerson,
    createPersonHefesto,
    getPersonByDocument,
  };
};
