import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import { Box, Paper, Divider, IconButton, Typography } from '@material-ui/core';
import { Error, DeleteForever } from '@material-ui/icons';
import { truncate } from 'lodash';
import useUtils from 'hooks/useUtils';
import useHelpers from 'hooks/useHelpers';

import CurrencyInput from 'components/CurrencyInputCustom/CurrencyInputCustom';

const CardCoverage = ({
  index,
  object,
  isValid,
  valueMax,
  validation,
  handleChange,
  setIdToRemove,
  valueCoverage,
  isSelectedCoverage,
  setShowModalDetails,
  setOpenRemoveCoverage,
  setCurrentCoverageModal,
}) => {
  const { coverage } = object;
  const { formatCurrency } = useUtils();
  const { getIcon } = useHelpers();

  const franchise = 100 * coverage?.insuredAmount?.max?.percent;

  return (
    <Paper
      className={clsx({ 'eighth__selected-card': isSelectedCoverage }, 'eighth__paper-card')}
      id={`residencial-site-${coverage?.icon}-card`}
      data-testid={`card-${coverage?.icon}`}
    >
      <Box className="eighth__anchor-button eighth__add-button">
        <IconButton
          color="primary"
          title="Ver detalhes"
          onClick={() => {
            setCurrentCoverageModal(coverage);
            setShowModalDetails(true);
          }}
        >
          +
        </IconButton>
      </Box>

      <Box display="flex" m={3} mr={2} mb={1} height={60}>
        <Box display="flex" alignItems="center" justifyContent="center" width={90} mr={1}>
          <img className="eighth__img" src={getIcon(coverage?.icon)} alt="Logo" />
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box>
            <Typography variant="body2" color="primary">
              <b>Em caso de:</b>
            </Typography>
          </Box>

          <Box data-for="tipTitle" data-tip={coverage?.name}>
            <Typography variant="subtitle2">
              <b>{truncate(coverage?.name, { length: 25 })}</b>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" p={3} px={2} pt={0}>
        <Box mb={2}>
          <Typography variant="body2" className="eighth__description">
            {truncate(coverage?.description, { length: 56 })}
          </Typography>
        </Box>

        <Box width={1} mb={1}>
          <Divider />
        </Box>

        <Box mb={0.5} textAlign="center">
          <Typography variant="caption" color="textSecondary">
            <b>Valor a receber em caso de imprevisto:</b>
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" textAlign="center" width={1}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              style={{ padding: 3 }}
              onMouseEnter={() => {
                ReactTooltip.rebuild();
              }}
              data-for="tipValueMinMax"
              data-tip={`O valor mínimo é de ${formatCurrency(
                coverage?.insuredAmount?.min?.value || ''
              )} e o máximo é de ${formatCurrency(valueMax || '')}`}
            >
              <Error fontSize="small" htmlColor="#ffb500" />
            </Box>

            <Box mx={1} maxWidth={128}>
              <CurrencyInput
                id={`coverageValue${index}`}
                inputProps={{ 'data-testid': `input-coverageValue-${index}` }}
                variant="outlined"
                title="Valor da cobertura"
                value={valueCoverage}
                onChange={(_, value) => {
                  handleChange(coverage, value);
                }}
                max={valueMax}
              />
            </Box>

            {isSelectedCoverage ? (
              <Box>
                <IconButton
                  title="Remover cobertura"
                  onClick={() => {
                    setIdToRemove(coverage?.id);
                    setOpenRemoveCoverage(true);
                  }}
                  style={{ padding: 3 }}
                  data-testid={`button-remove-${index}`}
                >
                  <DeleteForever color="error" />
                </IconButton>
              </Box>
            ) : (
              <Box width={30} />
            )}
          </Box>

          {isValid && (
            <Box>
              <Typography variant="caption" color="error">
                {validation}
              </Typography>
            </Box>
          )}

          <Box textAlign="center" mt={isValid ? 0.5 : 3}>
            <Typography variant="caption" style={{ lineHeight: 1 }}>
              <i>
                As coberturas adicionais possuem uma franquia de {franchise}% para utilização, sendo o valor mínimo de{' '}
                {formatCurrency(coverage?.insuredAmount?.min?.value || '')}.
              </i>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

CardCoverage.propTypes = {
  index: PropTypes.number.isRequired,
  isSelectedCoverage: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  object: PropTypes.object.isRequired,
  setCurrentCoverageModal: PropTypes.func.isRequired,
  setShowModalDetails: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  valueCoverage: PropTypes.number.isRequired,
  validation: PropTypes.string.isRequired,
  valueMax: PropTypes.number.isRequired,
  setIdToRemove: PropTypes.func.isRequired,
  setOpenRemoveCoverage: PropTypes.func.isRequired,
};

export default CardCoverage;
