import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { truncate } from 'lodash';
import { SECTION_COVERAGES } from '../../../../../constants/texts';

const List = ({ wynCardList, onItemClick }) =>
  wynCardList.map((card, i) => (
    <Box className="box-item" key={`${card.title}-${i}`}>
      <button onClick={() => onItemClick(card)} type="button">
        <Box className="icon-more">
          <span>+</span>
        </Box>

        <a href={card.link}>
          <Box display="flex" alignItems="center" className="box-thumb">
            <Box mr={2}>
              <img
                src={card.imgUrl}
                alt={card.alt}
                style={{ width: card.customWidth ? `${card.customWidth}px` : '' }}
              />
            </Box>

            <Box>
              <Box className="box-name">{SECTION_COVERAGES.IN_CASE_OF}</Box>
              <Box className="box-name-sub">{card.title}</Box>
            </Box>
          </Box>

          <Box className="box-list">
            {card.itens.map((itemList, index) => (
              <span className="box-list-item abobrinha" key={index}>
                {truncate(itemList.item, { length: 80 })}
              </span>
            ))}
          </Box>

          <Box mt={2} textAlign="center">
            <span className="learn-more">{SECTION_COVERAGES.LEARN_MORE}</span>
          </Box>
        </a>
      </button>
    </Box>
  ));

List.propTypes = {
  wynCardList: PropTypes.array.isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default List;
