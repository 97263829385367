import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Box, Grid, Hidden, Button, Typography, useMediaQuery } from '@material-ui/core';
import imgPatti from 'assets/img/contract/patti-finish.png';
import useParticipant from 'hooks/useParticipant';
import useGlobal from 'hooks/useGlobal';
import useBroker from 'hooks/useBroker';

const Finish = ({ children, FINISH_TEXT }) => {
  const { getParticipantState } = useParticipant();
  const { setReset, setResetFinish } = useGlobal();
  const { getBrokerRedirectUrl } = useBroker();

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <>
      <Box height={1} display="flex" alignItems="center" justifyContent="center" className="steps__finish-main">
        <Box display="flex">
          <Hidden smDown>
            <Box display="flex" alignItems="center" mr={7}>
              <img src={imgPatti} alt="Patti" className="steps__img-patti" />
            </Box>
          </Hidden>

          <Box className="steps__flex-column-center steps__finish-text">
            <Box mb={3}>
              <Typography variant="h5" color="primary">
                <b>{FINISH_TEXT.title}</b>
              </Typography>
            </Box>

            <Box mb={6}>
              <Box mb={2}>
                <Typography variant="body1">{FINISH_TEXT.subtitle(getParticipantState?.name)}</Typography>
              </Box>

              <Box mb={2}>{children}</Box>

              {FINISH_TEXT.firstDescription && 
                <Box mb={2}>
                  <Typography variant="body1">{FINISH_TEXT.firstDescription}</Typography>
                </Box>
              }

              {FINISH_TEXT.secondDescription &&
                <Box mb={4}>
                <Typography variant="body1">{FINISH_TEXT.secondDescription}</Typography>
              </Box>
              }
              
              {FINISH_TEXT.thirdDescription &&
                <Box mb={2}>
                <Typography variant="body1">{FINISH_TEXT.thirdDescription}</Typography>
              </Box>
              }
              
            </Box>

            <Box>
              <Grid container justifyContent={ isXs ? 'space-evenly' : 'center' } alignItems='center'>
                <Grid item xs={3}>
                  <Button
                    color="primary"
                    title="Início"
                    variant="text"
                    fullWidth
                    component={Link}
                    to={getBrokerRedirectUrl()}
                    onClick={() => {
                      setReset();
                      setResetFinish();
                    }}
                  >
                    Início
                  </Button>
                </Grid>

                <Grid item xs={5}>
                  <Button
                    className="steps__final-button"
                    id="residencial-site-pottencial-para-voce-botao"
                    color="primary"
                    title="Pottencial Para Você"
                    variant="contained"
                    fullWidth
                    component="a"
                    target="_blank"
                    href={window.ENV.CUSTOMER_URL}
                    onClick={() => {
                      setReset();
                      setResetFinish();
                    }}
                    gridBack={isXs ? 4 : 3}
                    gridForward={isXs ? 4 : 3}
                  >
                    Pottencial Para Você
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      {!isXs && (
        <Box width={300} height={300} position="absolute" bottom={0} right={0} className="steps__finish-footer" />
      )}
    </>
  );
};

Finish.propTypes = {
  children: PropTypes.node.isRequired,
  FINISH_TEXT: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.func.isRequired,
    firstDescription: PropTypes.string,
    secondDescription: PropTypes.string,
    thirdDescription: PropTypes.string
  }).isRequired,
};

export default Finish;
