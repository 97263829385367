import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useWindowSize from 'hooks/useWindowSize';
import { Box } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ContractLink from '../../globals/ContractLink';
import { SECTION_COVERAGES } from '../../../../../constants/texts';

const CoveragesButton = ({ showRoofBasic, setShowRoofBasic }) => {
  const windowSize = useWindowSize();
  const isMDup = windowSize.width > 1190;

  return isMDup ? (
    <>
      <Box className="TextoRoof">
        <Box className="button-roof">
          <Box display="flex">
            <Box
              mr={11}
              className={`${!showRoofBasic ? 'selected' : 'notSelected'}`}
              onClick={() => setShowRoofBasic(false)}
            >
              <Box className="subtitle">{SECTION_COVERAGES.COVERAGE}</Box>
              <Box display="flex" alignItems="center" className="title">
                {!showRoofBasic && (
                  <Box>
                    <ArrowRight />
                  </Box>
                )}

                <span>{SECTION_COVERAGES.TYPE_COVERAGE.BASIC}</span>
              </Box>
            </Box>

            <Box className={`${showRoofBasic ? 'selected' : ''}`} onClick={() => setShowRoofBasic(true)}>
              <Box className="subtitle">{SECTION_COVERAGES.COVERAGE}</Box>
              <Box display="flex" alignItems="center" className="title">
                {showRoofBasic && (
                  <Box>
                    <ArrowRight />
                  </Box>
                )}

                <span>{SECTION_COVERAGES.TYPE_COVERAGE.ADDITIONAL}</span>
              </Box>
            </Box>
          </Box>

          <Box>
            <ContractLink />
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <>
      <Box className="container-mobile">
        <Box className="buttons">
          <button
            type="button"
            onClick={() => {
              setShowRoofBasic(false);
            }}
            className={clsx('button', !showRoofBasic && 'Ativo')}
            label="basica"
          >
            {SECTION_COVERAGES.BUTTON_COVERAGE_BASIC}
          </button>

          <button
            type="button"
            onClick={() => {
              setShowRoofBasic(true);
            }}
            className={clsx('button', showRoofBasic && 'Ativo')}
            label="adicionais"
          >
            {SECTION_COVERAGES.BUTTON_COVERAGE_ADD}
          </button>
        </Box>

        <span>
          {showRoofBasic ? SECTION_COVERAGES.PROTECTION_EXTRA_DAY_TO_DAY : SECTION_COVERAGES.INCLUSION_ALL_CONTRACTS}
        </span>
      </Box>
    </>
  );
};

CoveragesButton.propTypes = {
  showRoofBasic: PropTypes.bool.isRequired,
  setShowRoofBasic: PropTypes.func.isRequired,
};

export default CoveragesButton;
