import React from 'react';
import PropTypes from 'prop-types';
import 'assets/style/privacy-modal.scss';
import { Box } from '@material-ui/core';
import { SECTION_COVERAGES } from '../../../../../constants/texts';

const PrivacyModal = ({ show, setShowPrivacyModal, cardActive, setCardActive }) =>
  show && (
    <Box id="myModal" className="privacy-modal" role="presentation" data-testid="modal-privacymodal">
      <Box className="modal-content">
        <Box className="modal-box-item">
          <button
            type="button"
            className="close"
            onClick={() => {
              setShowPrivacyModal(false);
              setCardActive({});
            }}
          >
            _
          </button>

          <a href={cardActive.link}>
            <Box className="modal-box-thumb">
              <Box display="flex">
                <Box mr={2} mb={2}>
                  <img src={cardActive.imgUrl} alt={cardActive.alt} />
                </Box>

                <Box>
                  <Box className="modal-box-name">{SECTION_COVERAGES.IN_CASE_OF}</Box>
                  <Box className="modal-box-title">{cardActive.title}</Box>
                </Box>
              </Box>
            </Box>

            <Box className="modal-box-list">
              {cardActive.itens.map((itemList, i) => (
                <span className="modal-box-list-item white-space" key={i}>
                  {itemList.item}
                </span>
              ))}
            </Box>
          </a>

          <Box
            display="flex"
            justifyContent="center"
            mt={2}
            onClick={() => {
              setShowPrivacyModal(false);
              setCardActive({});
            }}
          >
            <span className="close">Fechar</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );

PrivacyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowPrivacyModal: PropTypes.func.isRequired,
  cardActive: PropTypes.object.isRequired,
  setCardActive: PropTypes.func.isRequired,
};

export default PrivacyModal;
