interface AboutYouFormSchema {
  useSocialName: boolean;
  name: string;
  email: string;
  phone: string;
  document: string;
  trustedServiceAgreement: boolean;
  trustedCommunicationsPottencial: boolean;
}

const INITIAL_FORM_DATA: AboutYouFormSchema = {
  useSocialName: false,
  name: '',
  email: '',
  phone: '',
  document: '',
  trustedServiceAgreement: false,
  trustedCommunicationsPottencial: false,
};

export type { AboutYouFormSchema };
export { INITIAL_FORM_DATA };
