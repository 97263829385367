import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box, Paper, Button, RadioGroup, Typography, FormControl, FormControlLabel } from '@material-ui/core';

const TypesSelection = ({ title, param, parentFormik, collection, onChange, getDisabled }) => (
  <Box>
    <Box mb={1}>
      <Typography variant="body2" color="textSecondary">
        <b>{title}:</b>
      </Typography>
    </Box>

    <FormControl fullWidth>
      <RadioGroup
        id={param}
        name={param}
        {...parentFormik.getFieldProps(param)}
        className="card-buttons__radio-group"
      >
        {collection.map((type, index) => (
          <FormControlLabel 
            className="div-form-control-label"
            key={index}
            title={type.title}
            control={
              <Box
                display="flex"
                alignItems="center"
                className={clsx({
                  'card-buttons__active': parentFormik.values[param] === type.key,
                  'card-buttons__disabled': getDisabled(type),
                  'div-property': true
                })}
                data-testid={`box-${type.key}-aboutPropertyStep`}
                minWidth={235}
              >
                <Paper
                  className="card-buttons__paper-card"
                  data-testid={`radio-${type.key}-aboutPropertyStep`}
                  onClick={() => {
                    if (!getDisabled(type)) {
                      onChange(type.key, () => parentFormik.setFieldValue(param, type.key));
                    }
                  }}
                >
                  <Button
                    disabled={getDisabled(type)}
                    className="cards__option-card"
                    name={param}
                    id={`residencial-site-${type.key}-card`}
                  >
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={1}>
                      <Box>
                        <img src={type.ico} alt={type.title} loading="eager" />
                      </Box>
                    </Box>
                  </Button>
                </Paper>

                <Box ml={2} textAlign="center" className='div-typography-box'>
                  <Typography variant="body2" className='div-typography'>{type.title}</Typography>
                </Box>
              </Box>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  </Box>
);

TypesSelection.propTypes = {
  title: PropTypes.string.isRequired,
  param: PropTypes.string.isRequired,
  parentFormik: PropTypes.object.isRequired,
  collection: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  getDisabled: PropTypes.func.isRequired,
};

TypesSelection.defaultProps = {
  onChange: () => {},
};

export default TypesSelection;
