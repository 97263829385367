import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import icoWarning from 'assets/img/icon-alert-warning.svg';
import Svg from 'react-inlinesvg';
import ModalInfo from 'components/ModalInfo/ModalInfo';
import { modalInfoProps } from './types';

const useStyles = makeStyles(() => ({
  modal: {
    '& .MuiPaper-root': { maxWidth: '550px !important' },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: 20,
  },
  buttom: {
    marginTop: 30,
    width: '50%',
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  message: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600,
  },
}));

const SocialNameModalInfo: React.FC<modalInfoProps> = ({ ...props }: modalInfoProps) => {
  const classes = useStyles();
  return (
    <>
      <ModalInfo open={props.open} className={classes.modal}>
        <Box p={4}>
          <Svg className={classes.image} src={icoWarning} width={230} height={100} />
          <Typography className={classes.message}>{props.title}</Typography>

          <Box className={classes.buttons} gridGap={4}>
            <Button
              className={classes.buttom}
              color="primary"
              variant="outlined"
              title={props.unconfimedButton}
              onClick={() => {
                props.callReturnEvent(false);
              }}
            >
              {props.unconfimedButton}
            </Button>
            <Button
              className={classes.buttom}
              color="primary"
              title={props.confirmedButton}
              variant="contained"
              onClick={() => {
                if (props?.openSupportChannel) {
                  window.open('https://pottencial.com.br/fale-conosco/');
                }
                props.callReturnEvent(true);
              }}
            >
              {props.confirmedButton}
            </Button>
          </Box>
        </Box>
      </ModalInfo>
    </>
  );
};

export default SocialNameModalInfo;
