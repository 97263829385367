import { useEffect, useRef } from 'react';
import useUtils from 'hooks/useUtils';
import { DEFAULT_BASIC_COVERAGE_VALUE, HOURS } from 'constants/defaults';
import { PARTNER_ROLES } from 'constants/collections';
import useRisk from './useRisk';
import useParticipant from './useParticipant';
import useCoverages from './useCoverages';
import useQuote from './useQuote';
import usePayment from './usePayment';
import useBroker from './useBroker';
import usePartner from './usePartner';

const usePayloads = () => {
  const { date, removeEmptyObjects } = useUtils();

  const { getRiskState } = useRisk();
  const { getParticipantState } = useParticipant();
  const { getBasicCoverage, getAdditionalCoverages } = useCoverages();
  const { getQuoteState } = useQuote();
  const { getPaymentState } = usePayment();
  const { getBroker } = useBroker();
  const { getPartnerState } = usePartner();

  const createQuotePayloadRef = useRef(null);
  const updateQuotePayloadRef = useRef(null);
  const createPersonHefestoPayloadRef = useRef(null);
  const createProposalPayloadRef = useRef(null);

  const sharedQuotePayload = {
    riskData: {
      insuredAmount: getBasicCoverage?.coverage?.insuredAmount?.value || DEFAULT_BASIC_COVERAGE_VALUE,
      propertyType: getRiskState.propertyType,
      constructionType: getRiskState.constructionType,
      propertyUseType: getRiskState.propertyUseType,
      address: {
        street: getRiskState.address?.street,
        number: getRiskState.address?.number,
        complement: getRiskState.address?.complement,
        district: getRiskState.address?.district,
        city: getRiskState.address?.city,
        state: getRiskState.address?.uf,
        zipCode: getRiskState.address?.zipCode,
        type: 'Residential',
        country: 'Brasil',
      },
      historicalProtectedProperty: false,
      sharedProperty: false,
      insuredOwner: true,
      documentPeriodStart: date().set(HOURS),
      documentPeriodEnd: date().add(1, 'y').set(HOURS),
    },
    commissionedAgents: [
      {
        personId: getBroker().ID,
        role: 'Broker',
        lead: true,
        commissionPercentage: getBroker().COMMISSION_PERCENTAGE,
      },
    ],
    participants: [
      {
        documentNumber: getParticipantState?.document,
        role: 'Insured',
        personId: getParticipantState?.personId,
      },
    ],
    discount: getBroker().DISCOUNT,
    commercialLoadingFee: 0
  };

  const createQuotePayload = () => {
    let partners = [];
    if (getPartnerState.id && getPartnerState.isValid)
      partners = [
        {
          personId: getPartnerState.id,
          partnerRole: PARTNER_ROLES.commercialAgent
        }
      ];
    
    return {
      ...sharedQuotePayload,
      coverages: [
        {
          id: getBasicCoverage?.coverage?.id,
          insuredAmount: getBasicCoverage?.coverage?.insuredAmount?.value || DEFAULT_BASIC_COVERAGE_VALUE,
        },
      ],
      createdBy: getParticipantState?.personId,
      partners
    };
  };

  const updateQuotePayload = () => {
    const coverages = getAdditionalCoverages
      ?.map((coverageItem) => {
        const additionalCoverage = {};
        const isValidCoverage = !!coverageItem.coverage?.insuredAmount?.value;

        if (isValidCoverage) {
          additionalCoverage.id = coverageItem?.coverage?.id;
          additionalCoverage.insuredAmount = coverageItem?.coverage?.insuredAmount?.value;
        }

        return additionalCoverage;
      })
      .concat({
        id: getBasicCoverage?.coverage?.id,
        insuredAmount: getBasicCoverage?.coverage?.insuredAmount?.value || DEFAULT_BASIC_COVERAGE_VALUE,
      });

    return {
      ...sharedQuotePayload,
      coverages: removeEmptyObjects(coverages),
      updatedBy: getParticipantState?.personId,
    };
  };

  const createPersonHefestoPayload = () => ({
    document: getParticipantState?.document,
    name: getParticipantState?.name,
    email: getParticipantState?.email,
    personId: getParticipantState?.personId,
  });

  const createProposalPayload = () => ({
    createdBy: getParticipantState?.personId,
    quoteId: getQuoteState?.id,
    payment: {
      firstDueDate: getPaymentState?.firstDueDate,
      paymentType: getPaymentState?.type,
      installmentNumber: getPaymentState?.installmentNumber,
    },
  });

  useEffect(() => {
    createQuotePayloadRef.current = createQuotePayload();
    updateQuotePayloadRef.current = updateQuotePayload();
    createPersonHefestoPayloadRef.current = createPersonHefestoPayload();
    createProposalPayloadRef.current = createProposalPayload();
  }, [getRiskState, getParticipantState, getQuoteState, getPaymentState, getBasicCoverage, getAdditionalCoverages]);

  return {
    createQuotePayloadRef,
    updateQuotePayloadRef,
    createPersonHefestoPayloadRef,
    createProposalPayloadRef,
  };
};

export default usePayloads;
