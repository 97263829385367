export const DONT_USE_SOCIALNAME_MODAL = {
  TITLE:
    'Atenção! Essa pessoa possui nome social cadastrado aqui na Pottencial. Ao optar por não utilizá-lo, a partir de hoje, voltaremos a nos relacionar utilizando o seu nome de registro.',
  CONFIRMED_BUTTON: 'Manter nome social',
  UNCONFIRMED_BUTTON: 'Não usar nome social',
};

export const EDIT_SOCIALNAME_MODAL = {
  TITLE:
    'Atenção! Ao seguir com a alteração do nome social aqui na Pottencial, a partir de hoje, passaremos a nos relacionar utilizando o novo nome social informado.',
  CONFIRMED_BUTTON: 'Alterar nome social',
  UNCONFIRMED_BUTTON: 'Não alterar nome social',
};

export const OFFENSIVE_SOCIALNAME_MODAL = {
  TITLE:
    'Não foi possível seguir com o cadastro do nome social, por favor entre em contato com os nossos canais de atendimento.',
  CONFIRMED_BUTTON: 'Canais de atendimento',
  UNCONFIRMED_BUTTON: 'Fechar',
};
