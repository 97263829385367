import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import useCoverages from 'hooks/useCoverages';
import useGlobal from 'hooks/useGlobal';
import useBroker from 'hooks/useBroker';
import { useAlert } from 'components/Alert/Alert';
import CustomAppBar from 'components/CustomAppBar/CustomAppBar';
import { FINISH } from 'constants/textsSteps';
import { MESSAGE_COVERAGEBASIC_ERROR } from 'constants/messages';
import PromotionalModal from 'components/PromotionalModal/PromotionalModal';
import Steps from './Steps';
import Finish from './Finish';
import 'assets/style/pages/contract.scss';
import Boleto from './paymentComponents/Boleto';

const Init = () => {
  const { setResetFinish, getFinishingState } = useGlobal();
  const { loadCoverages } = useCoverages();
  const { addMsgDanger } = useAlert();
  const { getBroker, getBrokerPromotionalLogo } = useBroker();
  const [promotional, setPromotional] = useState();

  useEffect(() => {
    setResetFinish();
    loadCoverages()
      .then(() => { 
        if (getBroker().PROMOTIONAL_MESSAGE) setPromotional({message: getBroker().PROMOTIONAL_MESSAGE, logo: getBrokerPromotionalLogo()}); 
      })
      .catch(() => addMsgDanger(MESSAGE_COVERAGEBASIC_ERROR));
    // eslint-disable-next-line
  }, []);

  return (
    <Box height={1} position="absolute" width={1} id="contract">
      <CustomAppBar>
        {!getFinishingState && <Steps />}
        {promotional &&  
          <PromotionalModal
            text={promotional.message}
            logo={promotional.logo}
          /> 
        }
        {getFinishingState && <Finish FINISH_TEXT={FINISH}>{Boleto}</Finish>}
      </CustomAppBar>
    </Box>
  );
};

export default Init;
