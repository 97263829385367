import React from 'react';
import { Grid } from '@material-ui/core';
import { IMAGES_SOCIALS } from 'constants/defaults';
import logo from 'assets/img/general/logo.svg';
import 'assets/style/footer.scss';
import { GLOBALS } from '../../../../constants/texts';

const Footer = () => (
  <footer id="main-footer">
    <div className="containerFooter">
      <div className="flex-wrapper">
        <Grid item md={3} lg={2} sm={4}>
          <div className="center">
            <div className="center-logo">
              <a title="Ir para a página principal" aria-label="Ir para a página principal" href="/">
                <img src={logo} alt="Logo - Pottencial" />
              </a>
            </div>

            <div className="left-social">
              <ul>
                <li>
                  <a
                    href="https://www.facebook.com/Pottencial"
                    title="Ir para o facebook"
                    aria-label="Ir para o facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IMAGES_SOCIALS.facebook} alt="Ícone - Facebook" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://twitter.com/pottencial"
                    title="Ir para o twitter"
                    aria-label="Ir para o twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IMAGES_SOCIALS.twitter} alt="Ícone - Twitter" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://instagram.com/pottencialseguradora"
                    title="Ir para o instagram"
                    aria-label="Ir para o instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IMAGES_SOCIALS.instagram} alt="Ícone - Instagram" />
                  </a>
                </li>

                <li>
                  <a
                    href="https://www.linkedin.com/company/pottencial-seguradora-s-a"
                    title="Ir para o linkedin"
                    aria-label="Ir para o linkedin"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IMAGES_SOCIALS.linkedin} alt="Ícone - Linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Grid>

        <Grid item md={9} lg={10} sm={8}>
          <div className="right">
            <Grid item md={3} lg={3} sm={6}>
              <div className="center">
                <div className="center-title">{GLOBALS.FOOTER.MENU_FOOTER_POTTENCIAL.TITLE}</div>

                <div className="column-list">
                  <ul>
                    <li>
                      <a
                        title="Ir para a página sobre"
                        aria-label="Ir para a página sobre"
                        href="https://pottencial.com.br/sobre-nos/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_POTTENCIAL.ABOUT_US}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Ir para a página trabalhe conosco"
                        aria-label="Ir para a página trabalhe conosco"
                        href="https://pottencial.com.br/trabalhe-conosco/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_POTTENCIAL.WORK_WITH_US}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Ir para a página de certidões legais"
                        aria-label="Ir para a página de certidões legais"
                        href="https://pottencial.com.br/certidoes-legais/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_POTTENCIAL.LEGAL_CERTIFICATES}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Ir para a página de ouvidoria"
                        aria-label="Ir para a página de ouvidoria"
                        href="https://pottencial.com.br/ouvidoria/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_POTTENCIAL.OMBUDSMAN}
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://pottencial.com.br/transparencia-e-privacidade/"
                        title="Ir para a página de Transparência e Privacidade"
                        aria-label="Ir para a página de Transparência e Privacidade"
                        target="_self"
                        rel="noopener noreferrer"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_POTTENCIAL.TRANSPARENCY_AND_PRIVACY}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Abrir preferências de cookies"
                        aria-label="Abrir preferências de cookies"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ot-sdk-show-settings"
                        href="https://stdevsiteinst01.blob.core.windows.net/sitefiles/Governanca/Politica-cookies-v001.pdf"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_POTTENCIAL.COOKIES_PREFERENCES}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>

            <Grid item md={3} lg={3} sm={6}>
              <div className="center">
                <div className="center-title">{GLOBALS.FOOTER.MENU_FOOTER_RESOURCES.TITLE}</div>

                <div className="column-list">
                  <ul>
                    <li>
                      <a
                        title="Ir para a página de consultar apólice"
                        aria-label="Ir para a página de consultar apólice"
                        href="https://pottencial.com.br/consultar-apolice/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_RESOURCES.CONSULT_POLICY}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Ir para a página de blog"
                        aria-label="Ir para a página de blog"
                        href="https://blog.pottencial.com.br/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_RESOURCES.BLOG}
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://glossario.pottencial.com.br/"
                        title="Ir para a página Glossário de Seguros"
                        aria-label="Ir para a página Glossário de Seguros"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_RESOURCES.INSURANCE_GLOSSARY}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Ir para a página de sinistro"
                        aria-label="Ir para a página de signistro"
                        href="https://pottencial.com.br/sinistro/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_RESOURCES.SINISTER}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Ir para a página Manifesto Ágil"
                        aria-label="Ir para a página Manifesto Ágil"
                        href="https://agiliza.pottencial.com.br/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_RESOURCES.MANIFESTO_AGIL}
                      </a>
                    </li>

                    <li>
                      <a
                        title="Ir para a página trabalhe conosco"
                        aria-label="Ir para a página trabalhe conosco"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://developers.pottencial.com.br/api-portal/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_RESOURCES.DEVELOPER_PORTAL}
                      </a>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </Grid>

            <Grid item md={3} lg={3} sm={6}>
              <div className="center">
                <div className="center-title">{GLOBALS.FOOTER.MENU_FOOTER_CHANNELS.TITLE}</div>

                <div className="column-list">
                  <ul>
                    <li>
                      <a
                        title="Ir para a página de contato"
                        aria-label="Ir para a página de contato"
                        href="https://pottencial.com.br/fale-conosco/"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_CHANNELS.CONTACT_US}
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://ethicspeakup.com.br/pottencial/"
                        title="Ir para a página"
                        aria-label="Ir para a página"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {GLOBALS.FOOTER.MENU_FOOTER_CHANNELS.COMPLAINT_CHANNEL}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>

            <Grid item md={3} lg={3} sm={6}>
              <div className="center">
                <div className="center-title">{GLOBALS.FOOTER.MENU_FOOTER_SAC.TITLE}</div>

                <div className="column-list">
                  <ul>
                    <li>
                      <a
                        href="tel:3121217777"
                        className="link"
                        title="Fale por telefone"
                        aria-label="Fale por telefone"
                      >
                        <div className="icon">
                          <img src={IMAGES_SOCIALS.phone} alt="Ícone - Pottencial" />{' '}
                          <div className="tel">{GLOBALS.FOOTER.MENU_FOOTER_SAC.PHONE_NUMBER}</div>
                        </div>
                      </a>
                    </li>

                    <li>
                      <a
                        href="tel:08006067688"
                        className="link"
                        title="Fale por telefone"
                        aria-label="Fale por telefone"
                      >
                        <div className="icon">
                          <img src={IMAGES_SOCIALS.phone} alt="Ícone - Pottencial" />{' '}
                          <div className="tel">{GLOBALS.FOOTER.MENU_FOOTER_SAC.PHONE_NUMBER_0800}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
      <div className="copyright">
        <p>{GLOBALS.FOOTER.COPYRIGHT.INFO_FOOTER}</p>

        <p>{GLOBALS.FOOTER.COPYRIGHT.ADDRESS}</p>
      </div>
    </div>
  </footer>
);

export default Footer;
