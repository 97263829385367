import { useDispatch } from 'react-redux';
import { persistStore } from 'redux-persist';
import { INITIAL_STATE } from 'redux/requestReducer';
import configureAppStore from './store';

const store = configureAppStore(INITIAL_STATE);
const persist = persistStore(store);

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export { store, persist };
