import { useCallback, useEffect, useState } from 'react';
import SocialNameActivateModal from './UseSocialNameModal';
import SocialNameEditionModal from './EditSocialNameModal';
import SocialNameOffensiveWordsModal from './OffensiveNameModal';
import SocialNameExclusionModal from './DontUseSocialNameModal';

const SocialNameModal = () => {
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const handleSocialNameModalEvent = useCallback(({ detail }) => {
    const { socialNameModalType } = detail;
    if (socialNameModalType) {
      setModalType(socialNameModalType);
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('socialNameModal', handleSocialNameModalEvent);

    return () => {
      window.removeEventListener('socialNameModal', handleSocialNameModalEvent);
    };
  }, [handleSocialNameModalEvent]);

  const callSocialNameModalReturnEvent = (response: boolean) => {
    const detail = {
      type: modalType,
      result: response,
    };
    const socialNameModalReturnEvent = new CustomEvent('socialNameModalReturn', { detail });
    window.dispatchEvent(socialNameModalReturnEvent);
    setOpen(false);
  };

  return (
    <>
      {modalType === 'SocialNameActivateModal' && (
        <SocialNameActivateModal open={open} callReturnEvent={callSocialNameModalReturnEvent} />
      )}
      {modalType === 'SocialNameEditionModal' && (
        <SocialNameEditionModal open={open} callReturnEvent={callSocialNameModalReturnEvent} />
      )}
      {modalType === 'SocialNameOffensiveWordsModal' && (
        <SocialNameOffensiveWordsModal open={open} callReturnEvent={callSocialNameModalReturnEvent} />
      )}
      {modalType === 'SocialNameExclusionModal' && (
        <SocialNameExclusionModal open={open} callReturnEvent={callSocialNameModalReturnEvent} />
      )}
    </>
  );
};

export default SocialNameModal;
