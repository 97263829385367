import { useCallback, useEffect, useState } from 'react';

enum SocialNameEventType {
  Invoke = 'socialNameModal',
  Return = 'socialNameModalReturn',
}

export type SocialNameModalType =
  | 'SocialNameActivateModal'
  | 'SocialNameEditionModal'
  | 'SocialNameOffensiveWordsModal'
  | 'SocialNameExclusionModal';

type ExecuteAfterModalOptions = {
  onAccept: () => void | (() => Promise<void>);
  onRefuse: () => void | (() => Promise<void>);
};

const useSocialNameModalConfirmation = () => {
  const [currentWaitingEvent, setCurrentWaitingEvent] = useState<{
    type: SocialNameModalType;
    options: ExecuteAfterModalOptions;
  }>();

  const executeAfterModal = (modalType: SocialNameModalType, options: ExecuteAfterModalOptions) => {
    const detail = {
      socialNameModalType: modalType,
    };
    const modalEvent = new CustomEvent(SocialNameEventType.Invoke, { detail });
    window.dispatchEvent(modalEvent);

    setCurrentWaitingEvent({ type: modalType, options });
  };

  const handleSocialNameModalReturnEvent = useCallback(
    ({ detail }) => {
      const { type, result } = detail;

      if (type === currentWaitingEvent?.type) {
        if (result) currentWaitingEvent?.options.onAccept();
        else currentWaitingEvent?.options.onRefuse();
      }

      setCurrentWaitingEvent(undefined);
    },
    [currentWaitingEvent]
  );

  useEffect(() => {
    window.addEventListener(SocialNameEventType.Return, handleSocialNameModalReturnEvent);

    return () => {
      window.removeEventListener(SocialNameEventType.Return, handleSocialNameModalReturnEvent);
    };
  }, [handleSocialNameModalReturnEvent]);

  return { executeAfterModal };
};

export default useSocialNameModalConfirmation;
