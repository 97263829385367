export const getPayments = ({ request }) => request.payments;

export const getStep = ({ request }) => request.step;

export const getSlide = ({ request }) => request.slide;

export const getTermService = ({ request }) => request.termService;

export const getCoverages = ({ request }) => request.coverages;

export const getQuote = ({ request }) => request.quote;

export const getFinishing = ({ request }) => request.finishing;

export const getParticipant = ({ request }) => request.participant;

export const getRisk = ({ request }) => request.riskData;

export const getAddress = ({ request }) => request.riskData.address;

export const getTrustService = ({ request }) => request.trustService;

export const getProposal = ({ request }) => request.proposal;

export const getPartner = ({ request }) => request.partner;

export const getRestrictions = ({ request }) => request.restrictions;