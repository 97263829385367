import { useCallback } from 'react';
import { findLast } from 'lodash';

import { ICONS_COVERAGE_ADDITIONAL } from 'constants/collections';
import { DEFAULT_BASIC_COVERAGE_VALUE } from 'constants/defaults';
import useUtils from './useUtils';
import useCoverages from './useCoverages';

const useHelpers = () => {
  const { formatCurrency } = useUtils();
  const { getBasicCoverage } = useCoverages();
  const { value: basicCoverageValue } = getBasicCoverage?.coverage?.insuredAmount ?? DEFAULT_BASIC_COVERAGE_VALUE;

  const validateValue = useCallback((value, valueMin, valueMax, setValidation) => {
    if (value === 0) {
      setValidation('Valor não pode ser R$ 0,00');
      return false;
    }

    if (value < valueMin) {
      setValidation(`Valor mínimo é R$ ${formatCurrency(valueMin)}`);
      return false;
    }

    if (value > valueMax) {
      setValidation(`Valor máximo é ${formatCurrency(valueMax)}`);
      return false;
    }

    setValidation('');
    return true;
  }, []);

  const getValueMax = useCallback((coverage) => {
    const max = coverage?.insuredAmount?.max?.value;
    const maxCurrent = basicCoverageValue * coverage?.insuredAmount?.max?.percent;

    if (maxCurrent > max) {
      return max;
    }
    return maxCurrent;
  }, []);

  const validateValueCoverage = useCallback((coverage, value, setValidation, setValidationId) => {
    const clearValidation = () => {
      setValidation('');
      setValidationId('');
    };

    if (value === 0) {
      setValidation('');
      setValidationId(coverage.id);
      return false;
    }

    const { min } = coverage?.insuredAmount || {};

    if (value < min?.value) {
      setValidation(`Valor mínimo é R$ ${formatCurrency(min?.value)}`);
      setValidationId(coverage.id);
      return false;
    }

    if (value > getValueMax(coverage)) {
      setValidation(`Valor máximo é ${formatCurrency(getValueMax(coverage))}`);
      setValidationId(coverage.id);
      return false;
    }

    clearValidation();
    return true;
  }, []);

  const getIcon = useCallback((stringIcon) => {
    const icon = findLast(ICONS_COVERAGE_ADDITIONAL, (current) => current.key === stringIcon)?.icon;
    return icon || '';
  });

  return {
    validateValue,
    getValueMax,
    validateValueCoverage,
    getIcon,
  };
};

export default useHelpers;
