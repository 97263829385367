import * as Yup from 'yup';
import { isCpf, isCnpj, isCep } from 'validator-brazil';

import useUtils from './useUtils';
import { CELLPHONE_SIZE_WITHOUT_MASK, PHONE_MASKS, PHONE_SIZE_WITHOUT_MASK } from '../constants/defaults';

const useYup = () => {
  const { isBlank, getOnlyNumber } = useUtils();

  const cpf = Yup.string().test({
    name: 'cpf',
    message: 'Insira um CPF válido.',
    test: (value) => {
      let valid = true;

      if (value) {
        valid = isCpf(value);
      }
      return valid;
    },
  });

  const cep = Yup.string().test({
    name: 'cep',
    message: 'Insira um CEP inválido.',
    test: (value) => {
      let valid = true;

      if (value) {
        valid = isCep(value);
      }
      return valid;
    },
  });

  const cnpj = Yup.string().test({
    name: 'cnpj',
    message: 'Insira um CNPJ inválido.',
    test: (value) => {
      let valid = true;

      if (value) {
        valid = isCnpj(value);
      }
      return valid;
    },
  });

  const cpfOrCnpj = Yup.string().test({
    name: 'cpfOrCnpj',
    message: 'Insira um CPF inválido.',
    test: (value) => {
      let valid = true;

      if (value) {
        valid = isCpf(value) || isCnpj(value);
      }
      return valid;
    },
  });

  const phone = Yup.string().test({
    name: 'phone',
    message: 'Insira um telefone válido.',
    test: (value) => {
      let valid = true;

      if (value) {
        valid = value.length === PHONE_MASKS.phone.length;
      }
      return valid;
    },
  });

  const cellphone = Yup.string().test({
    name: 'cellphone',
    message: 'Insira um telefone válido.',
    test: (value) => {
      let valid = true;

      if (value) {
        valid = value.length === PHONE_MASKS.cellphone.length;
      }
      return valid;
    },
  });

  const phoneOrCellphone = Yup.string().test({
    name: 'phoneOrCellphone',
    message: 'Insira um telefone/celular válido.',
    test: (value) => {
      const inputOnlyNumber = getOnlyNumber(value);

      if (!isBlank(inputOnlyNumber))
        return (
          inputOnlyNumber.length === PHONE_SIZE_WITHOUT_MASK || inputOnlyNumber.length === CELLPHONE_SIZE_WITHOUT_MASK
        );

      return true;
    },
  });

  return {
    cpf,
    cnpj,
    cpfOrCnpj,
    phone,
    cellphone,
    phoneOrCellphone,
    cep,
  };
};

export default useYup;
