import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/style/assist-modal.scss';
import { Box, Typography, Button } from '@material-ui/core';
import bgSecure from 'assets/img/home/SectionWhatYouNeed/bg-secure.png';
import SectionModal from './SectionModal';
import { SECTION_CUSTOMER } from '../../../../../constants/texts';

const SecureModal = ({ show, setSecureModal }) =>
  show && (
    <SectionModal open={show} image={bgSecure} text="Acionar seguro">
      <Box pr={5} className="box-side-text">
        <Box my={3}>
          <Typography component="span" color="primary" className="title">
            {SECTION_CUSTOMER.ACTIVATE_INSURANCE_MODAL.TITLE}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.ACTIVATE_INSURANCE_MODAL.PARAGRAPH_1}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.ACTIVATE_INSURANCE_MODAL.PARAGRAPH_2}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            <Box display="flex" alignItems="center">
              {SECTION_CUSTOMER.ACTIVATE_INSURANCE_MODAL.PARAGRAPH_3}
              <span className="email">{SECTION_CUSTOMER.EMAIL_SINISTER}</span>
            </Box>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center" mt={6}>
          <Box mr={3}>
            <Button variant="text" color="primary" onClick={() => setSecureModal(false)} className={styles.close}>
              {SECTION_CUSTOMER.CLOSED_BUTTON}
            </Button>
          </Box>

          <Button variant="contained" color="primary">
            <a href="mailto:sinistros.patrimoniais@pottencial.com.br">{SECTION_CUSTOMER.SEND_EMAIL}</a>
          </Button>
        </Box>
      </Box>
    </SectionModal>
  );

SecureModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setSecureModal: PropTypes.func.isRequired,
};

export default SecureModal;
