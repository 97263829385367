import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import bgBillet from 'assets/img/home/SectionWhatYouNeed/bg-billet.png';
import SectionModal from './SectionModal';
import { SECTION_CUSTOMER } from '../../../../../constants/texts';

const BilletModal = ({ show, setBilletModal }) =>
  show && (
    <SectionModal open={show} image={bgBillet} text="Segunda via do boleto">
      <Box pr={5} className="box-side-text">
        <Box my={3}>
          <Typography component="span" color="primary" className="title">
            {SECTION_CUSTOMER.COPY_OF_BILL_MODAL.TITLE}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.COPY_OF_BILL_MODAL.PARAGRAPH_1}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.COPY_OF_BILL_MODAL.PARAGRAPH_2}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.COPY_OF_BILL_MODAL.PARAGRAPH_3}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            <Box display="flex" alignItems="center">
              {SECTION_CUSTOMER.COPY_OF_BILL_MODAL.PARAGRAPH_4}
              <span className="email">{SECTION_CUSTOMER.EMAIL_CHARGE}</span>
            </Box>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center" mt={6}>
          <Box mr={3}>
            <Button variant="text" color="primary" onClick={() => setBilletModal(false)}>
              {SECTION_CUSTOMER.CLOSED_BUTTON}
            </Button>
          </Box>

          <Button variant="contained" color="primary">
            <a href="mailto:cobranca@pottencial.com.br">{SECTION_CUSTOMER.SEND_EMAIL}</a>
          </Button>
        </Box>
      </Box>
    </SectionModal>
  );

BilletModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setBilletModal: PropTypes.func.isRequired,
};

export default BilletModal;
