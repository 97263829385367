import React from 'react';
import PropTypes from 'prop-types';
import { Box, AppBar, Typography, useMediaQuery } from '@material-ui/core';
import useBroker from 'hooks/useBroker';
import { FaQuestionCircle } from 'react-icons/fa'

const CustomAppBar = ({ children }) => {

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const handleFaq = () => {
    window.open(window.ENV.FAQ_RESIDENCIAL_URL, '_blank');
  };

  const { getBrokerLogo } = useBroker();

  return (
    <>
      <AppBar position="static" color="default">
        <Box display="flex" justifyContent="space-between" p={2.5} pb={isXs ? 0.7 : 2.5}
          alignItems={isXs ? 'center' : 'flex-start'}
          flexDirection={isXs ? 'column' : 'row'}>
          <Box ml={ isXs ? 0 : 4}>
            <img src={getBrokerLogo()} alt="Logo - Pottencial Residencial" className="custom-app-bar__logo" />
          </Box>

          <Box mr={isXs ? 0 : 4} style={isXs ? {width: '100%', display:'flex', paddingTop:'5px'} : {}}>
            
              {!isXs && (
                <Typography variant="subtitle2" onClick={handleFaq} className="help">
                  Precisa de ajuda?
                </Typography>
              )}
              {isXs && (
                  <FaQuestionCircle onClick={handleFaq} color="#6F6F6F" size={21} style={{marginLeft: 'auto', marginTop: '0px'}}/>
              )}
            
          </Box>
        </Box>
      </AppBar>
      {children}
    </>
  );
};

CustomAppBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomAppBar;
