import axios from 'axios';
import {
  CreateHefestoRequest,
  CreatePersonRequest,
  CreateProposalRequest,
  CreateQuoteRequest,
  ElectronicAcceptRequest,
  UpdateQuoteRequest,
  PersonRequest,
} from 'borders/dtos/request';

import {
  AddressResponse,
  CoveragesResponse,
  PaymentUrlResponse,
  PersonResponse,
  ProposalDocumentResponse,
  CreatePersonResponse,
  CreateProposalResponse,
  CreateQuoteResponse,
  UpdateQuoteResponse,
  RestrictionZipCodeResponse
} from 'borders/dtos/response';

class RequestService {
  searchPersonByDocumentNumber = async (payload: PersonRequest) =>
    (await axios.post<PersonResponse>(`/api/persons/search`, payload)).data;

  getAdditionalCoverages = async () => (await axios.get<CoveragesResponse[]>('api/coverage/additional')).data;

  getBasicCoverage = async () => (await axios.get<CoveragesResponse[]>('api/coverage/basic')).data;

  createQuote = async (payload: CreateQuoteRequest) =>
    (await axios.post<CreateQuoteResponse>('api/quotes', payload)).data;

  updateQuote = async (id: string, payload: UpdateQuoteRequest) =>
    (await axios.put<UpdateQuoteResponse>(`api/quotes/${id}`, payload)).data;

  createPerson = async (payload: CreatePersonRequest) =>
    (await axios.post<CreatePersonResponse>('api/customer/person', payload)).data;

  createHefesto = async (payload: CreateHefestoRequest) => (await axios.post('api/customer', payload)).data;

  createProposal = async (payload: CreateProposalRequest) =>
    (await axios.post<CreateProposalResponse>('api/proposal/accept', payload)).data;

  acceptTerms = async (payload: ElectronicAcceptRequest) => (await axios.post('api/accept', payload)).data;

  getQuotePDF = async (id: string) =>
    (await axios.post(`api/quotes/${id}/pdf`, { showCommission: true, showDiscount: true })).data;

  getPaymentsUrl = async (proposalSequencial: string) =>
    (await axios.get<PaymentUrlResponse>(`api/payments/url/${proposalSequencial}`)).data;

  getProposalDocumentByOrderId = async (orderId: string) =>
    (await axios.get<ProposalDocumentResponse>(`api/orders/${orderId}/proposal`)).data;

  getAddressByZipCode = async (zipCode: number) =>
    (await axios.get<AddressResponse>(`api/addresses/zip-code/${zipCode}`)).data;

  getPersonById = async (personId: string) => 
    (await axios.get<PersonResponse>(`api/persons/${personId}`)).data;

  getInsuredAmountRestrictionByZipCode = async (zipCode: number) => 
    (await axios.get<RestrictionZipCodeResponse>(`api/restrictions/zipCode/${zipCode}/insuredAmount`)).data
}

export default new RequestService();
