import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import CurrencyInput from '../CurrencyInput/CurrencyInput';

const CurrencyInputCustom = (props) => {
  const { value } = props;

  return <CurrencyInput {...props} autoSelect currency="BRL" value={value || 0} component={TextField} />;
};

CurrencyInputCustom.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CurrencyInputCustom.defaultProps = {
  value: 0,
};

export default CurrencyInputCustom;
