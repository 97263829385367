import React from 'react';
import { PROPERTY } from 'constants/textsSteps';
import useParticipant from 'hooks/useParticipant';
import StepText from '../shared/StepText';

const AboutPropertyStepText = () => {

  const { getParticipantState } = useParticipant();
  const isMobile = window.innerWidth <= 1280; 

  const texts = [isMobile ? PROPERTY.textHeaderMobile(getParticipantState?.name) : PROPERTY.textHeader(getParticipantState?.name)];

  return (
    <>
      <StepText textArray={texts} />
    </>
  );
};

export default AboutPropertyStepText;
