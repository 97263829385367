import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import ModalInfo from 'components/ModalInfo/ModalInfo';

const DetailModal = ({ open, setOpen, coverage, icon }) => (
  <ModalInfo open={open} className="eighth__detail-modal" onClose={() => setOpen(false)}>
    <Box pb={3}>
      <Box display="flex" mb={3}>
        <Box display="flex" alignItems="center" justifyContent="center" width={90} mr={1}>
          <img className="eighth__img" src={icon} alt="Logo" />
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box>
            <Typography variant="body2" color="primary">
              <b>Em caso de:</b>
            </Typography>
          </Box>

          <Box data-for="tipTitle">
            <Typography variant="subtitle2">
              <b>{coverage?.name}</b>
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mb={3}>
        <Typography variant="body2" className="eighth__description">
          {coverage?.description}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button color="primary" title="Fechar" variant="text" onClick={() => setOpen(false)}>
          Fechar
        </Button>
      </Box>
    </Box>
  </ModalInfo>
);

DetailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  coverage: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
};

export default DetailModal;
