import React from 'react';

import { ADDITIONAL_COVERAGES } from 'constants/textsSteps';
import useParticipant from 'hooks/useParticipant';
import StepText from '../shared/StepText';

const AdditionalCoverageStepText = () => {
  const { getParticipantState } = useParticipant();

  const isMobile = window.innerWidth <= 1280; 

  const texts = [isMobile ? ADDITIONAL_COVERAGES.textHeaderMobile(getParticipantState?.name) : ADDITIONAL_COVERAGES.textHeader(getParticipantState?.name)];

  return (
    <>
      <StepText textArray={texts} />
    </>
  );
};

export default AdditionalCoverageStepText;
