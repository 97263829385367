import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Paper,
  Button,
  Select,
  MenuItem,
  Typography,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  useMediaQuery
} from '@material-ui/core';
import clsx from 'clsx';
import SVG from 'react-inlinesvg';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import useUtils from 'hooks/useUtils';
import useQuote from 'hooks/useQuote';
import useProposal from 'hooks/useProposal';
import usePayloads from 'hooks/usePayloads';
import usePayment from 'hooks/usePayment';
import useGlobal from 'hooks/useGlobal';
import useValidator from 'hooks/useValidator';
import useBroker from 'hooks/useBroker';
import { useAlert } from 'components/Alert/Alert';
import DateInput from 'components/DateInput/DateInput';
import {
  HOURS,
  HEIGHT_STEP_CONTAINER,
  TYPE_PAYMENT,
  DAYS_TO_PAY,
  DAYS_TO_START_PAY,
  FETCH_STATUS,
  TYPE_CREDIT,
  ROUTES,
} from 'constants/defaults';
import { TYPE_PAYMENTS } from 'constants/collections';
import { MESSAGE_CREATEPROPOSAL_ERROR, MESSAGE_PAYMENT_NOT_READY } from 'constants/messages';
import { PAYMENT } from 'constants/textsSteps';
import { useLoader } from 'components/Loader';
import { useHistory } from 'react-router-dom';
import ButtonsControl from '../shared/ButtonsControl';
import TitleDivider from '../shared/TitleDivider';

const DEFAULT_PAYMENT_STEP = {
  typePayment: TYPE_PAYMENT.billet.key,
  installment: 1,
  endDueDate: '',
};

const PaymentStep = ({ onBack }) => {
  const { addMsgDanger } = useAlert();
  const { date, formatCurrency } = useUtils();
  const { defineCustomMessage: defineCustomLoaderMessage } = useLoader();
  const history = useHistory();
  const { PaymentValidationSchema } = useValidator();
  const { getQuoteState } = useQuote();
  const { getProposalState, createProposal } = useProposal();
  const { createProposalPayloadRef } = usePayloads();
  const { getPaymentState, setPayment, getPaymentUrlByProposalSequencial } = usePayment();
  const { setFinishing } = useGlobal();
  const { getBroker } = useBroker();

  const [installmentsPay, setInstallmentsPay] = useState([]);
  const [dateDisabled, setDateDisabled] = useState(false);

  const installments = getQuoteState?.installments || [];
  const maxForm = date().add(DAYS_TO_PAY, 'd');
  const minForm = date().add(DAYS_TO_START_PAY, 'd').set(HOURS);
  const today = date().set(HOURS);

  const formik = useFormik({
    initialValues: { ...DEFAULT_PAYMENT_STEP },
    validationSchema: PaymentValidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: async (data) => {
      if (data?.typePayment === TYPE_PAYMENT.credit.key) {
        defineCustomLoaderMessage('Aguarde um pouco.\nEstamos lhe redirecionando\npara o pagamento...');
      }

      if (!getProposalState?.sequencial) {
        const createProposalResponse = await createProposal(createProposalPayloadRef.current);

        if (createProposalResponse.meta.requestStatus === FETCH_STATUS.rejected)
          addMsgDanger(MESSAGE_CREATEPROPOSAL_ERROR);
      }
      else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await handlePaymentUrl(data);
      }
    }
  });

  const { setValues } = formik;

  const onChangeType = (type) => {
    if (type === TYPE_PAYMENT.billet.key) {
      setValues({ ...formik.values, installment: 1, endDueDate: minForm.toISOString() });
      setInstallmentsPay(installments.slice(0, getBroker().PAYMENT_CONDITIONS.BILLET.MAX_INSTALLMENTS));
      setDateDisabled(false);
    } else {
      setValues({ ...formik.values, installment: 2, endDueDate: today.toISOString() });
      setDateDisabled(true);
      setInstallmentsPay(installments.slice(1));
    }
  };

  useEffect(() => {
    setValues({ typePayment: DEFAULT_PAYMENT_STEP.typePayment, 
      endDueDate: minForm.toISOString(), 
      installment: DEFAULT_PAYMENT_STEP.installment
     });
    setInstallmentsPay(installments.slice(0, getBroker().PAYMENT_CONDITIONS.BILLET.MAX_INSTALLMENTS));
    setDateDisabled(false);
  }, []);

  useEffect(() => {
    const convertTypePament = (type) => {
      switch (type) {
        case TYPE_PAYMENT.credit.key:
          return TYPE_CREDIT.recurrence.key;
        case TYPE_PAYMENT.billet.key:
        default:
          return type;
      }
    };

    setPayment({
      type: convertTypePament(formik.values.typePayment),
      firstDueDate: formik.values.endDueDate,
      installmentNumber: formik.values.installment,
      isBillet: formik.values.typePayment === TYPE_PAYMENT.billet.key
    });
  }, [formik.values.typePayment, formik.values.endDueDate, formik.values.installment]);

  const handlePaymentUrl = async (data) => {
    const getPaymentsUrlResponse = await getPaymentUrlByProposalSequencial(getProposalState?.sequencial);

    if (getPaymentsUrlResponse.meta.requestStatus === FETCH_STATUS.rejected && data.typePayment === TYPE_PAYMENT.billet.key)
      addMsgDanger(MESSAGE_PAYMENT_NOT_READY);
    else
    if (data.typePayment === TYPE_PAYMENT.credit.key && !!getPaymentsUrlResponse?.payload.paymentUrl)
      window.open(getPaymentsUrlResponse?.payload.paymentUrl, '_self');
    else if (data.typePayment === TYPE_PAYMENT.credit.key)
      history.push(`${getBroker().URL_PATH}/${ROUTES.CreditCardFallback}`);
    else if (getPaymentState?.isBillet)
      setFinishing(true);
  };

  useEffect(() => {
    if (!getProposalState?.sequencial)
      return;

    handlePaymentUrl(formik.values);
  }, [getProposalState?.sequencial]);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      <Box mt={3} minHeight={HEIGHT_STEP_CONTAINER}>
        <Box className="eleventh__side-form">
          <TitleDivider text={PAYMENT.title} minWidth={95} />

          <Box display="flex" flexDirection="column" maxHeight={370} mr={ isMdDown ? 0 : 3}>
            <Box mb={4}>
              <FormControl fullWidth>
                <RadioGroup
                  row
                  id="typePayment"
                  name="typePayment"
                  {...formik.getFieldProps('typePayment')}
                  className="eleventh__radio-group eleventh__radio-group eleventh__box-options">
                  {TYPE_PAYMENTS.map((type) => (
                    <FormControlLabel
                      key={type.key}
                      title={type.title}
                      className="eleventh__form-payment"
                      control={
                        <Paper
                          className={clsx(
                            { eleventh__active: formik.values.typePayment === type.key },
                            'eleventh__paper-card',
                            'eleventh__cards'
                          )}
                          onClick={() => {
                            onChangeType(type?.key);
                            formik.setFieldValue('typePayment', type?.key);
                          }}              
                        >
                          <Button
                            id={type.key}
                            name={type.key}
                            className="eleventh__card-button"
                            data-testid={`button-paymentType-${type.key}`}
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                              minWidth={130}
                              p={1}
                            >
                              <Box>
                                <SVG
                                  src={type?.icon}
                                  fill="gray"
                                  preProcessor={(code) => code.replace(/style=".*?"/g, 'style=" "')}
                                />
                              </Box>

                              <Box>
                                <Typography variant="body2">{type?.title}</Typography>
                              </Box>
                            </Box>
                          </Button>
                        </Paper>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            <Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box mb={3} display="flex" flexDirection="column">
                    <Box>
                      <Typography variant="body2">Selecione o número de parcelas*</Typography>
                    </Box>
                    <Box>
                      <Select
                        fullWidth
                        inputProps={{ id: 'installment', name: 'installment' }}
                        id="installment"
                        name="installment"
                        title="Selecione o número de parcelas*"
                        label="Selecione o número de parcelas*"
                        {...formik.getFieldProps('installment')}
                        MenuProps={{
                          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                          transformOrigin: { vertical: 'top', horizontal: 'left' },
                          getContentAnchorEl: null,
                        }}
                        data-testid='installments-select'
                      >
                        {installmentsPay.map((item) => (
                          <MenuItem key={item?.installmentNumber} value={item?.installmentNumber}>
                            {item?.installmentNumber} x de {formatCurrency(item?.amount)} sem juros
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        hidden={!formik.touched.installment || !formik.errors.installment}
                        error={formik.touched.installment && !!formik.errors.installment}
                      >
                        {formik.errors.installment}
                      </FormHelperText>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box mb={3} display="flex" flexDirection="column">
                    <Box>
                      <Typography variant="body2">Data do vencimento da 1ª parcela*</Typography>
                    </Box>
                    <Box>
                      <DateInput
                        disabled={dateDisabled}
                        fullWidth
                        disablePast
                        id="endDueDate"
                        minDate={minForm}
                        maxDate={maxForm}
                        label=""
                        title="Data do vencimento da 1ª parcela*"
                        {...formik.getFieldProps('endDueDate')}
                        error={formik.touched.endDueDate && !!formik.errors.endDueDate}
                        helperText={formik.touched.endDueDate && formik.errors.endDueDate}
                        data-testid="input-date-paymentStep"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box mt={4}>
              <Typography className="eleventh__broker-disclaimer" variant="body2">
                Corretor Responsável: {getBroker().NAME}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <ButtonsControl
        dataTestid="button-forward-paymentStep"
        onBack={onBack}
        onNext={formik.handleSubmit}
        gridBack={isXs ? 4 : 3}
        gridForward={isXs ? 4 : 3}
        disabled={!isEmpty(formik.errors)}
        textForward="Pagar"
      />
    </>
  );
};

PaymentStep.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default PaymentStep;
