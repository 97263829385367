import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Button } from '@material-ui/core';

const ButtonsControl = ({ onBack, onNext, disabled, isFirstStep, gridBack, gridForward, textForward, dataTestid }) => (
  <Box>
    <Grid container justifyContent="flex-end" alignItems="center">
      <Grid item xs={gridBack}>
        {!isFirstStep && (
          <Button color="primary" title="Anterior" variant="text" fullWidth onClick={onBack}>
            Anterior
          </Button>
        )}
      </Grid>

      <Grid item xs={gridForward}>
        <Button
          style={{ height: 40 }}
          color="primary"
          title={textForward === '' ? 'Continuar' : textForward}
          variant="contained"
          fullWidth
          disabled={disabled}
          onClick={onNext}
          data-testid={dataTestid}
        >
          {textForward === '' ? 'Continuar' : textForward}
        </Button>
      </Grid>
    </Grid>
  </Box>
);

ButtonsControl.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  gridBack: PropTypes.number,
  gridForward: PropTypes.number,
  disabled: PropTypes.bool,
  isFirstStep: PropTypes.bool,
  textForward: PropTypes.string,
  dataTestid: PropTypes.string,
};

ButtonsControl.defaultProps = {
  disabled: false,
  isFirstStep: false,
  textForward: '',
  gridBack: 3,
  gridForward: 4,
  dataTestid: '',
};

export default ButtonsControl;
