import React from 'react';
import { PAYMENT } from 'constants/textsSteps';
import StepText from '../shared/StepText';

const PaymentStepText = () => {

  const isMobile = window.innerWidth <= 1280; 

  const texts = [isMobile ? PAYMENT.textHeaderMobile : PAYMENT.textHeader];

  return (
  <>
    <StepText textArray={texts} />
  </>
  )
};

export default PaymentStepText;
