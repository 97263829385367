import { useDispatch, useSelector } from "react-redux";
import { getRestrictions } from "redux/requestSelectors";
import { requestOperations } from "../redux";

export default () => {
  const dispatch = useDispatch();

  const getRestrictionsState = useSelector(getRestrictions);

  const getInsuredAmountRestriction = async (zipCode) => dispatch(requestOperations.getInsuredAmountRestrictionByZipCode(zipCode));

  return  {
    getRestrictionsState,
    getInsuredAmountRestriction
  };
};
