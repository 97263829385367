import React, { useEffect, useState } from 'react';
import usePartner from 'hooks/usePartner';
import MessageBox from 'components/MessageBox/MessageBox';
import { MESSAGE_INVALID_PARTNER } from 'constants/messages';
import { FETCH_STATUS } from 'constants/defaults';
import SectionBanner from './presentation/home/SectionBanner/SectionBanner';
import SectionAssistance from './presentation/home/SectionAssistance/SectionAssistance';
import SectionCustomer from './presentation/home/SectionCustomer/SectionCustomer';
import SectionCoverages from './presentation/home/SectionCoverages/SectionCoverages';
import SectionPersonAndPerson from './presentation/home/SectionPersonAndPerson/SectionPersonAndPerson';
import SectionWhoNeverDo from './presentation/home/SectionWhoNeverDo/SectionWhoNeverDo';
import Header from './presentation/globals/Header';
import Footer from './presentation/globals/Footer';
import 'assets/style/new-home.scss';

const LandingPage = () => {
  const { validatePartner } = usePartner();
  const [openErrorModal, setOpenErrorModal] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const validationResponse = await validatePartner();
    if (validationResponse?.meta?.requestStatus === FETCH_STATUS.rejected)
    {
      setOpenErrorModal(true);
    }
  }, []);

  return (
    <>
      <Header />
  
      <main id="page-new-home" className="page">
        <SectionBanner />
        <SectionAssistance />
        <SectionCoverages />
        <SectionWhoNeverDo />
        <SectionPersonAndPerson />
        <SectionCustomer />
      </main>
  
      <Footer />

      <MessageBox 
        opened={openErrorModal}
        title="Oops!"
        type="error"
        text={MESSAGE_INVALID_PARTNER}
        handleClose={() => setOpenErrorModal(false)}
        handlePrimary={() => setOpenErrorModal(false)}
        labelPrimary="Fechar"
        maxWidth="sm"
      />
    </>
  );
};

export default LandingPage;
