import React from 'react';
import { Box } from '@material-ui/core';
import { CREDITCARD_FINISH } from 'constants/textsSteps';
import CustomAppBar from 'components/CustomAppBar/CustomAppBar';
import CreditCardFallback from '../paymentComponents/CreditCardFallback';
import Finish from '../Finish';


const CreditCardFallBackStep = () => (
  <Box height={1} position="absolute" width={1} id="contract">
    <CustomAppBar>
    <Finish FINISH_TEXT={CREDITCARD_FINISH}>
      <CreditCardFallback />
    </Finish>
    <Box width={300} height={300} position="absolute" bottom={0} right={0} className="steps__finish-footer" />
    </CustomAppBar>
  </Box>
);;

export default CreditCardFallBackStep;