import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useAlert } from 'components/Alert/Alert';
import { MESSAGE_PAYMENT_LINK_UNAVAILABLE, MESSAGE_PAYMENT_NOT_READY } from 'constants/messages';
import useProposal from 'hooks/useProposal';
import usePayment from 'hooks/usePayment';
import { FETCH_STATUS } from 'constants/defaults';

const CreditCardFallback = () => {
  const { getProposalState } = useProposal();
  const { getPaymentState, getPaymentUrlByProposalSequencial } = usePayment();
  const { addMsgDanger, addMsgWarning } = useAlert();

  const handlePaymentLink = async () => {
    const getPaymentUrlResponse = await getPaymentUrlByProposalSequencial(getProposalState?.sequencial);

    if (getPaymentUrlResponse.meta.requestStatus === FETCH_STATUS.rejected)
    {
      addMsgDanger(MESSAGE_PAYMENT_LINK_UNAVAILABLE);
      return;
    };

    if (!getPaymentUrlResponse.payload.paymentUrl)
      addMsgWarning(MESSAGE_PAYMENT_NOT_READY);
  };

  useEffect(() => {
    if (!getPaymentState?.url)
      return;

    window.open(getPaymentState?.url, '_blank');
  }, [getPaymentState?.url]);

  return (
    <>
      <Typography variant="body1">
        Enviamos para seu e-mail um link para finalizar o pagamento, você também pode acessá-lo {' '}
        <Typography
          data-testid='button-paymentLink'
          className="steps__finish-main__link"
          variant="body1"
          component="span"
          onClick={handlePaymentLink}
        >
          clicando aqui.
        </Typography>{' '}
      </Typography>

      <Typography variant="body1">
        É importante que você realize o pagamento em no máximo 48 horas, depois deste período o link não será mais válido.
      </Typography>
      <br />
    </>
  );
};

export default CreditCardFallback;
