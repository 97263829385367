import React, { useState, useEffect, useCallback, useRef } from 'react';
import EventEmitter from 'events';
import Lottie from 'react-lottie';
import PropTypes from 'prop-types';
import { Box, Grid, Card, Typography, CardContent } from '@material-ui/core';
import { useSpring, animated } from 'react-spring';
import animation from './animation.json';

const emitter = new EventEmitter();

const LOADER_DEFINE_CUSTOM_MESSAGE = 'loader.defineCustomMessage';
const LOADER_SHOW = 'loader.show';
const LOADER_HIDE = 'loader.hide';
const LOADER_ENABLE = 'loader.enable';
const LOADER_DISABLE = 'loader.disable';

const DELAY = 2;

const Loader = ({ show }) => {
  const timeoutId = useRef(null);

  const [showByEvent, setShowByEvent] = useState(false);
  const [message, setMessage] = useState('Carregando...');
  const [customMessage, setCustomMessage] = useState('');
  const props = useSpring({ opacity: 1, from: { opacity: 0 } });

  const reset = () => {
    setCustomMessage('');
    setMessage('Carregando...');
    if (timeoutId.current) clearTimeout(timeoutId.current);
  };

  const setDelayedMessage = () => {
    setMessage(
      <div style={{ fontSize: '15px', whiteSpace: 'pre-line' }}>
        {customMessage.length > 0 ? customMessage : 'Aguarde mais um pouquinho,\nestamos carregando...'}
      </div>
    );
  };

  useEffect(() => {
    const defineCustomMessage = (msg) => setCustomMessage(msg);
    const showLoader = () => setShowByEvent(true);
    const hideLoader = () => setShowByEvent(false);

    emitter.on(LOADER_DEFINE_CUSTOM_MESSAGE, defineCustomMessage);
    emitter.on(LOADER_SHOW, showLoader);
    emitter.on(LOADER_HIDE, hideLoader);

    return () => {
      emitter.off(LOADER_DEFINE_CUSTOM_MESSAGE, defineCustomMessage);
      emitter.off(LOADER_SHOW, showLoader);
      emitter.off(LOADER_HIDE, hideLoader);
    };
  }, []);

  useEffect(() => {
    if (!show && !showByEvent) {
      reset();
    }

    if (show || showByEvent) {
      timeoutId.current = setTimeout(() => setDelayedMessage(), DELAY * 1000);
    }
  }, [show, showByEvent]);

  return (
    <>
      {(show || showByEvent) && (
        <animated.div style={props} className="loader__root">
          <Card>
            <CardContent>
              <Box p={1}>
                <Grid container spacing={1} justifyContent="center" direction="column" alignItems="center">
                  <Grid item>
                    <Box className="loader__svg-container">
                      <Lottie options={{ animationData: animation }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography align="center" variant="h6">
                      {message}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </animated.div>
      )}
    </>
  );
};

export const useLoader = () => {
  const defineCustomMessage = useCallback((msg) => emitter.emit(LOADER_DEFINE_CUSTOM_MESSAGE, msg), []);
  const enableLoader = useCallback(() => emitter.emit(LOADER_ENABLE), []);
  const disableLoader = useCallback(() => emitter.emit(LOADER_DISABLE), []);
  const showLoader = useCallback(() => emitter.emit(LOADER_SHOW), []);
  const hideLoader = useCallback(() => emitter.emit(LOADER_HIDE), []);

  return {
    defineCustomMessage,
    enableLoader,
    disableLoader,
    showLoader,
    hideLoader,
  };
};

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default Loader;
