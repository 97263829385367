import React from 'react';
import useBroker from 'hooks/useBroker';
import usePartner from 'hooks/usePartner';
import useGlobal from 'hooks/useGlobal';
import { Link } from 'react-router-dom';
import { ROUTES } from 'constants/defaults';
import { GLOBALS } from '../../../../constants/texts';

const ContractLink = () => {
  const { searchParams } = useGlobal();
  const { getBroker } = useBroker();
  const { disableContractLink } = usePartner();

  return (
    <Link
      className={`button button-orange ${disableContractLink() ? 'disabled': ''}`}
      to={`${getBroker().URL_PATH}/${ROUTES.Contratacao + searchParams}`}
      title="Personalize seu seguro"
      aria-label="Personalize seu seguro"
      id="residencial-site-personalize-seguro-botao"
    >
      {GLOBALS.CUSTOMIZE_YOUR_INSURANCE}
    </Link>
  );
};


export default ContractLink;
