import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Box, Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { modalStyles } from 'constants/themedStyles';

const PromotionalModal = ({ title, text, logo, dataTestId, maxWidth }) => {
  const classes = modalStyles();
  const [opened, setOpened] = useState(false);

  const handleClose = () => {
    setOpened(false);
  };

  useEffect(() => {
    setOpened(true);
  }, []);

  return (
    <Dialog
      open={opened}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      disableEscapeKeyDown
      fullWidth
      className={classes.border}
      maxWidth={maxWidth}
    >
      <DialogContent className={classes.modal} data-testid={dataTestId}>
        <CloseIcon title="Fechar" onClick={handleClose} className={classes.close} />

        {logo && (
          <Box mt={2} mb={3} align="center">
            <img src={logo} alt="promotional logo" className={classes.thumb} />
          </Box>
        )}

        {title && (
          <Box mt={2} mb={3}>
            <Typography align="center" variant="h5">
              {title}
            </Typography>
          </Box>
        )}

        {text && (
          <Box mt={2} mb={3} align="center">
            <Typography align="center">{text}</Typography>
          </Box>
        )}

        <Grid item pb={3} container justifyContent="center" spacing={2}>
          <Grid item sm={8} xs={12}>
            <Button
              color="primary"
              variant="contained"
              className={classes.btn}
              onClick={handleClose}
              title='Fechar'
            >
              Fechar
            </Button>
          </Grid>
        </Grid>

      </DialogContent>
    </Dialog>
  );
};

PromotionalModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  logo: PropTypes.any,
  dataTestId: PropTypes.string,
  maxWidth: PropTypes.string,
};

PromotionalModal.defaultProps = {
  title: '',
  text: '',
  logo: null,
  dataTestId: '',
  maxWidth: 'xs',
};

export default PromotionalModal;