import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid } from '@material-ui/core';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import wnd1 from 'assets/img/home/SectionWhoNeverDo/who-never-do-1.png';
import wnd2 from 'assets/img/home/SectionWhoNeverDo/who-never-do-2.png';
import icoGlass from 'assets/img/home/SectionWhoNeverDo/ico-glass.svg';
import icoKey from 'assets/img/home/SectionWhoNeverDo/ico-key.svg';
import { SECTION_WHO_NEVER_DO } from '../../../../../constants/texts';
import ContractLink from '../../globals/ContractLink';

const SectionWhoNeverDo = () => {
  const [text, setText] = useState('');
  const [ico, setIco] = useState('');
  const [textIHave, setTextIHave] = useState('');
  const sliderArrow = useRef(null);

  const renderArrow = (dots) => (
    <div className="navigation">
      <button className="arrow arrowPrev" type="button" onClick={() => sliderArrow.current.slickPrev()}>
        {'<'}
      </button>

      {dots}

      <button className="arrow arrowNext" type="button" onClick={() => sliderArrow.current.slickNext()}>
        {'>'}
      </button>
    </div>
  );

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => renderArrow(dots),
  };

  const handleChange = (index) => {
    if (index === 0) {
      setText(SECTION_WHO_NEVER_DO.LOST_KEY_DESC);
      setIco(icoKey);
      setTextIHave(SECTION_WHO_NEVER_DO.I_LOST_KEY_DESC);
      return;
    }
    setIco(icoGlass);
    setText(SECTION_WHO_NEVER_DO.GLASS_DESC);
    setTextIHave(SECTION_WHO_NEVER_DO.I_GLASS_DESC);
  };

  useEffect(() => {
    setText(SECTION_WHO_NEVER_DO.LOST_KEY_DESC);
    setIco(icoKey);
    setTextIHave(SECTION_WHO_NEVER_DO.I_LOST_KEY_DESC);
  }, []);

  return (
    <section className="SectionWND" id="section-who-never-do">
      <div className="Container">
        <div className="TitleDiv">
          <h2 className="TitleWND title-section-global">
            <div>{SECTION_WHO_NEVER_DO.TITLE}</div>
            <hr />
          </h2>
          <span className="SubtitleWND whiteSpace">{SECTION_WHO_NEVER_DO.SUBTITLE}</span>
        </div>
        <Box my={6.75}>
          <Grid container>
            <Grid item xs={12} lg={2} />
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Box className="SliderWND">
                <Slider ref={sliderArrow} afterChange={handleChange} {...settingsSlider}>
                  <Box className="BoxImg" justifyContent="center">
                    <img src={wnd1} alt="Quadrinho 1 - Quem nunca - Pottencial" />
                  </Box>
                  <Box className="BoxImg" justifyContent="center">
                    <img src={wnd2} alt="Quadrinho 2 - Quem nunca - Pottencial" />
                  </Box>
                </Slider>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Box className="DescriptionsWND">
                <Box>
                  <span className="title">{SECTION_WHO_NEVER_DO.SUBTITLE_WND}</span>
                  <p className="text">{text}</p>
                  <Box mt={3}>
                    <img src={ico} alt="Vidraceiro - Pottencial" />
                  </Box>
                </Box>
                <Box pt={6.75}>
                  <span className="title">{SECTION_WHO_NEVER_DO.SUBTITLE_I_HAVE}</span>
                  <p className="text">{textIHave}</p>
                  <Box sx={{ marginTop: '4.7rem' }}>
                    <Box display="flex" flexWrap="wrap" className="wrapper-cta">
                      <ContractLink />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={2} />
          </Grid>
        </Box>
      </div>
    </section>
  );
};
export default SectionWhoNeverDo;
