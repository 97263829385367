import { createAction } from "@reduxjs/toolkit";

export const setSlide = createAction('requestActions/SET_SLIDE');

export const setTermServiceOpen = createAction('requestActions/SET_TERM_SERVICE_OPEN');

export const setStep = createAction('requestActions/SET_STEP');

export const setAddressDisabled = createAction('requestActions/SET_ADDRESS_ENABLED');

export const setFinishing = createAction('requestActions/SET_FINISHING');

export const setReset = createAction('requestActions/SET_RESET');

export const setResetFinish = createAction('requestActions/SET_RESET_FINISH');

export const setContract = createAction('requestActions/SET_CONTRACT');

export const setParticipant = createAction('requestActions/SET_PARTICIPANT');

export const setRisk = createAction('requestActions/SET_RISK');

export const setAddress = createAction('requestActions/SET_ADDRESS');

export const trustServiceAgreement = createAction('requestActions/TRUST_SERVICE_AGREEMENT');

export const trustServiceCommunications = createAction('requestActions/TRUST_SERVICE_COMMUNICATIONS');

export const updateCoverageValue = createAction('requestActions/UPDATE_COVERAGE_VALUE');

export const setPayment = createAction('requestActions/SET_PAYMENT');

export const setPartner = createAction('requestActions/SET_PARTNER');
