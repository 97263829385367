import * as Yup from 'yup';

const YUP_MESSAGE = {
  email: 'E-mail inválido.',
  invalid: 'Valor inválido.',
  required: 'Campo obrigatório.',
  term: 'Aceite os termos para continuar.',
  min: ({ min }) => `Pelo menos ${min} caracteres são necessários.`,
  max: ({ max }) => `Insira no máximo ${max} caracteres`,
};

Yup.setLocale({
  mixed: {
    default: YUP_MESSAGE.invalid,
    notType: YUP_MESSAGE.invalid,
    required: YUP_MESSAGE.required,
  },
  number: {
    integer: YUP_MESSAGE.invalid,
  },
  string: {
    min: YUP_MESSAGE.min,
    max: YUP_MESSAGE.max,
    email: YUP_MESSAGE.email,
  },
});

export default YUP_MESSAGE;
