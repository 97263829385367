import React from 'react';
import PropTypes from 'prop-types';

import { Box, Checkbox, Typography, FormControlLabel } from '@material-ui/core';
import useTrustService from 'hooks/useTrustService';

const CommunicationsCheck = ({ parentFormik }) => {
  const { trustServiceCommunications } = useTrustService();

  const handleTrustedCommunicationsPottencial = (event) => {
    
    if (parentFormik.values.trustedCommunicationsPottencial) {
      parentFormik.setFieldValue('trustedCommunicationsPottencial', false);
      trustServiceCommunications(false);
      return;
    }
    parentFormik.setFieldValue('trustedCommunicationsPottencial', event.target.checked);
    trustServiceCommunications(event.target.checked);
  };

  return (
    <>
      <Box>
        <FormControlLabel
          style={{ marginRight: 0 }}
          control={
            <Checkbox
              color="primary"
              id="trustedCommunicationsPottencial"
              name="trustedCommunicationsPottencial"
              inputProps={{
                'data-testid': 'input-communications-introductoryStep',
              }}
              checked={parentFormik.values.trustedCommunicationsPottencial}
              onClick={handleTrustedCommunicationsPottencial}
            />
          }
          label={
            <Box>
              <Typography variant="caption">Aceito receber novidade da Pottencial via WhatsApp.</Typography>
            </Box>
          }
        />
      </Box>
    </>
  );
}

CommunicationsCheck.propTypes = {
  parentFormik: PropTypes.object.isRequired,
};

export default CommunicationsCheck;
