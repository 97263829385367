import {
  iconRC,
  iconFarm,
  iconApto,
  iconWood,
  iconLive,
  iconPgto,
  iconHouse,
  iconTelha,
  iconEmpty,
  iconGlass,
  iconTheft,
  iconStove,
  iconFireLp,
  iconBarCode,
  iconReparo,
  iconMasonry,
  iconVacancy,
  iconVehicle,
  iconRupture,
  iconSmokeLp,
  iconEletric,
  iconLimpeza,
  iconChaveiro,
  iconAircraft,
  iconCardCredit,
  iconEncanador,
  iconPowerPlug,
  iconHouseCond,
  iconLimpezaAr,
  iconHurricane,
  iconVidraceiro,
  iconEletricista,
  iconServicosGerais,
  iconTubulacaoEsgoto,
  iconCoberturaTelhado,
} from './images';
import { TYPE_IS_HOUSES_KEYS, TYPE_HOUSES_KEYS, TYPE_MAKE_HOUSES_KEYS, TYPE_PAYMENT } from './defaults';

export const TYPE_HOUSES = [
  {
    key: TYPE_HOUSES_KEYS.house.key,
    title: 'Casa',
    ico: iconHouse,
  },
  {
    key: TYPE_HOUSES_KEYS.condominiumHouse.key,
    title: 'Casa em condomínio',
    ico: iconHouseCond,
  },
  {
    key: TYPE_HOUSES_KEYS.apartment.key,
    title: 'Apartamento',
    ico: iconApto,
  },
  {
    key: TYPE_HOUSES_KEYS.countrySideHouse.key,
    title: 'Fazenda ou chácara',
    ico: iconFarm,
  },
];

export const TYPE_MAKE_HOUSES = [
  {
    key: TYPE_MAKE_HOUSES_KEYS.brick.key,
    title: 'Alvenaria',
    ico: iconMasonry,
  },
  {
    key: TYPE_MAKE_HOUSES_KEYS.wood.key,
    title: 'Madeira',
    ico: iconWood,
  },
];

export const TYPE_IS_HOUSES = [
  {
    key: TYPE_IS_HOUSES_KEYS.usual.key,
    title: 'Onde moro',
    ico: iconLive,
  },
  {
    key: TYPE_IS_HOUSES_KEYS.summer.key,
    title: 'Onde tiro férias',
    ico: iconVacancy,
  },
  {
    key: TYPE_IS_HOUSES_KEYS.vacate.key,
    title: 'Desocupada',
    ico: iconEmpty,
  },
];

export const ICONS_COVERAGE_ADDITIONAL = [
  {
    key: 'coverage-vehicle-impact',
    icon: iconVehicle,
  },
  {
    key: 'coverage-hurricane',
    icon: iconHurricane,
  },
  {
    key: 'coverage-rent-payment-loss',
    icon: iconPgto,
  },
  {
    key: 'coverage-glass',
    icon: iconGlass,
  },
  {
    key: 'coverage-theft',
    icon: iconTheft,
  },
  {
    key: 'coverage-civil-responsibility',
    icon: iconRC,
  },
  {
    key: 'coverage-pipe-rupture',
    icon: iconRupture,
  },
  {
    key: 'coverage-electrical-damage',
    icon: iconEletric,
  },
];

export const TYPE_PAYMENTS = [
  {
    title: 'Boleto',
    icon: iconBarCode,
    key: TYPE_PAYMENT.billet.key,
  },
  {
    title: 'Cartão de Crédito',
    icon: iconCardCredit,
    key: TYPE_PAYMENT.credit.key,
  },
];

export const ESSENCIALS_COVERAGES = [
  {
    ico: iconChaveiro,
    title: 'Chaveiro',
  },
  {
    ico: iconCoberturaTelhado,
    title: 'Cobertura provisória de telhados',
  },
  {
    ico: iconTubulacaoEsgoto,
    title: 'Desentupimento de tubulação de esgoto e caixa de gordura',
  },
  {
    ico: iconEletricista,
    title: 'Eletricista',
  },
  {
    ico: iconEncanador,
    title: 'Encanador',
  },
  {
    ico: iconLimpeza,
    title: 'Limpeza de calhas',
  },
  {
    ico: iconLimpezaAr,
    title: 'Limpeza de ar-condicionado',
  },
  {
    ico: iconReparo,
    title: 'Reparo de ar-condicionado',
  },
  {
    ico: iconServicosGerais,
    title: 'Serviços gerais',
  },
  {
    ico: iconTelha,
    title: 'Substituição de telhas',
  },
  {
    ico: iconVidraceiro,
    title: 'Vidraceiro',
  },
];

export const coveragesAdditional = [
  {
    id: 1,
    imgUrl: iconEletric,
    title: 'Danos elétricos',
    customWidth: 70,
    itens: [
      {
        item: 'Se acontecer um curto-circuito na sua casa queimando seus eletrodomésticos, aparelhos eletrônicos e instalações?  \n\nCaso isso aconteça a gente te reembolsa o valor, levando em conta o prejuízo.',
      },
    ],
  },
  {
    id: 2,
    imgUrl: iconHurricane,
    title: 'Desastres naturais',
    customWidth: 70,
    itens: [
      {
        item: 'Iihh! Caiu aquele temporal assustador, ventos, relâmpagos e granizos? \n\nSuas telhas voaram, caiu uma árvore no seu telhado?😱  \n\nNão se preocupe!  \nCom esta cobertura você estará amparado em caso de vendaval, furacão, ciclone, tornado, granizo e geada.',
      },
    ],
  },
  {
    id: 3,
    imgUrl: iconVehicle,
    title: 'Impacto de veículos',
    customWidth: 70,
    itens: [
      {
        item: 'Já pensou se um veículo derrubar o muro da sua casa, ou pior, uma carroceria desprender do veículo e bater no seu portão?  \n\nFica tranquilo que  você aproveita o valor contratado nesta cobertura pra te tirar do sufoco.',
      },
    ],
  },
  {
    id: 4,
    imgUrl: iconPgto,
    title: 'Perda ou pagamento de aluguel',
    itens: [
      {
        item: 'Seu imóvel pegou fogo ou foi atingido por um raio?  \n\nNão se preocupe! \nSe você contratar esta cobertura, nós garantimos durante o período de reparo ou reconstrução, por até 12 meses o valor do seu aluguel.  \n\nE caso contrate a cobertura de Vendaval ou a de Impacto de Veículos, você também estará protegido.',
      },
    ],
  },
  {
    id: 5,
    imgUrl: iconGlass,
    title: 'Quebra de vidros',
    itens: [
      {
        item: 'Ih, quebrou!?  \n\nSe for por qualquer motivo externo, um acidente, um descuido ou passou um fantasma e quebrou seu espelho?  \n\nA gente paga o valor da indenização definida aqui por você.',
      },
    ],
  },
  {
    id: 6,
    imgUrl: iconRC,
    title: 'RC familiar',
    itens: [
      {
        item: 'Ixiii!! \nSeu filho chutou a bola na janela do vizinho ou seu simpático cão, Godofredo, mordeu um convidado?  \n\nRelaxa!! Nós cobrimos os custos para tirá-lo deste problemão.',
      },
    ],
  },
  {
    id: 7,
    imgUrl: iconTheft,
    title: 'Roubo',
    itens: [
      {
        item: 'Sua casa está linda, cheia de coisas: móveis, roupas, eletrodomésticos.  \n\nVocê saiu para passear e ela foi invadida, e alguma coisa foi roubada?  \n\nQue situação, hein! \nSabemos como isto é chato, neste caso nós cobrimos o seu prejuízo.',
      },
    ],
  },
  {
    id: 8,
    imgUrl: iconRupture,
    title: 'Ruptura de tubulações',
    itens: [
      {
        item: 'Já pensou se o cano da sua pia estourar ou sua caixa d`água romper?  \n\nNada de desespero! \nSe houver danos súbitos ou acidentais pela água, com esta cobertura você estará protegido.',
      },
    ],
  },
];

export const coveragesBasic = [
  {
    key: 'fire',
    imgUrl: iconFireLp,
    title: 'Incêndio',
    secundaryTitle: 'Incêndio',
    alt: 'Ícone fogo - Pottencial',
    caseOf: true,
    itens: [
      {
        item: 'Um ferro esquecido sobre a mesa, uma lâmpada de Natal com defeito ou sua tentativa de fritar algo, podem causar um incêndio.  \n\nNão se preocupe! \nNós cobrimos estes prejuízos para você.',
      },
    ],
  },
  {
    key: 'stove',
    imgUrl: iconStove,
    title: 'Explosão ou implosão',
    secundaryTitle: 'Explosão',
    alt: 'Ícone fogão explodindo - Pottencial',
    caseOf: true,
    itens: [
      {
        item: 'Ter uma panela de pressão na sua cozinha é uma mão na roda, você não acha?! \n\nMas já parou para pensar que este item que tanto nos ajuda, quando mal utilizado pode virar uma bomba? \nEla pode provocar uma explosão grave e muitos danos à sua cozinha. \n\nCom o Pottencial Residencial este e outros imprevistos, estão garantidos na cobertura básica.',
      },
    ],
  },
  {
    key: 'smoke',
    imgUrl: iconSmokeLp,
    title: 'Fumaça',
    secundaryTitle: 'Fumaça',
    alt: 'Ícone fumaça - Pottencial',
    caseOf: true,
    itens: [
      {
        item: ' Lar doce lar!! \n\nSabe aqueles imprevistos que tiram o seu sossego só de pensar? \n\nO imóvel do vizinho pegou fogo e a fumaça invadiu seu imóvel, deixando uma fuligem impregnada na sua casa ou apê?? \n\nFique tranquilo! \nNós resolvemos isso para você.',
      },
    ],
  },
  {
    key: 'rain',
    imgUrl: iconPowerPlug,
    title: 'Queda de raios',
    secundaryTitle: 'Raios',
    alt: 'Ícone tomada em curto-circuito - Pottencial',
    caseOf: false,
    itens: [
      {
        item: 'Nós sabemos que não dá para medir forças com a natureza, não é mesmo? \nSe um raio atingir a sua casa e trazer danos ao seu imóvel... \n\nFique tranquilo! \nNós cobrimos este prejuízo para você!',
      },
    ],
  },
  {
    key: 'aircraft',
    imgUrl: iconAircraft,
    title: 'Queda de aeronave',
    secundaryTitle: 'Aeronaves',
    alt: 'Ícone queda de aeronave - Pottencial',
    caseOf: false,
    itens: [
      {
        item: 'Sabemos que imprevistos acontecem, até os mais incomuns... \n\nSe aquela aeronave ou helicóptero cair e atingir o seu imóvel, calma! \nVocê não vai ficar na mão. \n\nNós garantimos os bens e os danos causados ao seu lar.',
      },
    ],
  },
];

export const PARTNER_ROLES = {
  commercialAgent: 'commercialAgent',
  Advisor: 'Advisor'
};
