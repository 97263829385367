import React from 'react';
import PropTypes from 'prop-types';

const Arrow = (props) => {
  const { className, onClick, Component } = props;

  return <Component color="primary" className={className} onClick={onClick} />;
};

Arrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  Component: PropTypes.object.isRequired,
};

Arrow.defaultProps = {
  className: '',
  onClick: () => {},
};

export default Arrow;
