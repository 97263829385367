import React, { useState, useEffect, FocusEventHandler, ChangeEventHandler } from 'react';
import InputMask from 'react-input-mask';
import useUtils from 'hooks/useUtils';
import { TextField } from '@material-ui/core';
import { CELLPHONE_SIZE_WITHOUT_MASK, PHONE_SIZE_WITHOUT_MASK, PHONE_MASKS } from '../../constants/defaults';

const PhoneInput = (props: any) => {
  const { getOnlyNumber } = useUtils();
  const { value, id, onChange, onBlur } = props;
  const [mask, setMask] = useState('');

  useEffect(() => {
    const number = getOnlyNumber(value);

    if (number.length === 0) setMask(PHONE_MASKS.default);
    else if (number.length <= PHONE_SIZE_WITHOUT_MASK) {
      setMask(PHONE_MASKS.auto);
    } else if (number.length === CELLPHONE_SIZE_WITHOUT_MASK) {
      setMask(PHONE_MASKS.cellphone);
    }
  }, [value, getOnlyNumber]);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const onlyNumbers = getOnlyNumber(event.target.value);

    onBlur({
      target: { id, value: onlyNumbers },
    });
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const onlyNumbers = getOnlyNumber(event.target.value);

    onChange({
      target: { id, value: onlyNumbers },
    });
  };

  return (
    <InputMask {...props} mask={mask} maskChar={null} onChange={handleChange} onBlur={handleBlur}>
      {(inputProps: any) => <TextField {...inputProps} />}
    </InputMask>
  );
};

export default PhoneInput;
