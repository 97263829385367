import { useMemo } from 'react';
import useUtils from 'hooks/useUtils';
import { BASIC_COVERAGE } from 'constants/textsSteps';
import useCoverages from 'hooks/useCoverages';
import StepText from '../shared/StepText';

const BasicCoverageStepText = () => {
  const { getBasicCoverage } = useCoverages();
  const { formatCurrency } = useUtils();
  const { textHeader, textHeaderMobile, textDescription, textBody } = BASIC_COVERAGE;

  const insuredAmount = useMemo(() => getBasicCoverage?.coverage?.insuredAmount || {}, [getBasicCoverage]);

  const isMobile = window.innerWidth <= 1280; 

  const texts = [
    (isMobile ? textHeaderMobile : textHeader),
    textDescription(formatCurrency(insuredAmount?.min?.value || ''), formatCurrency(insuredAmount?.max?.value || '')),
    textBody
  ];

  return (
    <>
      <StepText textArray={texts} />
    </>
  );
};

export default BasicCoverageStepText;
