export const GLOBALS = {
  CUSTOMIZE_YOUR_INSURANCE: 'Personalize seu seguro',

  HEADER: {
    MENU: 'Menu',
    START: 'Início',
    ASSISTANCE: 'Assistência',
    COVERAGES: 'Coberturas',
    CUSTOMER_AREA: 'Àrea do Cliente',
    BE_OUR_BROKER: 'SEJA NOSSO CORRETOR',
    LOGIN: 'Login',

    REGISTER: {
      TITLE: 'Deseja fazer login como?',
      PERSON: 'Pessoa Física',
      COMPANY: 'Pessoa Jurídica',
      BROKER: 'Corretor',
      NOT_POTTENCIAL_YET: 'Ainda não é Pottencial?',
      REGISTER: 'Cadastre-se',
    },
  },

  FOOTER: {
    MENU_FOOTER_POTTENCIAL: {
      ABOUT_US: 'Sobre Nós',
      TITLE: 'A POTTENCIAL',
      WORK_WITH_US: 'Trabalhe Conosco',
      LEGAL_CERTIFICATES: 'Certidões Legais',
      OMBUDSMAN: 'Ouvidoria',
      TRANSPARENCY_AND_PRIVACY: 'Transparência e Privacidade',
      COOKIES_PREFERENCES: 'Preferências de Cookies',
    },

    MENU_FOOTER_RESOURCES: {
      TITLE: 'RECURSOS',
      CONSULT_POLICY: 'Consultar Apólice',
      BLOG: 'Blog',
      INSURANCE_GLOSSARY: 'Glossário de Seguros',
      SINISTER: 'Sinistro',
      MANIFESTO_AGIL: 'Manifesto Ágil',
      DEVELOPER_PORTAL: 'Portal do Desenvolvedor',
    },

    MENU_FOOTER_CHANNELS: {
      TITLE: 'CANAIS',
      CONTACT_US: 'Fale Conosco',
      COMPLAINT_CHANNEL: 'Canal de Denúncias',
    },

    MENU_FOOTER_SAC: {
      TITLE: 'SAC',
      PHONE_NUMBER: '(31) 2121-7777',
      PHONE_NUMBER_0800: '0800 606 7688',
    },

    COPYRIGHT: {
      INFO_FOOTER:
        'Pottencial Seguradora S.A. – CNPJ: 11.699.534/0001-74 – SUSEP: 03069 © Pottencial Seguradora, 2021. Todos os direitos reservados',
      ADDRESS: 'Av. Raja Gabáglia, 1143, 19°andar - Luxemburgo, Belo Horizonte - MG',
    },
  },
};

export const SECTION_BANNER = {
  TITLE: 'Seu lar é sua cara, \nseu seguro também!',
  SUBTITLE: 'Fique mais tranquilo para aproveitar os momentos em família, enquanto a gente protege o seu lar!',
};

export const SECTION_ASSISTANCE = {
  TITLE: 'Assistências',
  SUBTITLE:
    'Cuidar da casa e da família pode ser desafiador, mas você não está sozinho nessa. Nossas assistências são pensadas para você ficar tranquilo e cuidar de quem ama!',
  ASSISTANCE: 'ASSISTÊNCIA',
  TYPE_ASSISTANCE: {
    ESSENTIAL: 'Essencial',
  },
  FREE_PLAN: 'Plano essencial gratuito',
};

export const SECTION_COVERAGES = {
  TITLE: 'Coberturas',
  SUBTITLE:
    'Nossas coberturas foram criadas para que você não se preocupe com imprevistos que podem acontecer, como: roubo, furto, incêndio, explosão, danos elétricos, ruptura de tubulações e muitos outros! \n\nConheça nossas coberturas e personalize seu seguro.',

  COVERAGE: 'COBERTURA',
  TYPE_COVERAGE: {
    BASIC: 'Básica',
    ADDITIONAL: 'Adicionais',
  },
  BUTTON_COVERAGE_BASIC: 'Coberturas básicas',
  BUTTON_COVERAGE_ADD: 'Coberturas adicionais',
  PROTECTION_EXTRA_DAY_TO_DAY:
    'Ideias para quem quer uma proteção extra e ter ainda mais tranquilidade no seu dia a dia.',
  INCLUSION_ALL_CONTRACTS: 'Inclusão em todas as contratações',

  IN_CASE_OF: 'Em caso de',
  LEARN_MORE: 'Saiba mais',
};

export const SECTION_WHO_NEVER_DO = {
  TITLE: 'Quem nunca',
  SUBTITLE:
    'Passou por uma situação inesperada e precisou de uma ajudinha? Nós acreditamos que um seguro deve ser útil todos os dias e que deve ser usado de verdade!  \nVeja como nossas assistências são desenhadas para ajudar sempre que você  precisar.',
  SUBTITLE_WND: 'Quem nunca',
  SUBTITLE_I_HAVE: 'Eu já!',
  LOST_KEY_DESC: 'Perdeu a chave e ficou sem poder entrar em casa depois de um passeio?',
  GLASS_DESC: 'Deixou as crianças brincarem dentro de casa e a bola acertou a porta de vidro e a quebrou?',

  I_LOST_KEY_DESC:
    'Com o Pottencial Residencial você não precisa mais se preocupar! Você poderá contar com serviço de chaveiro gratuitamente 24 horas todos os dias da semana.',
  I_GLASS_DESC:
    'Com o Pottencial Residencial você não precisa mais se preocupar! Você poderá chamar um vidraceiro para resolver o problema.',
};

export const SECTION_PERSON_AND_PERSON = {
  TITLE: 'A Pottencial',
  SUBTITLE: 'Um produto feito por pessoas para pessoas.',
  DESCRIPTION:
    'Nossa paixão é criar produtos que os usuários adoram. \n\nNosso foco sempre foi a experiência do cliente, sendo ela simples de usar e sem letrinhas miúdas. \n\nTodos os Potters são engajados em proporcionar a melhor experiência para você!',
  DESCRIPTION_VALUE_TITLE: 'Por isso, nossos valores são:',
  OUR_VALUES: {
    VALUE_1: 'Foco no cliente',
    VALUE_2: 'Geração de valor',
    VALUE_3: 'Gente que abraça desafios',
    VALUE_4: 'Alta reputação',
    VALUE_5: 'Inovação',
  },
};

export const SECTION_CUSTOMER = {
  TITLE: 'Área do Cliente',
  DESCRIPTION: 'Encontre rapidamente o serviço que deseja.',
  MENU_ITEM: {
    ACTIVATE_INSURANCE: 'Acionar seguro',
    REQUEST_ASSISTANCE: 'Solicitar assistência',
    COPY_OF_BILL: '2ª via de boleto',
    CONSULT_POLICY: 'Consultar apólice',
    GENERAL_CONDITIONS: 'Condições gerais',
    COMMON_QUESTIONS: 'Perguntas frequentes',
  },

  POTTENCIAL_FOR_YOU: 'Pottencial Para Você',
  CLOSED_BUTTON: 'Fechar',
  SEND_EMAIL: 'Enviar E-mail',
  EMAIL_CHARGE: 'cobranca@pottencial.com.br.',
  EMAIL_SINISTER: 'sinistros.patrimoniais@pottencial.com.br.',

  REQUEST_ASSISTANCE_MODAL: {
    TITLE: 'Solicitar assistência',
    PARAGRAPH_1:
      'A Assistência 24h da Pottencial é um conjunto de serviços que você pode utilizar para deixar sua residência ainda mais tranquila!',
    PARAGRAPH_2:
      'O seu seguro Pottencial Residencial já conta com um pacote essencial e gratuito de assistências, que também pode ser incrementado para ficar ainda mais completo!',
    PARAGRAPH_3: 'Independente do plano, são autorizadas 04 intervenções durante a vigência do seguro.',
    PARAGRAPH_4: 'Caso precise acionar sua assistência, ligue para',
    CALL_NUMBER: '0800 776 4040.',
  },

  COPY_OF_BILL_MODAL: {
    TITLE: '2ª via de boleto',
    PARAGRAPH_1: 'Oie!! Precisa da segunda via do seu boleto?',
    PARAGRAPH_2: 'Fique tranquilo, estamos aqui para ajudá-lo.',
    PARAGRAPH_3: 'Nos envie uma solicitação informando o número da sua apólice ou proposta.',
    PARAGRAPH_4: 'Nosso e-mail é:',
  },

  ACTIVATE_INSURANCE_MODAL: {
    TITLE: 'Acionar seguro',
    PARAGRAPH_1: 'Sabemos da importância de um rápido atendimento no sinistro, e, por isto estamos aqui para ajudá-lo!',
    PARAGRAPH_2:
      'Nos envie por e-mail um descritivo com fotos, notas ou algum arquivo que nos ajude a entender melhor o acontecimento, assim agilizaremos o seu atendimento.',
    PARAGRAPH_3: 'Nosso e-mail é:',
  },
};
