import React, { useState } from 'react';
import { useInView } from 'react-hook-inview';
import iconAcionarSeguro from 'assets/img/home/SectionWhatYouNeed/icon-acionar-seguro.svg';
import iconConsultarApolice from 'assets/img/home/SectionWhatYouNeed/icon-consultar-apolice.svg';
import iconSolicitarAssistencia from 'assets/img/home/SectionWhatYouNeed/icon-solicitar-assistencia.svg';
import iconCondicoesGerais from 'assets/img/home/SectionWhatYouNeed/icon-condicoes-gerais.svg';
import iconBoleto from 'assets/img/home/SectionWhatYouNeed/icon-boleto.svg';
import iconFaq from 'assets/img/home/SectionWhatYouNeed/icon-faq.svg';
import useUtils from 'hooks/useUtils';
import { IconButton } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import SecureModal from './SecureModal';
import AssistModal from './AssistModal';
import BilletModal from './BilletModal';
import { SECTION_CUSTOMER } from '../../../../../constants/texts';

const SectionCustomer = () => {
  const [showSecureModal, setShowSecureModal] = useState(false);
  const [showAssistModal, setShowAssistModal] = useState(false);
  const [showBilletModal, setShowBilletModal] = useState(false);

  const { setMarkMenu } = useUtils();

  const [refCustomer] = useInView({
    threshold: 1,
    onEnter: () => {
      setMarkMenu('section-client');
    },
  });

  return (
    <section className="section-customer" id="section-client">
      <div className="container-customer">
        <div className="title-div" id="section-client-title" ref={refCustomer}>
          <h2 className="title-customer title-section-global">
            <div>{SECTION_CUSTOMER.TITLE}</div>
            <hr />
          </h2>

          <span className="description-customer">{SECTION_CUSTOMER.DESCRIPTION}</span>
        </div>

        <div className="box-item">
          <div className="wrapper">
            <div className="box">
              <div className="box-image">
                <img src={iconAcionarSeguro} alt="Ícone dedo apertando um botão - Pottencial" />
              </div>
              <div className="box-text">
                <button onClick={() => setShowSecureModal(true)} type="button">
                  {SECTION_CUSTOMER.MENU_ITEM.ACTIVATE_INSURANCE}
                </button>
              </div>
              <div>
                <IconButton size="small" className="arrow" onClick={() => setShowSecureModal(true)}>
                  <ArrowForward />
                </IconButton>
              </div>
            </div>

            <div className="box">
              <div className="box-image">
                <img src={iconConsultarApolice} alt="Ícone folha de papel e uma lupa - Pottencial" />
              </div>
              <a rel="noopener noreferrer" target="_blank" href={window.ENV.APOLICE_URL}>
                <div className="box-text">{SECTION_CUSTOMER.MENU_ITEM.CONSULT_POLICY}</div>
                <div>
                  <IconButton size="small" className="arrow">
                    <ArrowForward />
                  </IconButton>
                </div>
              </a>
            </div>

            <div className="box">
              <div className="box-image">
                <img src={iconSolicitarAssistencia} alt="Ícone chave combinada e chave de fenda - Pottencial" />
              </div>
              <div className="box-text">
                <button onClick={() => setShowAssistModal(true)} type="button">
                  {SECTION_CUSTOMER.MENU_ITEM.REQUEST_ASSISTANCE}
                </button>
              </div>
              <div>
                <IconButton size="small" className="arrow" onClick={() => setShowAssistModal(true)}>
                  <ArrowForward />
                </IconButton>
              </div>
            </div>

            <div className="box">
              <div className="box-image">
                <img src={iconCondicoesGerais} alt="Ícone folha de papel - Pottencial" />
              </div>
              <a rel="noopener noreferrer" target="_blank" href={window.ENV.DOCUMENT_CG_RESIDENCIAL}>
                <div className="box-text">{SECTION_CUSTOMER.MENU_ITEM.GENERAL_CONDITIONS}</div>
                <div>
                  <IconButton size="small" className="arrow">
                    <ArrowForward />
                  </IconButton>
                </div>
              </a>
            </div>

            <div className="box">
              <div className="box-image">
                <img src={iconBoleto} alt="Ícone código de barras - Pottencial" />
              </div>
              <div className="box-text">
                <button onClick={() => setShowBilletModal(true)} type="button">
                  {SECTION_CUSTOMER.MENU_ITEM.COPY_OF_BILL}
                </button>
              </div>
              <div>
                <IconButton size="small" className="arrow" onClick={() => setShowBilletModal(true)}>
                  <ArrowForward />
                </IconButton>
              </div>
            </div>

            <div className="box">
              <div className="box-image">
                <img src={iconFaq} alt="Ícone caixa de diálogo com ponto de interrogação - Pottencial" />
              </div>
              <a rel="noopener noreferrer" target="_blank" href={window.ENV.FAQ_RESIDENCIAL_URL}>
                <div className="box-text">{SECTION_CUSTOMER.MENU_ITEM.COMMON_QUESTIONS}</div>
                <div>
                  <IconButton size="small" className="arrow">
                    <ArrowForward />
                  </IconButton>
                </div>
              </a>
            </div>
          </div>

          <div className="box-line">
            <span className="line" />
          </div>

          <div className="box-name">
            <a
              href={window.ENV.CUSTOMER_URL}
              className="button button-orange"
              title="Pottencial Para Você"
              id="residencial-site-pottencial-para-voce-botao"
              aria-label="Pottencial Para Você"
              target="_blank"
              rel="noreferrer"
            >
              {SECTION_CUSTOMER.POTTENCIAL_FOR_YOU}
            </a>
          </div>
        </div>
      </div>

      <SecureModal show={showSecureModal} setSecureModal={setShowSecureModal} />

      <AssistModal show={showAssistModal} setAssistModal={setShowAssistModal} />

      <BilletModal show={showBilletModal} setBilletModal={setShowBilletModal} />
    </section>
  );
};

export default SectionCustomer;
