import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { HTTP_GENERIC_STATUS } from 'constants/defaults';
import {
  CreateHefestoRequest,
  CreatePersonRequest,
  CreateProposalRequest,
  CreateQuoteRequest,
  ElectronicAcceptRequest,
  UpdateQuoteRequest,
} from 'borders/dtos/request';
import {
  AddressResponse,
  CreatePersonResponse,
  CreateProposalResponse,
  CreateQuoteResponse,
  PaymentUrlResponse,
  PersonResponse,
  ProposalDocumentResponse,
  RestrictionZipCodeResponse,
  UpdateQuoteResponse,
} from 'borders/dtos/response';
import requestManager from './requestManager';

function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).response || (error as AxiosError).request;
}

type ErrorWithStatus = {
  status: number;
  data: Array<any>;
};

function isStatusError(error: unknown): error is ErrorWithStatus {
  return !!(error as ErrorWithStatus).status;
}

const handleReject = (error: unknown) => {
  if (isAxiosError(error) && error.response?.headers?.['x-powered-by'] === 'msw') {
    return { status: error.response?.status, errors: { ...error.response?.data } };
  }

  if (
    isStatusError(error) &&
    (Math.trunc(error.status / 100) === HTTP_GENERIC_STATUS.clientError ||
      Math.trunc(error.status / 100) === HTTP_GENERIC_STATUS.serverError)
  ) {
    return { status: error.status, errors: { ...error.data[0] } };
  }

  return error;
};

export const searchPersonByDocument = createAsyncThunk<PersonResponse, string>(
  'requestOperations/GET_PERSON_BY_DOCUMENT',
  async (documentNumber, { rejectWithValue }) => {
    try {
      const payload = { documentNumber };
      return await requestManager.searchPersonByDocumentNumber(payload);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const getAllCoverages = createAsyncThunk(
  'requestOperations/GET_ALL_COVERAGES',
  async (_, { rejectWithValue }) => {
    try {
      const basicCoverageTask = requestManager.getBasicCoverage();
      const additionalCoverageTask = requestManager.getAdditionalCoverages();
      return (await Promise.all([basicCoverageTask, additionalCoverageTask])).flat();
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const createQuote = createAsyncThunk<CreateQuoteResponse, CreateQuoteRequest>(
  'requestOperations/CREATE_QUOTE',
  async (quote, { rejectWithValue }) => {
    try {
      return await requestManager.createQuote(quote);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const updateQuote = createAsyncThunk<UpdateQuoteResponse, { id: string; quote: UpdateQuoteRequest }>(
  'requestOperations/UPDATE_QUOTE',
  async (quotePayload, { rejectWithValue }) => {
    try {
      const { id, quote } = quotePayload;
      return await requestManager.updateQuote(id, quote);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const createPerson = createAsyncThunk<CreatePersonResponse, CreatePersonRequest>(
  'requestOperations/CREATE_PERSON',
  async (person, { rejectWithValue }) => {
    try {
      return await requestManager.createPerson(person);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const createPersonHefesto = createAsyncThunk<any, CreateHefestoRequest>(
  'requestOperations/CREATE_PERSON_HEFESTO',
  async (person, { rejectWithValue }) => {
    try {
      return await requestManager.createHefesto(person);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const createProposal = createAsyncThunk<CreateProposalResponse, CreateProposalRequest>(
  'requestOperations/CREATE_PROPOSAL',
  async (proposal, { rejectWithValue }) => {
    try {
      return await requestManager.createProposal(proposal);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const acceptTerms = createAsyncThunk<unknown, ElectronicAcceptRequest>(
  'requestOperations/ACCEPT_TERMS',
  async (termAccept, { rejectWithValue }) => {
    try {
      return await requestManager.acceptTerms(termAccept);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const getQuotePDF = createAsyncThunk<unknown, string>(
  'requestOperations/DOWNLOAD_QUOTE',
  async (id, { rejectWithValue }) => {
    try {
      return await requestManager.getQuotePDF(id);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const getPaymentsUrl = createAsyncThunk<PaymentUrlResponse, string>(
  'requestOperations/GET_PAYMENTS_URL',
  async (sequencial, { rejectWithValue }) => {
    try {
      return await requestManager.getPaymentsUrl(sequencial);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const getProposalDocument = createAsyncThunk<ProposalDocumentResponse, string>(
  'requestOperations/GET_PROPOSAL_DOCUMENT_BY_ORDER_ID',
  async (id, { rejectWithValue }) => {
    try {
      return await requestManager.getProposalDocumentByOrderId(id);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const getAddressByZipCode = createAsyncThunk<AddressResponse, number>(
  'requestOperations/GET_ADDRESS_BY_ZIP_CODE',
  async (zipCode, { rejectWithValue }) => {
    try {
      return await requestManager.getAddressByZipCode(zipCode);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const getPersonById = createAsyncThunk<PersonResponse, string>(
  'requestOperations/GET_PERSON_BY_ID',
  async (personId, { rejectWithValue }) => {
    try {
      return await requestManager.getPersonById(personId);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);

export const getInsuredAmountRestrictionByZipCode = createAsyncThunk<RestrictionZipCodeResponse, number>(
  'requestOperations/GET_INSURED_AMOUNT_RESTRICTION_ZIP_CODE',
  async (zipCode, { rejectWithValue }) => {
    try {
      return await requestManager.getRestrictionInsuredAmountByZipCode(zipCode);
    } catch (error) {
      return rejectWithValue(handleReject(error));
    }
  }
);
