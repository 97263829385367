import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Grid, Slide, Divider, Typography, IconButton, LinearProgress, useMediaQuery } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import MessageBox from 'components/MessageBox/MessageBox';

import imgPatti from 'assets/img/contract/icon-patti-help-faq.png';
import useGlobal from 'hooks/useGlobal';
import useBroker from 'hooks/useBroker';
import { MESSAGE_BACK_INITIAL } from 'constants/messages';

import AboutYouStep from './aboutYouStep/AboutYouStep';
import AboutYouStepText from './aboutYouStep/AboutYouStepText';

import AboutPropertyStep from './aboutPropertyStep/AboutPropertyStep';
import AboutPropertyStepText from './aboutPropertyStep/AboutPropertyStepText';

import LocalizationStep from './localizationStep/LocalizationStep';
import LocalizationStepText from './localizationStep/LocalizationStepText';

import BasicCoverageStep from './basicCoverageValueStep/BasicCoverageStep';
import BasicCoverageStepText from './basicCoverageValueStep/BasicCoverageStepText';

import AdditionalCoverageStep from './additionalCoverageStep/AdditionalCoverageStep';
import AdditionalCoverageStepText from './additionalCoverageStep/AdditionalCoverageStepText';

import AssistanceStep from './assistanceStep/AssistanceStep';
import AssistanceStepText from './assistanceStep/AssistanceStepText';

import PaymentStep from './paymentStep/PaymentStep';
import PaymentStepText from './paymentStep/PaymentStepText';

import ValuesInfo from './shared/ValuesInfo';

const Steps = () => {
  const { 
    getSlideState, 
    getActiveStep, 
    setSlide, 
    setActiveStep, 
    setReset, 
    setResetFinish, 
    searchParams 
  } = useGlobal();
  const { getBroker } = useBroker();

  const [openBackInitial, setOpenBackInitial] = useState(false);

  const history = useHistory();

  const link = getBroker().URL_PATH === '' ? '/' : `${getBroker().URL_PATH}`;

  const scroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const stepsWithFooter = [3, 4, 5, 6, 7, 8];
  let showFooter = stepsWithFooter.includes(getActiveStep);
  
  const onBack = useCallback(() => {
    setSlide('right');

    if (getActiveStep > 0) {
      setActiveStep(getActiveStep - 1);
    }

    scroll();

    showFooter = stepsWithFooter.includes(getActiveStep - 1);

  }, [setActiveStep, getActiveStep]);

  const onNext = useCallback(() => {
    setSlide('left');

    if (getActiveStep > -1) {
      setActiveStep(getActiveStep + 1);
    }

    scroll();

    showFooter = stepsWithFooter.includes(getActiveStep + 1);

  }, [setActiveStep, getActiveStep]);

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isLgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  
  let marginX = 7.5;
  let marginColumn = 0;
  
  if(isXs)
  {
    marginX = 2;
  }

  if(isLgDown){
    marginColumn = 2;
  }

  const steps = [
    <AboutYouStep onBack={onBack} onNext={onNext} />,
    <AboutPropertyStep onBack={onBack} onNext={onNext} />,
    <LocalizationStep onBack={onBack} onNext={onNext} />,
    <BasicCoverageStep onBack={onBack} onNext={onNext} />,
    <AdditionalCoverageStep onBack={onBack} onNext={onNext} />,
    <AssistanceStep onBack={onBack} onNext={onNext} />,
    <PaymentStep onBack={onBack} onNext={onNext} />,
  ];

  const stepsTexts = [
    <AboutYouStepText />,
    <AboutPropertyStepText />,
    <LocalizationStepText />,
    <BasicCoverageStepText />,
    <AdditionalCoverageStepText />,
    <AssistanceStepText />,
    <PaymentStepText />,
  ];

  const multipleStep = 100 / steps.length;

  return (
    <>
      <MessageBox
        opened={openBackInitial}
        handleClose={() => setOpenBackInitial(false)}
        title="Voltar ao ínicio"
        text={MESSAGE_BACK_INITIAL}
        handleSecondary={() => setOpenBackInitial(false)}
        handlePrimary={() => {
          setResetFinish();
          setReset();
          history.push(link + searchParams);
        }}
        maxWidth="xs"
        type="warning"
      />
  <Grid container className={isMdDown && showFooter ? "steps__full-height steps__mobile-footer" : "steps__full-height"} >
    
        <Grid item xs={12} md={isMdDown ? 12 : 8} className={isMdDown && showFooter ? "step-space" : ""} >

          <Box className="steps__main-block" mx={marginX} my={2}>

            <Box
              ml={-2}
              height={85}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              className="steps__main-block__back-button"
              onClick={() => setOpenBackInitial(true)}
            >
              <IconButton color="primary">
                <ArrowLeftIcon />
              </IconButton>

              <Box mt={0.2}>
                <Typography align="center" variant="body2" color="primary">
                  <b>Pottencial Residencial</b>
                </Typography>
              </Box>
            </Box>
           
            <Box mb={4}>
              <Box display="flex" justifyContent="space-between"
                  alignItems={isLgDown ? 'flex-start' : 'center'}
                  flexDirection={isLgDown ? 'column' : 'row'}
              mb={1}>
                <Box  my={marginColumn}>
                  <Box>
                    <Typography variant="h5" color="textPrimary">
                      <b>Pottencial Para Você</b>
                    </Typography>
                  </Box>

                  {!isMdDown && (
                     <Divider className="steps__main-block__divider-title" />
                  )}
    
                </Box>

                <Box width={isMdDown ? '100%' : 500}>
                  <Box
                    display="flex"
                    alignItems="baseline"
                    justifyContent="flex-end"
                    mt={-2.5}
                    data-testid="box-stepnumber-steps"
                  >
                    <Typography variant="body1" color="primary">
                      <b>{getActiveStep + 1}</b>
                    </Typography>

                    <Typography variant="body2" color="textSecondary">
                      <b>/{steps.length}</b>
                    </Typography>
                  </Box>

                  <LinearProgress
                    variant="determinate"
                    style={{ borderRadius: 20 }}
                    value={(getActiveStep + 1) * multipleStep}
                  />
                </Box>
              </Box>

            </Box>

            <Box display="flex" alignItems="center" data-testid="box-texts-steps">

              <Grid container className='steps__flex-wrap'>

                <Grid item >
                  <Box mr={3}>
                    <img src={imgPatti} alt="Logo Patti" width={isXs ? 80 : 101}/>
                  </Box>
                </Grid>

                <Grid item  className="steps__flex-align-center">
                    <Box>
                      {stepsTexts.map((_, index) => (
                        <>{getActiveStep === index && stepsTexts[getActiveStep]}</>
                      ))}
                    </Box>
                </Grid>
      
              </Grid>

            </Box>

            <Box className="steps__box-main" data-testid="box-main-steps">
              <Box>
                {steps.map((_, index) => (
                  <>
                    {getActiveStep === index && (
                      <Slide in direction={getSlideState} mountOnEnter unmountOnExit>
                        <Box className="kekw">{steps[getActiveStep]}</Box>
                      </Slide>
                    )}
                  </>
                ))}
              </Box>
            </Box>
          </Box>
        </Grid>

        {!isMdDown && (
          <Grid item xs={12} md={4} className="steps__justify-center steps__values-info">
            <ValuesInfo />
          </Grid>
        )}

        {isMdDown && (showFooter) && (
              <Grid item xs={12} md={4} className="steps__justify-center steps__values-info steps__footer">
                <ValuesInfo />
              </Grid>
          )}
          
      </Grid>

    </>
  );
};

export default Steps;
