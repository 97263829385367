import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@material-ui/core';
import bgAssistance from 'assets/img/home/SectionWhatYouNeed/bg-assistance.png';
import SectionModal from './SectionModal';
import { SECTION_CUSTOMER } from '../../../../../constants/texts';

const AssistModal = ({ show, setAssistModal }) =>
  show && (
    <SectionModal open={show} image={bgAssistance} text="Solicitar assistência">
      <Box pr={5} className="box-side-text">
        <Box my={2.8}>
          <Typography component="span" color="primary" className="title">
            {SECTION_CUSTOMER.REQUEST_ASSISTANCE_MODAL.TITLE}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.REQUEST_ASSISTANCE_MODAL.PARAGRAPH_1}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.REQUEST_ASSISTANCE_MODAL.PARAGRAPH_2}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            {SECTION_CUSTOMER.REQUEST_ASSISTANCE_MODAL.PARAGRAPH_3}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography component="span" className="text">
            <Box display="flex" alignItems="center">
              {SECTION_CUSTOMER.REQUEST_ASSISTANCE_MODAL.PARAGRAPH_4}{' '}
              <a href="tel:08007764040" className="email" title="Fale por telefone" aria-label="Fale por telefone">
                {SECTION_CUSTOMER.REQUEST_ASSISTANCE_MODAL.CALL_NUMBER}
              </a>
            </Box>
          </Typography>
        </Box>

        <Box display="flex" justifyContent="flex-end" alignItems="center" mt={4}>
          <Button variant="contained" color="primary" onClick={() => setAssistModal(false)}>
            {SECTION_CUSTOMER.CLOSED_BUTTON}
          </Button>
        </Box>
      </Box>
    </SectionModal>
  );

AssistModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setAssistModal: PropTypes.func.isRequired,
};

export default AssistModal;
