import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Button, Typography, useMediaQuery } from '@material-ui/core';

import ModalInfo from 'components/ModalInfo/ModalInfo';
import { ABOUT_YOU } from 'constants/textsSteps';

const TermService = ({ open, setOpen, onAccept }) => {
  const { termService } = ABOUT_YOU;

  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <ModalInfo open={open} className="term-modal" onClose={() => setOpen(false)}>
      <Box p={isXs ? 1 : 6}>
        <Box overflow="auto">
          <Box mb={3}>
            <Typography variant="h5" color="primary">
              {termService.title}
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify" className="text-term">
              {termService.body[0]}
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify" className="text-term">
              {termService.body[1]} <b>POTTENCIAL</b> {termService.body[2]}
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify" className="text-term">
              A <b>POTTENCIAL</b> {termService.body[3]}{' '}
              <a
                className="link"
                rel="noopener noreferrer"
                target="_blank"
                href="https://pottencial.com.br/transparencia-e-privacidade/"
              >
                pottencial.com.br/transparencia-e-privacidade/
              </a>
              .
            </Typography>
          </Box>

          <Box mb={3}>
            <Typography variant="body2" align="justify" className="text-term">
              {termService.body[4]}{' '}
              <a className="link" href="mailto:privacidade@pottencial.com.br">
                privacidade@pottencial.com.br
              </a>
              .
            </Typography>
          </Box>
        </Box>

        <Box mt={3}>
          <Grid container spacing={4} className="flex-center">
            <Grid item xs={2}>
              <Button
                className="button-term"
                color="primary"
                title="Começar"
                variant="text"
                fullWidth
                onClick={() => setOpen(false)}
                data-testid="button-back-service-introductoryStep"
              >
                Voltar
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                className="button-term"
                color="primary"
                title="Começar"
                variant="contained"
                fullWidth
                id="residencial-site-termos-servico-aceito"
                onClick={onAccept}
              >
                Li e Aceito
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </ModalInfo>
  );
};

TermService.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default TermService;
