import { useDispatch, useSelector } from "react-redux";
import { getProposal } from "redux/requestSelectors";
import { requestOperations } from "../redux/index";

export default () => {
  const dispatch = useDispatch();

  const getProposalState = useSelector(getProposal);

  const createProposal = async (proposalPayload) => dispatch(requestOperations.createProposal(proposalPayload));

  const getProposalDocument = async (orderId) => dispatch(requestOperations.getProposalDocument(orderId));
  
  return {
    getProposalState,
    createProposal,
    getProposalDocument
  };
};
