import React from 'react';
import { Grid } from '@material-ui/core';
import equipe from 'assets/img/home/SectionPersonAndPerson/equipe.png';
import { SECTION_PERSON_AND_PERSON } from '../../../../../constants/texts';

const SectionPersonAndPerson = () => (
  <section className="SectionPersonAndPerson" id="section-person-and-person">
    <Grid container>
      <Grid item md={2} lg={1} />
      <Grid item md={4} xs={12} sm={12} lg={5}>
        <h2 className="TitlePersonAndPerson title-section-global">
          <div>{SECTION_PERSON_AND_PERSON.TITLE}</div>
          <hr />
        </h2>

        <h4 className="SubtitlelPersonAndPerson">{SECTION_PERSON_AND_PERSON.SUBTITLE}</h4>

        <div className="DescriptionPersonAndPerson whiteSpace">
          <p>{SECTION_PERSON_AND_PERSON.DESCRIPTION}</p>

          <p className="OurValuesPersonAndPerson">{SECTION_PERSON_AND_PERSON.DESCRIPTION_VALUE_TITLE}</p>
        </div>

        <div className="Numbers">
          <div className="ItemNumbers">
            <div className="ValuesColumum1">
              <li>{SECTION_PERSON_AND_PERSON.OUR_VALUES.VALUE_1}</li> <br />
              <li>{SECTION_PERSON_AND_PERSON.OUR_VALUES.VALUE_2}</li> <br />
              <li>{SECTION_PERSON_AND_PERSON.OUR_VALUES.VALUE_3}</li>
            </div>
          </div>
          <div className="ItemNumbers">
            <div className="ValuesColumum1">
              <li>{SECTION_PERSON_AND_PERSON.OUR_VALUES.VALUE_4}</li> <br />
              <li>{SECTION_PERSON_AND_PERSON.OUR_VALUES.VALUE_5}</li>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item md={5} xs={12} sm={12} lg={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className="ImagesDiv">
          <div className="banner">
            <img src={equipe} alt="Pessoas do time da Pottencial" />
          </div>
        </div>
      </Grid>

      <Grid item md={1} lg={1} />
    </Grid>
  </section>
);

export default SectionPersonAndPerson;
