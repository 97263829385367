import iconFacebook from 'assets/img/general/Footer/icon-facebook.svg';
import iconInstagram from 'assets/img/general/Footer/icon-instagram.svg';
import iconLinkedin from 'assets/img/general/Footer/icon-linkedin.svg';
import iconPhone from 'assets/img/general/footer-phone.svg';
import iconTwitter from 'assets/img/general/Footer/icon-twitter.svg';

export const DEFAULT_THEME = {
  id: 'default',
  primary: '#F26522',
};

export const DEFAULT_ID = 'global';

export const RECORDS_PER_PAGE = 10;

export const PHONE_SIZE_WITHOUT_MASK = 10;

export const CELLPHONE_SIZE_WITHOUT_MASK = 11;

export const MAX_CHARACTERS_ABOUT = 3;

export const HEIGHT_STEP_CONTAINER = 450;

export const CEP_SIZE_IN_MASK = 9;

export const DAYS_TO_PAY = 9;

export const DAYS_TO_START_PAY = 3;

export const MAX_ASSISTANCE_PER_PAGE = 12;

export const CPF_SIZE = 11;

export const RESTRICTDOCUMENTERRORCODE = '21.999';

export const SOCIAL_NAME_PERSON_ERROR = '21.26';

export const MAX_SIZE_ADDRESS = 80;

export const DEFAULT_BASIC_COVERAGE_VALUE = 50000;

export const PHONE_MASKS = {
  auto: '(99) 9999-99999',
  phone: '(99) 9999-9999',
  cellphone: '(99) 99999-9999',
  /*  Ao entrar e sair no campo com máscara, por padrão, o navegador insere a máscara no campo. 
      Uma das sugestões seria apenas aplicar a máscara apenas se o campo tiver valor.
      InputMask não consegue receber uma máscara caso inicialize com null, ou undefined, ou string vazia, logo é necessário passar uma máscara válida. 
      A máscara a seguir atendeu a necessidade.
      Ela será substituida pelas máscaras acima após o usuário preencher o campo.
  */
  default: '9-9',
};

export const HOURS = {
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
};

export const INITIAL_QUOTE = {
  grossPremium: 44.22,
  iof: 3.04,
  installments: [
    {
      installmentNumber: 1,
      amount: 4.02,
    },
  ],
};

export const IMAGES_SOCIALS = {
  facebook: iconFacebook,
  instagram: iconInstagram,
  linkedin: iconLinkedin,
  phone: iconPhone,
  twitter: iconTwitter,
};

export const TYPE_IS_HOUSES_KEYS = {
  usual: {
    key: 'usual',
  },
  summer: {
    key: 'summer',
  },
  vacate: {
    key: 'vacate',
  },
};

export const TYPE_MAKE_HOUSES_KEYS = {
  brick: {
    key: 'brick',
  },
  wood: {
    key: 'wood',
  },
};

export const TYPE_HOUSES_KEYS = {
  house: {
    key: 'house',
  },
  condominiumHouse: {
    key: 'condominiumHouse',
  },
  apartment: {
    key: 'apartment',
  },
  countrySideHouse: {
    key: 'countrySideHouse',
  },
};

export const TYPE_PAYMENT = {
  billet: {
    key: 'Boleto',
  },
  credit: {
    key: 'Cartao',
  },
};

export const TYPE_CREDIT = {
  recurrence: {
    key: 'CreditCardRecurrence',
  },
  checkout: {
    key: 'CreditCardCheckout',
  },
};

export const DEFAULT_CONFIG_CURRENCY = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        currency: 'BRL',
        style: 'currency',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const STEPS_NUMBER = {
  ABOUT_YOU: 0,
  ABOUT_PROPERTY: 1,
  LOCALIZATION: 2,
  BASIC_COVERAGE: 3,
  ADDITIONAL_COVERAGE: 4,
  ASSISTANCE: 5,
  PAYMENT: 6,
};

export const FETCH_STATUS = {
  notFetched: 'notFetched',
  fulfilled: 'fulfilled',
  pending: 'pending',
  rejected: 'rejected',
  forbidden: 'forbidden',
};

export const COVERAGE_TYPE = {
  basic: 'BASIC',
  additional: 'ADDITIONAL'
};

export const HTTP_GENERIC_STATUS = {
  successful: 2,
  clientError: 4,
  serverError: 5
};

export const ROUTES = Object.freeze({
  Contratacao: 'contratacao',
  CreditCardTransition: 'orders/:orderId',
  CreditCardFallback: 'contratacao/pagamento',
  CreditCardConclusion: 'finalizacao'
});

export default { DEFAULT_THEME };
