import React from 'react';
import { Typography } from '@material-ui/core';
import useProposal from 'hooks/useProposal';

const CreditCard = () => {
  const { getProposalState } = useProposal();

  const handleDownloadProposal = () => window.open(getProposalState?.url, '_blank');

  return (
    <>
      <Typography variant="body1">
        Vamos emitir a sua apólice e logo ela será enviada para o seu e-mail. Caso queira acessar a sua proposta{' '}
        <Typography
          className="steps__finish-main__link"
          variant="body1"
          component="span"
          onClick={handleDownloadProposal}
        >
          clique aqui.
        </Typography>
      </Typography>
      <br />
    </>
  );
};

export default CreditCard;
