import React from 'react';
import PropTypes from 'prop-types';
import styles from 'assets/style/assist-modal.scss';
import { Box, Grid } from '@material-ui/core';
import ModalInfo from 'components/ModalInfo/ModalInfo';

const SectionModal = ({ open, image, children, text }) => (
  <ModalInfo open={open} className="assist-modal" noPadding>
    <Box>
      <Grid container>
        <Grid item xs={12} md={5} className="side-image">
          <Box display="flex" ml={-0.5}>
            <img src={image} alt={`Background ${text} - Pottencial`} />
          </Box>
        </Grid>

        <Grid item xs={12} md={7} className="side-text">
          {children}
        </Grid>
      </Grid>
    </Box>

    <span hidden role="presentation" className={styles.close} />
  </ModalInfo>
);

SectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  image: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SectionModal;
