import { useDispatch } from "react-redux";
import { requestActions } from "../redux/index";

export default () => {
  const dispatch = useDispatch();

  const setReset = () => dispatch(requestActions.setReset());

  const setResetFinish = () => dispatch(requestActions.setResetFinish());

  return {
    setReset,
    setResetFinish
  };
};
