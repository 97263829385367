import { useDispatch, useSelector } from "react-redux";
import { getPayments } from "redux/requestSelectors";
import { requestActions, requestOperations } from "../redux";

export default () => {
  const dispatch = useDispatch();

  const getPaymentState = useSelector(getPayments);

  const getPaymentUrlByProposalSequencial = async (proposalSequencial) => dispatch(requestOperations.getPaymentsUrl(proposalSequencial));

  const setPayment = (paymentData) => dispatch(requestActions.setPayment(paymentData));

  return {
    getPaymentState,
    getPaymentUrlByProposalSequencial,
    setPayment
  };
};
