import * as Yup from 'yup';
import YUP_MESSAGE from 'helpers/yup';
import useYup from 'hooks/useYup';
import { DAYS_TO_PAY, DAYS_TO_START_PAY, HOURS, MAX_SIZE_ADDRESS, TYPE_PAYMENT } from 'constants/defaults';
import { PAYMENT } from 'constants/textsSteps';
import moment from 'moment';
import useRisk from './useRisk';

export default () => {
  const { cpf: cpfRule, phoneOrCellphone: phoneRule, cep: cepRule } = useYup();
  const { getRiskState } = useRisk();

  const maxForm = moment().add(DAYS_TO_PAY, 'd');
  const minForm = moment().add(DAYS_TO_START_PAY, 'd').set(HOURS);

  const AboutYouStepValidationSchema = Yup.object({
    useSocialName: Yup.boolean().default(false).required(),
    name: Yup.string().when('useSocialName', (useSocialName, schema) => (useSocialName ? schema.required() : schema)),
    document: cpfRule.required(),
    email: Yup.string().email().max(150).required(),
    phone: phoneRule.required(),
    trustedServiceAgreement: Yup.boolean().test({
      message: YUP_MESSAGE.required,
      test: (value) => value,
    }),
    trustedCommunicationsPottencial: Yup.boolean(),
  });

  const AboutPropertyStepValidationSchema = Yup.object({
    propertyType: Yup.string().required(),
    constructionType: Yup.string().required(),
    propertyUseType: Yup.string().required(),
  });

  const LocatizationStepValidationSchema = Yup.object({
    zipCode: cepRule.required(),
    street: Yup.string().max(MAX_SIZE_ADDRESS).required(),
    number: Yup.string().max(MAX_SIZE_ADDRESS).required(),
    ...(getRiskState?.propertyType === 'apartment' && {
      complement: Yup.string().max(MAX_SIZE_ADDRESS).required(),
    }),
    ...(getRiskState?.propertyType !== 'apartment' && {
      complement: Yup.string().max(MAX_SIZE_ADDRESS),
    }),
    district: Yup.string().max(MAX_SIZE_ADDRESS).required(),
    uf: Yup.string().max(MAX_SIZE_ADDRESS).required(),
    city: Yup.string().max(MAX_SIZE_ADDRESS).required(),
  });

  const BasicCoverageValidationSchema = Yup.object({
    basicCoverageValue: Yup.number().required(),
  });

  const PaymentValidationSchema = Yup.object({
    typePayment: Yup.string().ensure().required(),
    installment: Yup.string().required(),
    endDueDate: Yup.date()
      .when('typePayment', (typePayment) => {
        let schema = Yup.date();
        if (typePayment === TYPE_PAYMENT.billet.key) {
          schema = schema
            .min(minForm.toDate(), PAYMENT.minDate)
            .max(maxForm.toDate(), PAYMENT.maxDate(maxForm.format('DD/MM/YYYY')))
            .required();
          return schema;
        }

        return schema;
      })
      .required(),
  });

  return {
    AboutYouStepValidationSchema,
    AboutPropertyStepValidationSchema,
    LocatizationStepValidationSchema,
    BasicCoverageValidationSchema,
    PaymentValidationSchema,
  };
};
